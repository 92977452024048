import React from "react";
import Team from "./Team";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Pie } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import {
  usersReference,
  rankingsReference,
  updateUser,
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  keepersReference,
  sleep,
  passedDue,
  currYear,
} from "./App";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { keepArr } from "./KeeperContainer";
import PRCTeam from "./PRCTeam";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { gsap } from "gsap";

const TeamList = styled.div`
  padding: 8px;
`;

class PRContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadCount: 0,
      nameIdArr: [],
      animate: false,
      week: -1,
      Tid: "",
      submitted: false,
      checked: false,
      message: this.props.subMessage
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.r8 = React.createRef();
    this.r9 = React.createRef();
    this.r10 = React.createRef();
  }

  testAnimate = async (a) => {
    await sleep(100);

    if (this.state.loadCount == a) {
      gsap.from(this.r1.current, { duration: 3, scale: 0.5, ease: "bounce" });
      gsap.from(this.r2.current, { duration: 7, ease: "power4", opacity: "0" });
      gsap.from(this.r3.current, {
        y: "400%",
        duration: 3,
        ease: "power4",
        scale: 0.5,
      });
      // gsap.from(this.r4.current, { y: "100%", duration: 3, ease: "bounce", scale: 0.5});
      // await sleep(1000)
      this.setState({ animate: true });
    }
  };

  componentDidMount() {
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.testAnimate(d);
      this.loadTeams();
    }
  }

  async loadTeams() {
    let Tid = await getId(usersReference);
    this.setState({ week: getWeek(), Tid: Tid });
  }

  show() {
    for (const [key, value] of Object.entries(this.props.teams)) {
    }
  }

  checkSubbed = () => {
    this.setState({checked: true})
  }

  p1() {
    if (this.state.animate) {
      return (
        <Container>
          <Row>
            <Col
              style={{
                marginTop: "10px",
                fontSize: "30px",
                fontWeight: "bolder",
                paddingBottom: "0px",
                // color: this.state.validTime ? "black" : "#FF7367",
              }}
            >
              POWER RANKINGS
            </Col>
          </Row>
          <Row style={{ paddingTop: "0px" }}>
            <Col>WEEK {this.state.week} <span style={{color: this.state.submitted || this.props.done ? "green" : "red"}}>({this.state.message})</span></Col>
          </Row>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
        </Container>
      );
    }
  }

  p2() {
    if (this.state.animate) {
      return (
        <Droppable droppableId={this.props.column.id}>
          {(provided) => (
            <Row ref={provided.innerRef} {...provided.droppableProps}>
              {this.props.teams.map((team, index) => {
                return (
                  <PRCTeam
                    key={team[0]}
                    team={team}
                    index={index}
                    disable={this.state.submitted || this.props.done}
                    callback={this.checkSubbed}
                  ></PRCTeam>
                );
              })}

              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      );
    } else {
      return (
        <Container>
          <Row
            style={{ marginTop: "25%", fontSize: "30px", fontWeight: "bold" }}
          >
            <Col>Loading Pick 'Em</Col>
          </Row>
          <Row>
            <Col>
              <Spinner
                style={{ width: "4rem", height: "4rem", fontWeight: "bold" }}
                animation="border"
              ></Spinner>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  p3() {
    if (this.state.animate) {
      return (
        <Container>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <Col>
              <Button
                id="rankedButton"
                onClick={() => this.submit()}
                disabled={this.state.submitted || this.props.done || passedDue}
                ref={this.r4}
                onMouseEnter={this.enter}
                onMouseLeave={this.leave}
                style={{
                  backgroundColor: this.state.submitted || this.props.done ? "lightgray" : "white",
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  enter = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.2, fontWeight: "bolder" });
  };

  leave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  async submit() {

    if (localStorage.getItem("name") === "guest") {
      this.setState({ submitted: true, message: "SUBMITTED" });;
      return;
    }

    let ids = this.props.teams.map((t) => t[0]);
    let names = this.props.teams.map((t) => t[1]);

    await addDoc(rankingsReference, {
      Rankings: ids,
      Names: names,
      Week: this.state.week,
      Tid: this.state.Tid,
      Year: currYear,
    });
    this.setState({ submitted: true, message: "SUBMITTED" });

    let q = query(
      picksReference,
      where("Week", "==", this.state.week),
      where("Tid", "==", this.state.Tid)
    );

    const data = await getDocs(q);
    if (data.empty) { 
      window.location.href = "/pickem"
    }
    else {
      window.location.href = "/stats"
    }
    
  }

  render() {
    return (
      <div>
        <Container>
          <div ref={this.r1}>{this.p1()}</div>

          <div ref={this.r2}> {this.p2()}</div>
          <div ref={this.r3}>{this.p3()}</div>
        </Container>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default PRContainer;
