import React from "react";
import Team from "./Team";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Pie } from "react-chartjs-2";
import {
  usersReference,
  updateUser,
  picksReference,
  rankingsReference,
  getId,
  getWeek,
  leagueId,
  sleep,
  winnersReference,
  currYear
} from "./App";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import StatsWeek from "./StatsWeek";
import StatsKeepers from "./StatsKeepers";
import StatsPersonal from "./StatsPersonal";
import StatsYear from "./StatsYear";
import { db } from "./Firebase";
import { gsap } from "gsap";
// import { winnersArr } from "./pages/Stats";

// import Sonnet from "../../components/Sonnet";

export const getWinnersArr = async () => {
  console.log("GETWINNERSARR")
  let q = query(
    winnersReference,
    where("Year", "==", currYear),
  );
  let winDocs = await getDocs(q)
  let winnersArr;

  if (false) {
    let q1 = query(
      winnersReference,
      where("Year", "==", 2000),
    );
    let winDocs2 = await getDocs(q1)
    let winnersArr2 = winDocs2.docs[0].data().Winners;
    console.log('winnersArr2 :', winnersArr2);

    await addDoc(winnersReference, {
      Winners: winnersArr2,
      Year: currYear
    });
  }

  if (getWeek() <= 1) {
    winnersArr = [];
  }
  else {
    winnersArr = winDocs.docs[0].data().Winners;
  }
  
  return await winnersArr;
};

Chart.register(ArcElement, Tooltip, Legend);

class StatsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderedNIA: [],
      names1: [],
      users: [],
      users1: [],
      picks: [],
      week: -1,
      picksByWeek: [],
      nameIdArr: [],
      matchups: [],
      weekValid: false,
      nameLoads: 0,
      recordsArr: [],
      cashArr: [],
      whoEveryonePicks: [],
      whoEachPersonPicks: [],
      myIndex: -1,
      pickEmResults: [],
      animated: false,
      loadCount: 0,
      rankingData: [],
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
  }

  //get all the picks and put them into state
  async getPickInfo() {
    let picksData = await getDocs(picksReference);
    let week = await getWeek();

    let data = picksData.docs
    .filter((doc) => doc.data().Year === currYear)
    .map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log(data)
    await this.organizeByWeek(data);

    await this.setState({ picks: data, week: week });
  }

  organizeByWeek = (data) => {
    let empty = [
      ["NO USE"],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ];
    

    for (let i = 0; i < data.length; i++) {
      empty[data[i].Week].push(data[i]);
    }

    this.setState({ picksByWeek: empty });
  };

  //get names for all the Tids
  async getNames() {
    console.log("GETNAMES")
    let url = "https://api.sleeper.app/v1/league/" + leagueId + "/users";
    let tmp = [];
    let nms = [];

    await fetch(url, {
      method: "GET",
      headers: {
        // 'x-access-token': t
      },
    })
      .then((res) => res.json())
      .then((res) => {
        tmp = res;
      })
      .then(() => {
        for (let i = 0; i < tmp.length; i++) {
          let arr = [];
          arr.push(tmp[i].user_id);

          if (typeof tmp[i].metadata.team_name === "undefined") {
            arr.push(tmp[i].display_name);
          } else {
            arr.push(tmp[i].metadata.team_name);
          }

          nms.push(arr);
        }

        // this.getMatchups(nms, 1);
        this.getRosterOrder(nms);
        this.setState({ nameIdArr: nms });
        this.analyzePicks(nms);
        this.getMyIndex(nms);
      });
  }

  getRosterOrder = (nIArr) => {
    let url = "https://api.sleeper.app/v1/league/" + leagueId + "/rosters";

    let teamInfo = [];
    let ids = [];

    fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((res) => res.json())
      .then((res) => {
        teamInfo = res; //player info
      })
      .then(() => {
        ids = teamInfo.map((team) => {
          for (let i = 0; i < nIArr.length; i++) {
            if (nIArr[i][0] === team.owner_id) {
              return [team.owner_id, nIArr[i][1]];
            }
          }
        });
        // ids
        this.setState({ orderedNIA: ids });
        this.getMatchups(ids);
      })
      .then(() => {});
  };

  getMyIndex = async (arr) => {
    let id = await getId(usersReference);

    if (localStorage.getItem("name") === "guest") {
      id = "466461424856592384";
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === id) {
        this.setState({ myIndex: i });
        break;
      }
    }
  };

  async addWinnerCheck() {
    let week = await getWeek();
    if (week <= 1) return;
    let winnersAr = await getDocs(winnersReference);
    // let winnersArr = winnersAr.docs[0].data().Winners;
    // let id = winnersAr.docs[0].id;
    let q = query(
      winnersReference,
      where("Year", "==", currYear),
    );
    let winDocs = await getDocs(q)
    let winnersArr = winDocs.docs[0].data().Winners
    let id = winDocs.docs[0].id;
    

    // winnersArr["Year"] = 2023
    console.log("WINAR")
    console.log(winnersArr)
    // await addDoc(winnersReference, {
    //   Winners: winnersArr.Winners,
    //   Year: currYear,
    // });
    // let winnersArr = await getWinnersArr();
    
    // winnersArr[picks[i].Week].data.picks[j]

    for (let i = 1; i < week; i++) {
      console.log("hi")
      if (winnersArr[i].data.tiebreaker !== "") {
        let url = "https://api.sleeper.app/v1/league/" + leagueId + "/rosters";
        let teamInfo = [];
        let ids = [];
        let winners = [];

        fetch(url, {
          method: "GET",
          headers: {},
        })
          .then((res) => res.json())
          .then((res) => {
            teamInfo = res; //player info
          })
          .then(() => {
            
            winners = teamInfo
              .map((team) => {
                if (
                  team.metadata.record[team.metadata.record.length - (week - i)] === "W" //week = 3, get length -1, week = 2, get -2
                ) {
                  return team.owner_id;
                } else {
                  return -1;
                }
              })
              .filter((t) => t != -1);
          })
          .then(() => {
            console.log("WINNERS")
            console.log(winners)
            let numCorrect = [];
            let high = -1;
            for (let j = 0; j < this.state.pickEmResults.length; j++) {
              let tmp = [];
              if (this.state.pickEmResults[j].Week == i) {
                let right = 0;
                for (
                  let h = 0;
                  h < this.state.pickEmResults[j].Ids.length;
                  h++
                ) {
                  if (
                    // this.state.pickEmResults[j].Ids[h] ===
                    winners.includes(this.state.pickEmResults[j].Ids[h])
                  ) {
                    right++;
                  }
                }

                if (
                  winners.includes(this.state.pickEmResults[j].Lock)
                ) {
                  right++;
                }
                else {
                  right--;
                }

                if (right > high) {
                  high = right;
                }
                tmp.push(right, j);
                // console.log(j + ": " + this.state.pickEmResults[j].Tid)
                numCorrect.push(tmp);
              }
            }
            let top = numCorrect.filter((t) => t[0] == high);
            // console.log("TOP")
            // console.log(top)
            if (top.length > 1) {
              let bestTb = [100, -1];
              for (let j = 0; j < top.length; j++) {
                if (
                  Math.abs(
                    Number(this.state.pickEmResults[top[j][1]].TieBreaker) -
                      Number(winnersArr[i].data.tiebreaker)
                  ) < bestTb[0]
                ) {
                  let tmp = [
                    Math.abs(
                      Number(this.state.pickEmResults[top[j][1]].TieBreaker) -
                        Number(winnersArr[i].data.tiebreaker)
                    ),
                    top[j][1],
                  ];
                  bestTb = [...tmp];
                }
              }
              let winnerDoc = doc(db, "Winners", id);
              let winner_info = [this.state.pickEmResults[bestTb[1]].Tid];
              // console.log('top.filter((a) => a[1] == bestTb[1])[0] :', top.filter((a) => a[1] == bestTb[1])[0]);

              if (
                top.filter((a) => a[1] == bestTb[1])[0] ==
                (this.state.nameIdArr.length / 2)+1 //+1 for lock points
              ) {
                winner_info.push(10);
              } else {
                winner_info.push(5);
              }

              winnersArr[i].data.picks = winners
              winnersArr[i].data.winner_info = winner_info;

              let newFields = { Winners: winnersArr };
              updateDoc(winnerDoc, newFields);
            } else {
              let winnerDoc = doc(db, "Winners", id);
              let winner_info = [this.state.pickEmResults[top[0][1]].Tid];

              // console.log('top[0][0] :', top[0][0]);
              if (top[0][0] == (this.state.nameIdArr.length / 2)+1 ) { //+1 for lock points
                winner_info.push(10);
              } else {
                winner_info.push(5);
              }

              winnersArr[i].data.picks = winners
              winnersArr[i].data.winner_info = winner_info;

              let newFields = { Winners: winnersArr };
              updateDoc(winnerDoc, newFields);
            }
          });

        //add winners to winner arr

      }
    }
  }

  animate = async (a) => {
    await sleep(50);

    if (this.state.loadCount == a) {
      gsap.from(this.r1.current, {
        y: "100%",
        duration: 2,
        ease: "power4",
        backgroundColor: "green",
        // scale: 0.1,
      });

      this.setState({ animated: true });
    }
  };

  async getRankings() {
    const rankingData = await getDocs(rankingsReference);
    let arr = rankingData.docs.filter((doc) => doc.data().Year === currYear).map((doc) => doc.data());
    this.setState({ rankingData: arr });
  }

  componentDidMount() {
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.animate(d);
    }

    if (this.state.week == -1) {
      this.getPickInfo();
    }

    if (this.state.nameIdArr.length == 0 && this.state.nameLoads == 0) {
      this.setState({ nameLoads: ++this.state.nameLoads });
      this.getNames();
      this.getRankings();
    }

    if (!this.state.weekValid) {
      this.checkWeekValid();
    }
  }

  async checkWeekValid() {
    let week = await getWeek();
    let Uid = await getId(usersReference);
    let q = query(
      picksReference,
      where("Tid", "==", Uid),
      where("Week", "==", week),
      where("Year", "==", currYear),
    );
    let q1 = query(
      rankingsReference,
      where("Tid", "==", Uid),
      where("Week", "==", week),
      where("Year", "==", currYear),
    );

    const data = await getDocs(q);
    const data1 = await getDocs(q1);
    if (
      (!data.empty && !data1.empty) ||
      localStorage.getItem("name") === "guest"
    ) {
      this.setState({ weekValid: true });
    }
  }

  getMatchups = async (data) => {
    let weeksInASeason = 18;
    let final = [];
    for (let h = 1; h < weeksInASeason + 1; h++) {
      let arr = [];
      for (let k = 0; k < data.length / 2; k++) {
        arr.push([]);
      }
      let url =
        "https://api.sleeper.app/v1/league/" + leagueId + "/matchups/" + h; //other league for test

      // https://api.sleeper.app/v1/players/nfl

      let tmp = [];
      let all = [];
      let mNames = [];
      let rosters = [];
      let allPlayers = [];

      if (this.state.matchups.length == h - 1) {
        await fetch(url, {
          method: "GET",
          headers: {},
        })
          .then((res) => res.json())
          .then((res) => {
            tmp = res;
          })
          .then(() => {
            //iterate through tmp and find the matchups

            for (let i = 0; i < tmp.length; i++) {
              if (tmp[i].matchup_id == null) {
                continue;
              }
              arr[tmp[i].matchup_id - 1].push(data[tmp[i].roster_id - 1]);
            }

            final.push(arr);

            let t = this.state.matchups;
            t.push(arr);
            this.setState({ matchups: t });
          })
          .then(() => {});
      }
    }
  };

  getWeekTab() {
    if (this.state.matchups.length > 0 && this.state.picksByWeek.length > 0) {
      return (
        <StatsWeek
          picksByWeek={this.state.picksByWeek}
          currWeek={this.state.week}
          nameIdArr={this.state.nameIdArr}
          matchups={this.state.matchups}
          changeWeek={this.changeWeek}
          recordsArr={this.state.recordsArr}
          rankingData={this.state.rankingData}
        />
      );
    }
  }

  getWeeklyCashReport = (nIArr, picks, rankings, winnersArr) => {
    let week = getWeek();
    let empty = [];
    for (let i = 1; i < week; i++) {
      empty.push(0);
    }

    let report = nIArr.map((t) => {
      return [t[0], t[1], ...empty];
    });

    for (let i = 0; i < picks.length; i++) {

      let noWins = 1;

      for (let j = 0; j < picks[i].Picks.length; j++) {
        if (winnersArr[picks[i].Week].data.picks.includes(picks[i].Ids[j])) {
          noWins = 0;
        }
      }

      for (let j = 0; j < report.length; j++) {
        if (picks[i].Tid === report[j][0]) {
          report[j][picks[i].Week + 1] += (1 - (noWins * 5));
          break;
        }
      }
    }

    for (let i = 0; i < rankings.length; i++) {
      for (let j = 0; j < report.length; j++) {
        if (rankings[i].Tid === report[j][0]) {
          report[j][rankings[i].Week + 1]++;
          break;
        }
      }
    }

    for (let i = 1; i < week; i++) {
      for (let j = 0; j < report.length; j++) {
        if (winnersArr[i].data.winner_info[0] === report[j][0]) {
          report[j][i + 1] += winnersArr[i].data.winner_info[1];
        }
      }
    }


    console.log('report :', report);

  };

  analyzePicks = async (nIArr) => {
    // if (await getWeek() == 1) {
    //   return;
    // }

    const picksData = await getDocs(picksReference);
    let picks = picksData.docs.filter((doc) => doc.data().Year === currYear).map((doc) => ({ ...doc.data(), id: doc.id }));
    this.setState({ pickEmResults: picks });

    // let winnersAr = await getDocs(winnersReference);
    let winnersArr = await getWinnersArr();

    // X get every users record
    // X measure picks against and for
    // X get every users money
    // X measure who each user picks most and least

    if (winnersArr.length > 0) {
      this.getWeeklyCashReport(nIArr, picks, this.state.rankingData, winnersArr);
    }
    

    let idsArr = [];
    let recordsArr = [];
    let cashArr = [];
    let whoEachPersonPicks = [];
    let whoEveryonePicks = [];

    for (let i = 0; i < nIArr.length; i++) {
      recordsArr.push([0, 0]);
      cashArr.push([0, 0, 0]);
      whoEveryonePicks.push(0);

      let tmp = [];
      for (let j = 0; j < nIArr.length; j++) {
        tmp.push(0);
      }
      tmp.push(0); //extra slot used to track how many picks the team has made
      whoEachPersonPicks.push(tmp);
    }

    for (let i = 0; i < picks.length; i++) {
      let teamI = -1; //id of the team that made the pick

      for (let j = 0; j < nIArr.length; j++) {
        if (nIArr[j][0] === picks[i].Tid) {
          teamI = j;
          break;
        }
      }

      cashArr[teamI][0]++;

      let wins = recordsArr[teamI][0];
      let currWins = recordsArr[teamI][0];
      let losses = recordsArr[teamI][1];
      let currLosses = recordsArr[teamI][1];



      let cash = cashArr[teamI][1];
      let noWins = 0;
      for (let j = 0; j < picks[i].Picks.length; j++) {

        if (picks[i].Week < (await getWeek())) {
          if (winnersArr[picks[i].Week].data.picks.includes(picks[i].Ids[j])) {
            wins++;
            
          } else {
            losses++;
            
          }
        }

        for (let h = 0; h < nIArr.length; h++) {
          if (picks[i].Ids[j] == nIArr[h][0]) {
            whoEveryonePicks[h]++;
            whoEachPersonPicks[teamI][h]++;

            break;
          }
        }
      }
      whoEachPersonPicks[teamI][nIArr.length]++;
      
      if (wins - currWins == 0) {
        noWins++
      }

      if (
        picks[i].Week < (await getWeek()) &&
        winnersArr[picks[i].Week].data.winner_info[0] === nIArr[teamI][0]
      ) {
        cash += winnersArr[picks[i].Week].data.winner_info[1];
      }

      recordsArr[teamI][0] = wins;
      recordsArr[teamI][1] = losses;
      cashArr[teamI][1] = cash - (noWins * 5);
    }

    for (let i = 0; i < this.state.rankingData.length; i++) {
      for (let j = 0; j < nIArr.length; j++) {
        if (this.state.rankingData[i].Tid === nIArr[j][0]) {
          cashArr[j][2]++;
          break;
        }
      }
    }

    this.setState({
      recordsArr: recordsArr,
      cashArr: cashArr,
      whoEveryonePicks: whoEveryonePicks,
      whoEachPersonPicks: whoEachPersonPicks,
    });

    this.addWinnerCheck();
  };

  getYearTab() {}

  getKeepersTab() {
    if (true) {
      return <StatsKeepers />;
    }
  }

  changeWeek = (week) => {
    this.setState({ week: week });
  };

  getPersonalTab() {
    if (this.state.myIndex > -1 && this.state.cashArr.length > 0) {
      // if (this.state.cashArr.length > 0) {
      return (
        <StatsPersonal
          picksByWeek={this.state.picksByWeek}
          currWeek={this.state.week}
          nameIdArr={this.state.nameIdArr}
          matchups={this.state.matchups}
          myIndex={this.state.myIndex}
          recordsArr={this.state.recordsArr}
          cash={this.state.cashArr[this.state.myIndex]}
          whoEveryonePicks={this.state.whoEveryonePicks}
          whoIPick={this.state.whoEachPersonPicks[this.state.myIndex]}
          pickEmResults={this.state.pickEmResults}
          whoEachPersonPicks={this.state.whoEachPersonPicks}
          rankingData={this.state.rankingData}
        />
      );
    }
  }

  getYearTab() {
    if (this.state.myIndex > -1 && this.state.recordsArr.length > 0) {
      // if (this.state.recordsArr.length > 0) {
      return (
        <StatsYear
          picksByWeek={this.state.picksByWeek}
          currWeek={this.state.week}
          nameIdArr={this.state.nameIdArr}
          matchups={this.state.matchups}
          myIndex={this.state.myIndex}
          recordsArr={this.state.recordsArr}
          cash={this.state.cashArr}
          whoEveryonePicks={this.state.whoEveryonePicks}
          whoIPick={this.state.whoEachPersonPicks[this.state.myIndex]}
          pickEmResults={this.state.pickEmResults}
          whoEachPersonPicks={this.state.whoEachPersonPicks}
          rankingData={this.state.rankingData}
        />
      );
    }
  }

  showTabs() {
    if (this.state.animated) {
      return "s";
    }
  }

  render() {
    return (
      <Container id="tabContainer">
        <Tabs
          defaultActiveKey="personal"
          id="tab"
          ref={this.r1}
          // mountOnEnter={true}
        >
          <Tab eventKey="personal" title="My Stats">
            <Container>
              <Row>
                <Col>{this.getPersonalTab()}</Col>
                {/* TEMPORARILY UNAVAILABLE */}
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="week" title="Week" disabled={!this.state.weekValid}>
            {this.getWeekTab()}
            {/* TEMPORARILY UNAVAILABLE */}
          </Tab>
          <Tab eventKey="year" title="Year">
            <Container>
              <Row>
                <Col>{this.getYearTab()}</Col>
                {/* TEMPORARILY UNAVAILABLE */}
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="keepers" title="Keepers">
            <Container>
              <Row>
                <Col>
                  <StatsKeepers
                    picksByWeek={this.state.picksByWeek}
                    currWeek={this.state.week}
                    nameIdArr={this.state.nameIdArr}
                    matchups={this.state.matchups}
                  />
                </Col>
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default StatsContainer;
