import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  keepersReference,
  rankingsReference,
  currYear,
} from "../App";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { usersReference, updateUser, messagesReference, sleep } from "../App";
import InputGroup from "react-bootstrap/InputGroup";
import { gsap } from "gsap";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadCount: 0,
      checkedID: false,
      checkedKeeper: false,
      showAlert: false,
      showAlert1: false,
      sentMessage: "",
      hasTeamSet: false,
      hasKeepersSet: false,
      loadCount: 0,
      animated: false,
      showPickEmAlert: false,
      showPRAlert: false,
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.r8 = React.createRef();
    this.r9 = React.createRef();
  }

  async checkID() {
    this.setState({ checkedID: true });

    let q = query(
      usersReference,
      where("Email", "==", localStorage.getItem("email"))
    );
    let data = await getDocs(q);

    if (
      (data.size == 1 &&
      data.docs[0]._document.data.value.mapValue.fields.id.stringValue != "") || localStorage.getItem("name") === "guest"
    ) {
      this.setState({ hasTeamSet: true });
    }
  }

  async checkKeeper() {
    this.setState({ checkedKeeper: true });

    let q = query(
      keepersReference,
      where("Uid", "==", localStorage.getItem("userId")),
      where("Year", "==", currYear),
    );
    let data = await getDocs(q);

    if (
      (!data.empty)
    ) {
      this.setState({ hasKeepersSet: true });
    }
  }

  o1() {
    if (this.state.hasTeamSet && this.state.animated) {
      return (
        <Link to="/pickem" id="optionText">
          Pick 'Em{" "}
        </Link>
      );
    }
  }

  o5() {
    if (this.state.hasTeamSet && this.state.animated) {
      return (
        <Link to="/PowerRanking" id="optionText">
          Power Rankings{" "}
        </Link>
      );
    }
  }

  o2() {
    if (this.state.hasTeamSet && this.state.animated && !this.state.hasKeepersSet) {
      return (
        <Link to="/keeperselection" id="optionText">
          Select Keepers{" "}
        </Link>
      );
    }
  }

  o4() {
    if (this.state.hasTeamSet && this.state.animated) {
      return (
        <Link to="/stats" id="optionText">
          Stats{" "}
        </Link>
      );
    }
  }

  o6() {
    if (!this.state.hasTeamSet && localStorage.getItem("email") && this.state.animated) {
      return (
        <Link to="/teamselection" id="optionText">
          Select Team{" "}
        </Link>
      );
    }
  }

  async submitSuggestion() {
    let message = document.getElementById("sInput").value;

    if (message != "") {
      await addDoc(messagesReference, {
        Name: localStorage.getItem("name"),
        Message: document.getElementById("sInput").value,
      });
      this.setState({ showAlert: true, sentMessage: message });
      document.getElementById("sInput").value = "";
    } else {
      this.setState({ showAlert1: true });
    }

    setTimeout(() => {
      this.setState({ showAlert1: false, showAlert: false });
    }, 3000);
  }

  animate = async (a) => {
    await sleep(125);
    if (this.state.loadCount == a) {
      if (localStorage.getItem("email")) {
        this.setState();
      }

      gsap.from(this.r1.current, {
        y: "100%",
        duration: 1,
        ease: "power4",
        scale: 0.5,
        opacity: 0,
      });

      gsap.from(this.r2.current, {
        y: "100%",
        duration: 1,
        ease: "power4",
        scale: 0.5,
        opacity: 0,
      });

      gsap.from(this.r3.current, {
        y: "100%",
        duration: 1,
        ease: "power4",
        scale: 0.5,
        opacity: 0,
      });

      gsap.from(this.r4.current, {
        y: "100%",
        duration: 1,
        ease: "power4",
        scale: 0.5,
        opacity: 0,
      });

      gsap.from(this.r5.current, {
        y: "100%",
        duration: 1,
        ease: "power4",
        scale: 0.5,
        opacity: 0,
      });

      this.setState({ animated: true });
    }
  };

  componentDidMount() {
    if (!this.state.checkedID) {
      this.checkID();
    }
    if (!this.state.checkedKeeper) {
      this.checkKeeper();
    }
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.animate(d);
      this.checkToDo(d);
    }
  }

  checkToDo = async (a) => {
    let week = getWeek();
    let Uid = await getId(usersReference);
    await sleep(125);
    if (this.state.loadCount == a) {
      let q1 = query(
        rankingsReference,
        where("Week", "==", week),
        where("Tid", "==", Uid)
      );

      const data1 = await getDocs(q1);

      let q2 = query(
        picksReference,
        where("Week", "==", week),
        where("Tid", "==", Uid)
      );

      const data2 = await getDocs(q2);

      let waitTime = 5000;
      let endTime = 20000;

      if (data1.empty && localStorage.getItem("email")) {
        waitTime += 20000;
        endTime += 20000;
        //hasnt submitted rankings

        // this.setState({ showPRAlert: true });

        setTimeout(() => {
          gsap.from(this.r7.current, {
            x: "100%",
            duration: 6,
            ease: "power4",
            scale: 0.5,
            opacity: 0,
          });

          this.setState({ showPRAlert: true });
        }, 5000);

        setTimeout(() => {
          this.setState({ showPRAlert: false });
        }, 20000);
      }
      if (data2.empty && localStorage.getItem("email")) {
        //hasnt submitted pickem

        setTimeout(() => {
          gsap.from(this.r8.current, {
            x: "100%",
            duration: 5,
            ease: "power4",
            scale: 0.5,
            opacity: 0,
          });
          this.setState({ showPickEmAlert: true });
        }, waitTime);

        setTimeout(() => {
          this.setState({ showPickEmAlert: false });
        }, endTime);
      }
    }
  };

  enter = ({ currentTarget }) => {
    gsap.to(currentTarget, { color: "red", scale: 1.2 });
  };

  leave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  guestView() {
    console.log("guestView() :");

    localStorage.setItem("name", "guest");
    localStorage.setItem("email", "none");
    window.location.href = window.location.href;
  }

  render() {
    return (
      <Container>
        <Row ref={this.r1} onMouseEnter={this.enter} onMouseLeave={this.leave}>
          {this.o1()}
        </Row>
        <Row ref={this.r5} onMouseEnter={this.enter} onMouseLeave={this.leave}>
          {this.o5()}
        </Row>
        <Row ref={this.r2} onMouseEnter={this.enter} onMouseLeave={this.leave}>
          {this.o2()}
        </Row>
        <Row ref={this.r3} onMouseEnter={this.enter} onMouseLeave={this.leave}>
          {/* {this.o6()} */}
          {!this.state.hasTeamSet && localStorage.getItem("email") && this.state.animated && (
            <Link to="/teamselection" id="optionText">
              Select Team{" "}
            </Link>
          )}
        </Row>
        <Row ref={this.r4} onMouseEnter={this.enter} onMouseLeave={this.leave}>
          {this.o4()}
        </Row>
        <Row ref={this.r9} onMouseEnter={this.enter} onMouseLeave={this.leave}>
          {!localStorage.getItem("email") && this.state.animated && (
            <Button
              id="optionText"
              style={{ backgroundColor: "white", borderColor: "white" }}
              onClick={() => this.guestView()}
            >
              Guest View
            </Button>
          )}
        </Row>
        <Row id="todoAlert" ref={this.r7}>
          <Alert
            show={this.state.showPickEmAlert}
            variant="success"
            onClose={() => this.setState({ showPickEmAlert: false })}
            dismissible
          >
            <div style={{ fontWeight: "bolder" }}>Pick 'Em Not Submitted!</div>
            <div>Complete the Pick 'Em to see results and get $1 FAAB</div>
          </Alert>
        </Row>
        <Row id="todoAlert" ref={this.r8}>
          <Alert
            show={this.state.showPRAlert}
            variant="success"
            onClose={() => this.setState({ showPRAlert: false })}
            dismissible
          >
            <div style={{ fontWeight: "bolder" }}>
              Power Rankings Not Submitted!
            </div>
            <div>
              Complete the Power Rankings to see results and get $1 FAAB
            </div>
          </Alert>
        </Row>
        <Row id="suggest">
          <Alert
            show={this.state.showAlert}
            variant="success"
            onClose={() => this.setState({ showAlert: false })}
          >
            <div style={{ fontWeight: "bolder" }}>Suggestion sent!</div>
            <div>{this.state.sentMessage}</div>
          </Alert>
          <Alert
            show={this.state.showAlert1}
            variant="danger"
            onClose={() => this.setState({ showAlert1: false })}
          >
            <div style={{ fontWeight: "bolder" }}>
              Cannot send empty message!
            </div>
          </Alert>
          <InputGroup className="mb-3" ref={this.r6}>
            <Form.Control
              placeholder="Website Suggestions"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              id="sInput"
            />
            <Button
              variant="outline-primary"
              id="button-addon2"
              onClick={() => this.submitSuggestion()}
            >
              Submit
            </Button>
          </InputGroup>
        </Row>
      </Container>
    );
  }
}
export default Home;
