import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "gridjs/dist/theme/mermaid.css";
import { gsap } from "gsap";
import { sleep } from "./App";

class Matchup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
      rosters: [],
      positions: [],
      Brosters: [],
      Bpositions: [],
      b1: false,
      b2: false,
      loadCount: 0,
      animated: false,
      leftLock: false,
      rightLock: false,
      b1SelectTime: 0,
      b2SelectTime: 0,
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.tl = React.createRef();
  }
  createTL = async (a) => {
    await sleep(50);

    if (this.state.loadCount == a) {
      // await sleep(500);
      gsap.from(this.r3.current, {
        x: "-100%",
        duration: 2,
        ease: "power4",
        // scale: 0.1,
      });
      gsap.from(this.r4.current, {
        x: "100%",
        duration: 2,
        ease: "power4",
        // scale: 0.1,
      });
      this.setState({ animated: true });
      // this.tl.current && this.tl.current.progress(0).kill();
      // this.tl.current = gsap.timeline()
      // .from(this.r1.current, { x: "-100%", duration: 2, ease: "power4" })
      // .from(this.r2.current, { x: "100%", duration: 2, ease: "power4" }, 0);;
    }
  };

  componentDidMount() {
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.createTL(d);
    }
  }

  isDoubleClicked = (b) => {
    let now = Date.now();
    let last = -1;
    if (b == 1) {
      last = this.state.b1SelectTime;
      this.setState({ b1SelectTime: now });
    } else if (b == 2) {
      last = this.state.b2SelectTime;
      this.setState({ b2SelectTime: now });
    }

    if (now - last < 250) {
      return true;
    } else {
      return false;
    }
  };

  selectTeam1() {
    if (this.isDoubleClicked(1)) {
      this.setState({ b1: true, b2: false, leftLock: true });
      this.props.lockCallback(this.props.matchups[0][1]);
      let tmp = [];
      tmp.push(this.props.num);
      tmp.push(this.props.matchups[0][0]);
      tmp.push(this.props.matchups[0][1]);
      tmp.push("Lock");
      this.props.callback(tmp);
    } else {
      if (this.state.b1 == true) {
        let tmp = [];
        tmp.push(this.props.num);
        tmp.push(-1);
        tmp.push(-1);
        if (this.props.currLock === this.props.matchups[0][1]) {
          tmp.push("RemoveLock");
          this.props.lockCallback("");
        } else {
          tmp.push("NoLockData");
        }
        this.props.callback(tmp);
        this.setState({ b1: false, leftLock: false });
      } else {
        let tmp = [];
        tmp.push(this.props.num);
        tmp.push(this.props.matchups[0][0]);
        tmp.push(this.props.matchups[0][1]);
        if (this.props.currLock === this.props.matchups[1][1]) {
          tmp.push("RemoveLock");
          this.props.lockCallback("");
        } else {
          tmp.push("NoLockData");
        }
        this.props.callback(tmp);
        this.setState({ b2: false, b1: true, rightLock: false });
      }
    }
  }

  selectTeam2() {
    if (this.isDoubleClicked(2)) {
      this.setState({ b2: true, b1: false, rightLock: true });
      this.props.lockCallback(this.props.matchups[1][1]);
      let tmp = [];
      tmp.push(this.props.num);
      tmp.push(this.props.matchups[1][0]);
      tmp.push(this.props.matchups[1][1]);
      tmp.push("Lock");

      this.props.callback(tmp);
    } else {
      if (this.state.b2 == true) {
        let tmp = [];
        tmp.push(this.props.num);
        tmp.push(-1);
        tmp.push(-1);
        if (this.props.currLock === this.props.matchups[1][1]) {
          tmp.push("RemoveLock");
          this.props.lockCallback("");
        } else {
          tmp.push("NoLockData");
        }
        this.props.callback(tmp);
        this.setState({ b2: false, rightLock: false });
      } else {
        let tmp = [];
        tmp.push(this.props.num);
        tmp.push(this.props.matchups[1][0]);
        tmp.push(this.props.matchups[1][1]);
        if (this.props.currLock === this.props.matchups[0][1]) {
          tmp.push("RemoveLock");
          this.props.lockCallback("");
        } else {
          tmp.push("NoLockData");
        }
        this.props.callback(tmp);
        this.setState({ b2: true, b1: false, leftLock: false });
      }
    }
  }

  lockTeam1() {
    if (this.state.b1) {
      this.setState({ leftLock: true, rightLock: false });
    }

    console.log("LOCKER1");
  }
  lockTeam2() {
    this.setState({ leftLock: false, rightLock: true });
    console.log("LOCKER2");
  }

  genTable = (colNum) => {
    if (this.state.showInfo) {
      let currId = "";
      let slot1 = this.state.positions[colNum];
      let slot2 = this.state.rosters[colNum];
      let bSlot1 = this.state.Bpositions[colNum];
      let bSlot2 = this.state.Brosters[colNum];
      if (colNum == 1) {
        currId = "rightPlayer";

        slot1 = this.state.rosters[colNum];
        slot2 = this.state.positions[colNum];
        bSlot1 = this.state.Brosters[colNum];
        bSlot2 = this.state.Bpositions[colNum];
      } else {
      }
      let r = (
        <table style={{ marginTop: "10px" }}>
          <tr>
            <td id={currId}>{slot1[0]}</td>
            <td id={currId}>{slot2[0]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[1]}</td>
            <td id={currId}>{slot2[1]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[2]}</td>
            <td id={currId}>{slot2[2]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[3]}</td>
            <td id={currId}>{slot2[3]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[4]}</td>
            <td id={currId}>{slot2[4]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[5]}</td>
            <td id={currId}>{slot2[5]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[6]}</td>
            <td id={currId}>{slot2[6]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[7]}</td>
            <td id={currId}>{slot2[7]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[8]}</td>
            <td id={currId}>{slot2[8]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{slot1[9]}</td>
            <td id={currId}>{slot2[9]}</td>
            <td></td>
          </tr>
          <tr id="benchRow">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[0]}</td>
            <td id={currId}>{bSlot2[0]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[1]}</td>
            <td id={currId}>{bSlot2[1]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[2]}</td>
            <td id={currId}>{bSlot2[2]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[3]}</td>
            <td id={currId}>{bSlot2[3]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[4]}</td>
            <td id={currId}>{bSlot2[4]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[5]}</td>
            <td id={currId}>{bSlot2[5]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[6]}</td>
            <td id={currId}>{bSlot2[6]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[7]}</td>
            <td id={currId}>{bSlot2[7]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[8]}</td>
            <td id={currId}>{bSlot2[8]}</td>
            <td></td>
          </tr>
          <tr>
            <td id={currId}>{bSlot1[9]}</td>
            <td id={currId}>{bSlot2[9]}</td>
            <td></td>
          </tr>
        </table>
      );

      return r;
    }
  };

  toggleInfo() {
    this.setState({ showInfo: !this.state.showInfo });

    if (this.state.rosters.length == 0) {
      let r1 = this.props.rosters[0];
      let r2 = this.props.rosters[1];

      let a1 = this.props.allPlayers[0];
      let a2 = this.props.allPlayers[1];

      let output1 = a1.filter((player) => {
        return !r1.includes(player);
      });

      let output2 = a2.filter((player) => {
        return !r2.includes(player);
      });

      a1 = output1;
      a2 = output2;

      let nameR1 = ["", "", "", "", "", "", "", "", "", ""];
      let nameR2 = ["", "", "", "", "", "", "", "", "", ""];
      let nameA1 = ["", "", "", "", "", "", "", "", "", ""];
      let nameA2 = ["", "", "", "", "", "", "", "", "", ""];

      let posR1 = ["", "", "", "", "", "", "", "", "", ""];
      let posR2 = ["", "", "", "", "", "", "", "", "", ""];
      let posA1 = ["", "", "", "", "", "", "", "", "", ""];
      let posA2 = ["", "", "", "", "", "", "", "", "", ""];

      let nameR = [];
      let posR = [];
      let nameA = [];
      let posA = [];

      for (let i = 0; i < nflYay.length; i++) {
        for (let j = 0; j < r1.length; j++) {
          if (nflYay[i][0] == Number(r1[j])) {
            nameR1[j] = nflYay[i][1];
            posR1[j] = nflYay[i][2];
          }
        }
        for (let j = 0; j < r2.length; j++) {
          if (nflYay[i][0] == Number(r2[j])) {
            nameR2[j] = nflYay[i][1];
            posR2[j] = nflYay[i][2];
          }
        }

        //----

        for (let j = 0; j < a1.length; j++) {
          if (nflYay[i][0] == Number(a1[j])) {
            nameA1[j] = nflYay[i][1];
            posA1[j] = nflYay[i][2];
          }
        }
        for (let j = 0; j < a2.length; j++) {
          if (nflYay[i][0] == Number(a2[j])) {
            nameA2[j] = nflYay[i][1];
            posA2[j] = nflYay[i][2];
          }
        }
      }

      nameR.push(nameR1);
      nameR.push(nameR2);
      nameA.push(nameA1);
      nameA.push(nameA2);

      posR.push(posR1);
      posR.push(posR2);
      posA.push(posA1);
      posA.push(posA2);

      this.setState({
        rosters: nameR,
        positions: posR,
        Brosters: nameA,
        Bpositions: posA,
      });
    }
  }

  filterD() {
    let final = [];
    for (let i = 0; i < 9000; i++) {
      let tmp = nfl[i];
      if (
        typeof tmp !== "undefined" &&
        tmp.fantasy_positions !== null &&
        tmp.search_rank !== null &&
        tmp.search_rank < 400 &&
        (tmp.fantasy_positions[0] === "WR" ||
          tmp.fantasy_positions[0] === "RB" ||
          tmp.fantasy_positions[0] === "TE" ||
          tmp.fantasy_positions[0] === "QB" ||
          tmp.fantasy_positions[0] === "DEF" ||
          tmp.fantasy_positions[0] === "K")
      ) {
        let x = [];
        x.push(tmp.player_id);
        x.push(tmp.full_name);
        x.push(tmp.position);
        x.push(tmp.team);
        final.push(x);
      }
    }
    console.log("NFL CHEKCEY");
    console.log(final);
    console.log("[");
    for (let i = 0; i < final.length; i++) {
      let str =
        "[" +
        final[i][0] +
        ", " +
        '"' +
        final[i][1] +
        '"' +
        ", " +
        '"' +
        final[i][2] +
        '"' +
        ", " +
        '"' +
        final[i][3] +
        '"' +
        "],";
      console.log(str);
    }
    console.log("]");
  }

  checkLeftLock() {
    if (this.state.leftLock) {
      return "🔒";
    }
  }

  leftSide() {
    if (this.state.animated) {
      return (
        <Button
          onClick={() => this.selectTeam1()}
          disabled={!this.props.d}
          id="no-but1"
          style={{
            backgroundColor:
              this.state.b1 || this.props.lp.includes(this.props.matchups[0][1])
                ? "#2541B2"
                : "",
            color:
              this.state.b1 || this.props.lp.includes(this.props.matchups[0][1])
                ? "white"
                : "",
            border:
              this.state.leftLock &&
              this.props.currLock === this.props.matchups[0][1]
                ? "solid 4px gold"
                : "",
          }}
        >
          {this.props.matchups[0][0]}
        </Button>
      );
    }
  }

  rightSide() {
    if (this.state.animated) {
      return (
        <Button
          onClick={() => this.selectTeam2()}
          disabled={!this.props.d}
          id="no-but2"
          style={{
            backgroundColor:
              this.state.b2 || this.props.lp.includes(this.props.matchups[1][1])
                ? "#2541B2"
                : "",
            color:
              this.state.b2 || this.props.lp.includes(this.props.matchups[1][1])
                ? "white"
                : "",
            border:
              this.state.rightLock &&
              this.props.currLock === this.props.matchups[1][1]
                ? "solid 4px gold"
                : "",
          }}
          ref={this.r4}
        >
          {this.props.matchups[1][0]}
        </Button>
      );
    }
  }

  enter = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.2, fontWeight: "bolder" });
  };

  leave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col
              ref={this.r3}
              onMouseEnter={this.enter}
              onMouseLeave={this.leave}
            >
              {this.leftSide()}
            </Col>
            <Col xs={2} onMouseEnter={this.enter} onMouseLeave={this.leave}>
              <Button
                ref={this.r5}
                onClick={() => this.toggleInfo()}
                id="infoB"
              >
                vs.
              </Button>
            </Col>
            <Col
              ref={this.r4}
              onMouseEnter={this.enter}
              onMouseLeave={this.leave}
            >
              {this.rightSide()}
            </Col>
          </Row>
          <Row>
            <Col id="tbl" ref={this.r1}>
              {this.genTable(0)}
            </Col>
            <Col xs={2}></Col>
            <Col id="tbr" ref={this.r2}>
              {this.genTable(1)}
            </Col>
          </Row>
        </Container>
        <div id="wrapper"></div>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

const nfl = [];

const nflYay = [
  [17, "Matt Prater", "K", "ARI"],
  [23, "Jason Witten", "TE", "null"],
  [24, "Matt Ryan", "QB", "IND"],
  [49, "Robbie Gould", "K", "SF"],
  [58, "Greg Olsen", "TE", "null"],
  [96, "Aaron Rodgers", "QB", "GB"],
  [110, "Stephen Gostkowski", "K", "null"],
  [111, "Marcedes Lewis", "TE", "GB"],
  [138, "Ben Roethlisberger", "QB", "PIT"],
  [167, "Tom Brady", "QB", "TB"],
  [223, "Larry Fitzgerald", "WR", "null"],
  [232, "Frank Gore", "RB", "null"],
  [260, "Josh Johnson", "QB", "DEN"],
  [289, "Drew Brees", "QB", "null"],
  [331, "Philip Rivers", "QB", "null"],
  [344, "Julian Edelman", "WR", "null"],
  [345, "Brian Hoyer", "QB", "NE"],
  [367, "Jared Cook", "TE", "null"],
  [421, "Matthew Stafford", "QB", "LAR"],
  [515, "Rob Gronkowski", "TE", "null"],
  [536, "Antonio Brown", "WR", "null"],
  [538, "Emmanuel Sanders", "WR", "null"],
  [650, "Nick Folk", "K", "NE"],
  [676, "LeSean McCoy", "RB", "null"],
  [695, "Ted Ginn", "WR", "null"],
  [788, "Dion Lewis", "RB", "null"],
  [830, "A.J. Green", "WR", "ARI"],
  [899, "Dan Bailey", "K", "null"],
  [928, "Randall Cobb", "WR", "GB"],
  [943, "Kyle Rudolph", "TE", "TB"],
  [947, "Julio Jones", "WR", "TB"],
  [954, "Cam Newton", "QB", "null"],
  [956, "Mark Ingram", "RB", "NO"],
  [1049, "Ryan Tannehill", "QB", "TEN"],
  [1067, "Marvin Jones", "WR", "JAX"],
  [1110, "T.Y. Hilton", "WR", "null"],
  [1144, "Cole Beasley", "WR", "null"],
  [1149, "Alshon Jeffery", "WR", "null"],
  [1166, "Kirk Cousins", "QB", "MIN"],
  [1234, "Russell Wilson", "QB", "DEN"],
  [1264, "Justin Tucker", "K", "BAL"],
  [1308, "Jamize Olawale", "FB", "null"],
  [1339, "Zach Ertz", "TE", "ARI"],
  [1352, "Robert Woods", "WR", "TEN"],
  [1387, "Rex Burkhead", "RB", "HOU"],
  [1388, "Tyler Eifert", "TE", "null"],
  [1408, "Le'Veon Bell", "RB", "TB"],
  [1426, "DeAndre Hopkins", "WR", "ARI"],
  [1433, "Brandon McManus", "K", "DEN"],
  [1466, "Travis Kelce", "TE", "KC"],
  [1476, "Latavius Murray", "RB", "null"],
  [1479, "Keenan Allen", "WR", "LAC"],
  [1500, "Jordan Reed", "TE", "null"],
  [1535, "Cordarrelle Patterson", "RB", "ATL"],
  [1612, "Tyler Bray", "QB", "null"],
  [1689, "Adam Thielen", "WR", "MIN"],
  [1817, "Sammy Watkins", "WR", "GB"],
  [1825, "Jarvis Landry", "WR", "NO"],
  [1833, "Damien Williams", "RB", "ATL"],
  [1837, "Jimmy Garoppolo", "QB", "SF"],
  [1848, "James White", "RB", "null"],
  [1911, "Willie Snead", "WR", "SF"],
  [1971, "Donte Moncrief", "WR", "null"],
  [1992, "Allen Robinson", "WR", "LAR"],
  [2003, "Bennie Fowler", "WR", "null"],
  [2028, "Derek Carr", "QB", "LV"],
  [2039, "Seth Roberts", "WR", "null"],
  [2078, "Odell Beckham", "WR", "null"],
  [2118, "Eric Ebron", "TE", "PIT"],
  [2133, "Davante Adams", "WR", "LV"],
  [2161, "Jerick McKinnon", "RB", "KC"],
  [2168, "Devonta Freeman", "RB", "null"],
  [2197, "Brandin Cooks", "WR", "HOU"],
  [2214, "Cameron Brate", "TE", "TB"],
  [2216, "Mike Evans", "WR", "TB"],
  [2238, "John Brown", "WR", "TB"],
  [2251, "Logan Thomas", "TE", "WAS"],
  [2291, "Dontrelle Inman", "WR", "null"],
  [2306, "Jameis Winston", "QB", "NO"],
  [2307, "Marcus Mariota", "QB", "ATL"],
  [2309, "Amari Cooper", "WR", "CLE"],
  [2315, "Todd Gurley", "RB", "null"],
  [2319, "DeVante Parker", "WR", "NE"],
  [2320, "Melvin Gordon", "RB", "DEN"],
  [2341, "T.J. Yeldon", "RB", "null"],
  [2359, "Ameer Abdullah", "RB", "LV"],
  [2374, "Tyler Lockett", "WR", "SEA"],
  [2378, "Tevin Coleman", "RB", "NYJ"],
  [2391, "David Johnson", "RB", "null"],
  [2394, "Sean Mannion", "QB", "MIN"],
  [2410, "Jamison Crowder", "WR", "BUF"],
  [2431, "Mike Davis", "RB", "BAL"],
  [2449, "Stefon Diggs", "WR", "BUF"],
  [2450, "Brett Hundley", "QB", "null"],
  [2460, "C.J. Uzomah", "TE", "NYJ"],
  [2505, "Darren Waller", "TE", "LV"],
  [2549, "Trevor Siemian", "QB", "CHI"],
  [2583, "Tyrell Williams", "WR", "null"],
  [2671, "Josh Lambo", "K", "null"],
  [2673, "Damiere Byrd", "WR", "ATL"],
  [2749, "Raheem Mostert", "RB", "MIA"],
  [3157, "William Fuller", "WR", "null"],
  [3161, "Carson Wentz", "QB", "WAS"],
  [3163, "Jared Goff", "QB", "DET"],
  [3164, "Ezekiel Elliott", "RB", "DAL"],
  [3198, "Derrick Henry", "RB", "TEN"],
  [3199, "Michael Thomas", "WR", "NO"],
  [3200, "Sterling Shepard", "WR", "NYG"],
  [3202, "Austin Hooper", "TE", "TEN"],
  [3214, "Hunter Henry", "TE", "NE"],
  [3225, "Tyler Boyd", "WR", "CIN"],
  [3242, "Kenyan Drake", "RB", "LV"],
  [3257, "Jacoby Brissett", "QB", "CLE"],
  [3271, "Tyler Higbee", "TE", "LAR"],
  [3280, "Tyler Ervin", "RB", "null"],
  [3294, "Dak Prescott", "QB", "DAL"],
  [3297, "Tajae Sharpe", "WR", "CHI"],
  [3300, "DeAndre Washington", "RB", "null"],
  [3309, "Wendell Smallwood", "RB", "WAS"],
  [3321, "Tyreek Hill", "WR", "MIA"],
  [3343, "Nate Sudfeld", "QB", "SF"],
  [3354, "Derek Watt", "FB", "PIT"],
  [3423, "Robbie Anderson", "WR", "CAR"],
  [3664, "J.D. McKissic", "RB", "WAS"],
  [3678, "Wil Lutz", "K", "NO"],
  [3969, "Leonard Fournette", "RB", "TB"],
  [3976, "Mitch Trubisky", "QB", "PIT"],
  [4017, "Deshaun Watson", "QB", "CLE"],
  [4018, "Joe Mixon", "RB", "CIN"],
  [4029, "Dalvin Cook", "RB", "MIN"],
  [4033, "David Njoku", "TE", "CLE"],
  [4034, "Christian McCaffrey", "RB", "CAR"],
  [4035, "Alvin Kamara", "RB", "NO"],
  [4036, "Corey Davis", "WR", "NYJ"],
  [4037, "Chris Godwin", "WR", "TB"],
  [4039, "Cooper Kupp", "WR", "LAR"],
  [4040, "JuJu Smith-Schuster", "WR", "KC"],
  [4046, "Patrick Mahomes", "QB", "KC"],
  [4054, "Mo Alie-Cox", "TE", "IND"],
  [4066, "Evan Engram", "TE", "JAX"],
  [4068, "Mike Williams", "WR", "LAC"],
  [4080, "Zay Jones", "WR", "JAX"],
  [4082, "Curtis Samuel", "WR", "WAS"],
  [4089, "Gerald Everett", "TE", "LAC"],
  [4098, "Kareem Hunt", "RB", "CLE"],
  [4111, "D'Onta Foreman", "RB", "CAR"],
  [4127, "C.J. Beathard", "QB", "JAX"],
  [4131, "Kenny Golladay", "WR", "NYG"],
  [4137, "James Conner", "RB", "ARI"],
  [4144, "Jonnu Smith", "TE", "NE"],
  [4147, "Samaje Perine", "RB", "CIN"],
  [4149, "Jamaal Williams", "RB", "DET"],
  [4152, "Marlon Mack", "RB", "HOU"],
  [4157, "Tarik Cohen", "RB", "null"],
  [4183, "Nathan Peterman", "QB", "CHI"],
  [4197, "Isaiah McKenzie", "WR", "BUF"],
  [4199, "Aaron Jones", "RB", "GB"],
  [4217, "George Kittle", "TE", "SF"],
  [4227, "Harrison Butker", "K", "KC"],
  [4273, "Chris Carson", "RB", "null"],
  [4351, "Tim Patrick", "WR", "DEN"],
  [4381, "Taysom Hill", "TE", "NO"],
  [4435, "Anthony Firkser", "TE", "ATL"],
  [4454, "Kendrick Bourne", "WR", "NE"],
  [4455, "Matt Breida", "RB", "NYG"],
  [4531, "Ricky Seals-Jones", "TE", "NYG"],
  [4571, "Blake Jarwin", "TE", "null"],
  [4588, "Kyle Sloter", "QB", "null"],
  [4602, "Robert Tonyan", "TE", "GB"],
  [4663, "Austin Ekeler", "RB", "LAC"],
  [4666, "Younghoe Koo", "K", "ATL"],
  [4854, "River Cracraft", "WR", "MIA"],
  [4863, "Josh Rosen", "QB", "CLE"],
  [4866, "Saquon Barkley", "RB", "NYG"],
  [4881, "Lamar Jackson", "QB", "BAL"],
  [4892, "Baker Mayfield", "QB", "CAR"],
  [4950, "Christian Kirk", "WR", "JAX"],
  [4951, "DJ Chark", "WR", "DET"],
  [4958, "Brett Maher", "K", "DAL"],
  [4962, "Sony Michel", "RB", "MIA"],
  [4973, "Hayden Hurst", "TE", "CIN"],
  [4981, "Calvin Ridley", "WR", "ATL"],
  [4983, "DJ Moore", "WR", "CAR"],
  [4984, "Josh Allen", "QB", "BUF"],
  [4985, "Rashaad Penny", "RB", "SEA"],
  [4988, "Nick Chubb", "RB", "CLE"],
  [4992, "Dante Pettis", "WR", "CHI"],
  [4993, "Mike Gesicki", "TE", "MIA"],
  [5000, "Chase Edmonds", "RB", "MIA"],
  [5001, "Dalton Schultz", "TE", "DAL"],
  [5010, "Will Dissly", "TE", "SEA"],
  [5012, "Mark Andrews", "TE", "BAL"],
  [5022, "Dallas Goedert", "TE", "PHI"],
  [5024, "James Washington", "WR", "DAL"],
  [5038, "Michael Gallup", "WR", "DAL"],
  [5045, "Courtland Sutton", "WR", "DEN"],
  [5052, "Ronald Jones", "RB", "KC"],
  [5086, "Marquez Valdes-Scantling", "WR", "KC"],
  [5095, "Daniel Carlson", "K", "LV"],
  [5110, "Russell Gage", "WR", "TB"],
  [5113, "Cedrick Wilson", "WR", "MIA"],
  [5121, "Braxton Berrios", "WR", "NYJ"],
  [5122, "Boston Scott", "RB", "PHI"],
  [5126, "Austin Proehl", "WR", "null"],
  [5133, "Tyler Conklin", "TE", "NYJ"],
  [5185, "Allen Lazard", "WR", "GB"],
  [5189, "Eddy Pineiro", "K", "NYJ"],
  [5199, "Byron Pringle", "WR", "CHI"],
  [5248, "Gus Edwards", "RB", "BAL"],
  [5284, "Jeff Wilson", "RB", "SF"],
  [5347, "Nyheim Hines", "RB", "IND"],
  [5432, "Cam Sims", "WR", "WAS"],
  [5536, "Dontrell Hilliard", "RB", "TEN"],
  [5549, "Darrel Williams", "RB", "ARI"],
  [5844, "T.J. Hockenson", "TE", "DET"],
  [5846, "DK Metcalf", "WR", "SEA"],
  [5848, "Marquise Brown", "WR", "ARI"],
  [5849, "Kyler Murray", "QB", "ARI"],
  [5850, "Josh Jacobs", "RB", "LV"],
  [5854, "Drew Lock", "QB", "SEA"],
  [5857, "Noah Fant", "TE", "SEA"],
  [5859, "A.J. Brown", "WR", "PHI"],
  [5870, "Daniel Jones", "QB", "NYG"],
  [5872, "Deebo Samuel", "WR", "SF"],
  [5880, "Parris Campbell", "WR", "IND"],
  [5889, "Bryce Love", "RB", "null"],
  [5890, "Damien Harris", "RB", "NE"],
  [5892, "David Montgomery", "RB", "CHI"],
  [5903, "Will Grier", "QB", "DAL"],
  [5906, "Dawson Knox", "TE", "BUF"],
  [5916, "Darrell Henderson", "RB", "LAR"],
  [5917, "Mecole Hardman", "WR", "KC"],
  [5924, "Emanuel Hall", "WR", "null"],
  [5927, "Terry McLaurin", "WR", "WAS"],
  [5937, "Diontae Johnson", "WR", "PIT"],
  [5947, "Jakobi Meyers", "WR", "NE"],
  [5955, "Hunter Renfrow", "WR", "LV"],
  [5965, "Miles Boykin", "WR", "PIT"],
  [5967, "Tony Pollard", "RB", "DAL"],
  [5980, "Myles Gaskin", "RB", "MIA"],
  [5987, "Alexander Mattison", "RB", "MIN"],
  [6002, "Qadree Ollison", "RB", "ATL"],
  [6074, "Donald Parham", "TE", "LAC"],
  [6083, "Matt Gay", "K", "LAR"],
  [6126, "Irv Smith", "TE", "MIN"],
  [6130, "Devin Singletary", "RB", "BUF"],
  [6139, "Jace Sternberger", "TE", "PIT"],
  [6151, "Miles Sanders", "RB", "PHI"],
  [6171, "Cody Thompson", "WR", "SEA"],
  [6196, "Christian Wade", "RB", "null"],
  [6268, "Matthew Wright", "K", "null"],
  [6298, "Chandler Cox", "FB", "null"],
  [6334, "Damarea Crockett", "RB", "DEN"],
  [6363, "Michael Walker", "WR", "null"],
  [6387, "Nsimba Webster", "WR", "CHI"],
  [6421, "Jalen Guyton", "WR", "LAC"],
  [6450, "David Blough", "QB", "DET"],
  [6453, "D.J. Montgomery", "WR", "IND"],
  [6628, "Ventell Bryant", "WR", "null"],
  [6650, "Chase McLaughlin", "K", "null"],
  [6694, "D'Ernest Johnson", "RB", "CLE"],
  [6730, "Jordan Ta'amu", "QB", "null"],
  [6768, "Tua Tagovailoa", "QB", "MIA"],
  [6770, "Joe Burrow", "QB", "CIN"],
  [6778, "Chris Streveler", "QB", "NYJ"],
  [6783, "Jerry Jeudy", "WR", "DEN"],
  [6786, "CeeDee Lamb", "WR", "DAL"],
  [6789, "Henry Ruggs", "WR", "null"],
  [6790, "D'Andre Swift", "RB", "DET"],
  [6794, "Justin Jefferson", "WR", "MIN"],
  [6797, "Justin Herbert", "QB", "LAC"],
  [6801, "Tee Higgins", "WR", "CIN"],
  [6803, "Brandon Aiyuk", "WR", "SF"],
  [6804, "Jordan Love", "QB", "GB"],
  [6805, "KJ Hamler", "WR", "DEN"],
  [6806, "J.K. Dobbins", "RB", "BAL"],
  [6813, "Jonathan Taylor", "RB", "IND"],
  [6814, "Laviska Shenault", "WR", "JAX"],
  [6819, "Michael Pittman", "WR", "IND"],
  [6820, "Clyde Edwards-Helaire", "RB", "KC"],
  [6823, "Jacob Eason", "QB", "SEA"],
  [6824, "Donovan Peoples-Jones", "WR", "CLE"],
  [6826, "Cole Kmet", "TE", "CHI"],
  [6828, "AJ Dillon", "RB", "GB"],
  [6843, "Albert Okwuegbunam", "TE", "DEN"],
  [6847, "Devin Duvernay", "WR", "BAL"],
  [6853, "Van Jefferson", "WR", "LAR"],
  [6865, "Colby Parkinson", "TE", "SEA"],
  [6869, "Adam Trautman", "TE", "NO"],
  [6870, "Bryan Edwards", "WR", "ATL"],
  [6885, "Ke'Shawn Vaughn", "RB", "TB"],
  [6886, "Chase Claypool", "WR", "PIT"],
  [6904, "Jalen Hurts", "QB", "PHI"],
  [6920, "Isaiah Hodgins", "WR", "BUF"],
  [6938, "Cam Akers", "RB", "LAR"],
  [6943, "Gabe Davis", "WR", "BUF"],
  [6945, "Antonio Gibson", "RB", "WAS"],
  [6951, "Eno Benjamin", "RB", "ARI"],
  [6955, "James Robinson", "RB", "JAX"],
  [6992, "Michael Warren", "RB", "DET"],
  [7021, "Rico Dowdle", "RB", "DAL"],
  [7042, "Tyler Bass", "K", "BUF"],
  [7045, "Joshua Kelley", "RB", "LAC"],
  [7050, "Josiah Deguara", "TE", "GB"],
  [7062, "Rodrigo Blankenship", "K", "IND"],
  [7064, "Darrynton Evans", "RB", "CHI"],
  [7066, "K.J. Osborn", "WR", "MIN"],
  [7075, "Charlie Woerner", "TE", "SF"],
  [7082, "Dalton Keene", "TE", "NE"],
  [7084, "Jake Luton", "QB", "null"],
  [7085, "Isaiah Coulter", "WR", "CHI"],
  [7086, "John Hightower", "WR", "PHI"],
  [7087, "Jonathan Ward", "RB", "ARI"],
  [7090, "Darnell Mooney", "WR", "CHI"],
  [7091, "Aaron Parker", "WR", "KC"],
  [7092, "Chris Finke", "WR", "CHI"],
  [7103, "Tavien Feaster", "RB", "ARI"],
  [7143, "Ben DiNucci", "QB", "DAL"],
  [7159, "Reid Sinnett", "QB", "PHI"],
  [7234, "Maurice Ffrench", "WR", "null"],
  [7314, "Dominik Eberle", "K", "null"],
  [7335, "Bryce Perkins", "QB", "LAR"],
  [7446, "Tucker McCann", "K", "null"],
  [7496, "Nick Westbrook-Ikhine", "WR", "TEN"],
  [7503, "Connor Davis", "TE", "null"],
  [7523, "Trevor Lawrence", "QB", "JAX"],
  [7525, "DeVonta Smith", "WR", "PHI"],
  [7526, "Jaylen Waddle", "WR", "MIA"],
  [7527, "Mac Jones", "QB", "NE"],
  [7528, "Najee Harris", "RB", "PIT"],
  [7531, "Feleipe Franks", "QB", "ATL"],
  [7534, "Seth Williams", "WR", "DEN"],
  [7538, "Zach Wilson", "QB", "NYJ"],
  [7540, "Amari Rodgers", "WR", "GB"],
  [7543, "Travis Etienne", "RB", "JAX"],
  [7547, "Amon-Ra St. Brown", "WR", "DET"],
  [7553, "Kyle Pitts", "TE", "ATL"],
  [7560, "Pooka Williams", "RB", "null"],
  [7561, "Elijah Mitchell", "RB", "SF"],
  [7564, "Ja'Marr Chase", "WR", "CIN"],
  [7565, "Terrace Marshall", "WR", "CAR"],
  [7567, "Kenneth Gainwell", "RB", "PHI"],
  [7568, "Brevin Jordan", "TE", "HOU"],
  [7569, "Nico Collins", "WR", "HOU"],
  [7571, "Rashod Bateman", "WR", "BAL"],
  [7585, "Davis Mills", "QB", "HOU"],
  [7588, "Javonte Williams", "RB", "DEN"],
  [7589, "Ian Book", "QB", "NO"],
  [7591, "Justin Fields", "QB", "CHI"],
  [7593, "Trey Sermon", "RB", "SF"],
  [7594, "Chuba Hubbard", "RB", "CAR"],
  [7596, "Elijah Moore", "WR", "NYJ"],
  [7597, "Kenny Yeboah", "TE", "NYJ"],
  [7600, "Pat Freiermuth", "TE", "PIT"],
  [7601, "Rondale Moore", "WR", "ARI"],
  [7603, "Shi Smith", "WR", "CAR"],
  [7606, "Kadarius Toney", "WR", "NYG"],
  [7607, "Michael Carter", "RB", "NYJ"],
  [7608, "Khalil Herbert", "RB", "CHI"],
  [7609, "Demetric Felton", "RB", "CLE"],
  [7610, "Trey Lance", "QB", "SF"],
  [7611, "Rhamondre Stevenson", "RB", "NE"],
  [7622, "Sammis Reyes", "TE", "WAS"],
  [7670, "Joshua Palmer", "WR", "LAC"],
  [7729, "Dez Fitzpatrick", "WR", "TEN"],
  [7794, "Chris Evans", "RB", "CIN"],
  [7839, "Evan McPherson", "K", "CIN"],
  [7863, "JaQuan Hardy", "RB", "DEN"],
  [7918, "A.J. Rose", "RB", "LAR"],
  [7922, "Riley Patterson", "K", "DET"],
  [7933, "Alex Kessman", "K", "CAR"],
  [7938, "Hunter Kampmoyer", "TE", "LAC"],
  [7956, "Cade Johnson", "WR", "SEA"],
  [7961, "Jose Borregales", "K", "TB"],
  [7972, "Tony Poljan", "TE", "BAL"],
  [8005, "Tarik Black", "WR", "NYJ"],
  [8008, "Josh Imatorbhebhe", "WR", "null"],
  [8033, "Bernhard Seikovits", "TE", "ARI"],
  [8040, "Quinn Nordin", "K", "null"],
  [8042, "Chris Naggar", "K", "null"],
  [8056, "Jake Verity", "K", "IND"],
  [8077, "Otis Anderson", "RB", "null"],
  [8102, "DeShon Williams", "TE", "null"],
  [8105, "Brayden Lenius", "TE", "null"],
  [8111, "Cade Otton", "TE", "TB"],
  [8112, "Drake London", "WR", "ATL"],
  [8116, "Pierre Strong", "RB", "NE"],
  [8117, "Jalen Tolbert", "WR", "DAL"],
  [8118, "David Bell", "WR", "CLE"],
  [8119, "Jahan Dotson", "WR", "WAS"],
  [8121, "Romeo Doubs", "WR", "GB"],
  [8123, "Hassan Haskins", "RB", "TEN"],
  [8125, "Calvin Austin", "WR", "PIT"],
  [8126, "Wan'Dale Robinson", "WR", "NYG"],
  [8129, "Dameon Pierce", "RB", "HOU"],
  [8130, "Trey McBride", "TE", "ARI"],
  [8132, "Tyler Allgeier", "RB", "ATL"],
  [8134, "Khalil Shakir", "WR", "BUF"],
  [8135, "Treylon Burks", "WR", "TEN"],
  [8136, "Rachaad White", "RB", "TB"],
  [8137, "George Pickens", "WR", "PIT"],
  [8138, "James Cook", "RB", "BUF"],
  [8139, "Zamir White", "RB", "LV"],
  [8140, "Justyn Ross", "WR", "KC"],
  [8142, "Alec Pierce", "WR", "IND"],
  [8144, "Chris Olave", "WR", "NO"],
  [8146, "Garrett Wilson", "WR", "NYJ"],
  [8147, "John Metchie", "WR", "HOU"],
  [8148, "Jameson Williams", "WR", "DET"],
  [8149, "Master Teague", "RB", "PIT"],
  [8150, "Kyren Williams", "RB", "LAR"],
  [8151, "Kenneth Walker", "RB", "SEA"],
  [8153, "Isaiah Spiller", "RB", "LAC"],
  [8154, "Brian Robinson", "RB", "WAS"],
  [8155, "Breece Hall", "RB", "NYJ"],
  [8159, "Desmond Ridder", "QB", "ATL"],
  [8160, "Kenny Pickett", "QB", "PIT"],
  [8161, "Malik Willis", "QB", "TEN"],
  [8162, "Sam Howell", "QB", "WAS"],
  [8164, "Matt Corral", "QB", "CAR"],
  [8167, "Christian Watson", "WR", "GB"],
  [8168, "Skyy Moore", "WR", "KC"],
  [8172, "Greg Dulcich", "TE", "DEN"],
  [8178, "Braylon Sanders", "WR", "MIA"],
  [8179, "Snoop Conner", "RB", "JAX"],
  [8188, "Tyquan Thornton", "WR", "NE"],
  [8190, "Abram Smith", "RB", "NO"],
  [8200, "Kevin Austin", "WR", "JAX"],
  [8205, "Isiah Pacheco", "RB", "KC"],
  [8208, "Tyler Badie", "RB", "BAL"],
  [8211, "Tyrion Davis-Price", "RB", "SF"],
  [8212, "Dustin Crum", "QB", "KC"],
  [8215, "Austin Allen", "TE", "NYG"],
  [8219, "Jelani Woods", "TE", "IND"],
  [8221, "Keaontay Ingram", "RB", "ARI"],
  [8223, "Velus Jones", "WR", "CHI"],
  [8225, "Daniel Bellinger", "TE", "NYG"],
  [8228, "Jaylen Warren", "RB", "PIT"],
  [8229, "Isaiah Weston", "WR", "null"],
  [8236, "Jerreth Sterns", "WR", "TB"],
  [8259, "Cameron Dicker", "K", "null"],
  [8260, "Gabe Brkic", "K", "GB"],
  [8436, "Neil Pau'u", "WR", "BUF"],
  [8438, "Corey Sutton", "WR", "null"],
  [8527, "Dareke Young", "WR", "SEA"],
  [8536, "Caleb Shudak", "K", "TEN"],
  [8577, "James McCourt", "K", "LAC"],
  [8579, "Brandon Peters", "QB", "null"],
  [8665, "Armani Rogers", "TE", "WAS"],
  [8670, "John Parker Romo", "K", "null"],
  [8706, "Kehinde Oginni Hassan", "TE", "KC"],
  [8721, "De'Montre Tuggle", "RB", "CHI"],
  [8741, "Jamal Pettigrew", "TE", "null"],
  [8745, "Lance McCutcheon", "WR", "LAR"],
  [8760, "Andre Miller", "TE", "NYG"],
  [8783, "Kendric Pryor", "WR", "CIN"],
  [8801, "Dennis Houston", "WR", "DAL"],
  [8812, "Jonathan Garibay", "K", "null"],
  [8924, "Thomas Odukoya", "TE", "TEN"],
];

export default Matchup;
