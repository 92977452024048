import PRContainer from "../PRContainer";
import { DragDropContext } from "react-beautiful-dnd";
import React from "react";
import {
  usersReference,
  updateUser,
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  keepersReference,
  sleep,
  rankingsReference,
  currYear,
} from "../App";
import { getWinnersArr } from "../StatsContainer";
import { gsap } from "gsap";
import { getDocs, addDoc, query, where } from "firebase/firestore";

class PowerRanking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadCount: 0,
      nameIdArr: [],
      info: {},
      done: false,
      subMessage: "NOT SUBMITTED"
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.r8 = React.createRef();
    this.r9 = React.createRef();
    this.r10 = React.createRef();
  }

  componentDidMount() {
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.loadTeams();
    }
  }

  async loadTeams() {
    let week = getWeek();
    let winnersArr = await getWinnersArr();
    console.log("WANG")
    console.log(winnersArr)
    let Uid = await getId(usersReference);
    let url = "https://api.sleeper.app/v1/league/" + leagueId + "/users";
    //array for names
    let allTeams = [];
    let info = {
      teams: [],
      columns: {
        1: {
          id: "column-1",
          title: "Pick Em",
          teamIds: [],
        },
      },
      columnOrder: ["1"],
    };

    let q = query(
      rankingsReference,
      where("Week", "==", week),
      where("Tid", "==", Uid),
      where("Year", "==", currYear),
    );

    const data = await getDocs(q);
    if (data.empty) {
      // retrieve all the owner ids
      fetch(url, {
        method: "GET",
        headers: {
          // 'x-access-token': t
        },
      })
        .then((res) => res.json())
        .then((res) => {
          //iterate through teams and get names and ids
          let teams = [];
          for (let i = 0; i < res.length; i++) {
            if (res[i].user_id === Uid) {
              continue;
            }
            let wins = 0;
            let losses = 0;
            if (typeof res[i].metadata.team_name === "undefined") {
              for (let j = 1; j < week; j++) {
                if (winnersArr[j].data.picks.includes(res[i].user_id)) {
                  wins++;
                } else {
                  losses++;
                }
              }
              info.teams.push([
                res[i].user_id,
                res[i].display_name,
                i,
                [wins, losses],
              ]);
              info.columns["1"].teamIds.push(res[i].user_id);
            } else {
              for (let j = 1; j < week; j++) {
                if (
                  winnersArr[j].data.picks.includes(res[i].user_id)
                ) {
                  wins++;
                } else {
                  losses++;
                }
              }
              info.teams.push([
                res[i].user_id,
                res[i].metadata.team_name,
                i,
                [wins, losses],
              ]);
              info.columns["1"].teamIds.push(res[i].user_id);
              console.log("INFO")
              console.log(info)
            }
          }
          let randomized = [];
          let size = info.teams.length;

          for (let i = 0; i < size; i++) {
            let toPush = Math.floor(Math.random() * (size - i));

            randomized.push(info.teams[toPush]);
            info.teams.splice(toPush, 1);
          }

          info.teams = [...randomized];

          this.setState({ info: info });
        })
        .then(() => {});
    } else {
      
      let rankings = data.docs[0].data();

      for (let i = 0; i < rankings.Rankings.length; i++) {
        let wins = 0
        let losses = 0
        for (let j = 1; j < week; j++) {
          if (winnersArr[j].data.picks.includes(rankings.Rankings[i])) {
            wins++;
          } else {
            losses++;
          }
        }
        info.teams.push([
          rankings.Rankings[i],
          rankings.Names[i],
          i,
          [wins, losses],
        ]);
      }

      this.setState({ info: info, done: true, subMessage: "SUBMITTED" });
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let all = this.state.info;
    let arr = this.state.info.teams;
    let [changedTeam] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, changedTeam);

    all.teams = [...arr];

    this.setState({ info: all });
  };

  showPR() {
    if (this.state.info.columns) {

      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <PRContainer
            key={this.state.info.columns["1"].id}
            column={this.state.info.columns["1"]}
            teams={this.state.info.teams}
            done={this.state.done}
            subMessage={this.state.subMessage}
          />
        </DragDropContext>
      );
    }
  }

  render() {
    return <div>{this.showPR()}</div>;
  }
}

export default PowerRanking;
