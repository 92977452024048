import PickemContainer from "../PickemContainer";

const Pickem = () => {
    return (
      <div>
        <h1><PickemContainer/></h1>
      </div>
    );
  };

  export default Pickem;