import React from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { usersReference, updateUser, getId, keepersReference } from "./App";
import App from "./App";
import { db } from "./Firebase";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
// import { usersReference, updateUser, picksReference, getId } from "./App";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { sleep } from "./App";
import { gsap } from "gsap";

class KeeperPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keepers: [],
      b1: false,
      b2: false,
      loadCount: 0,
      animated: false,
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
  }

  animate = async (a) => {
    await sleep(50);
    if (this.state.loadCount == a) {
      gsap.from(this.r1.current, {
        x: "-100%",
        duration: 2,
        ease: "power4",
        scale: 0.5,
      });
      gsap.from(this.r2.current, {
        x: "100%",
        duration: 2,
        ease: "power4",
        scale: 0.5,
      });
      this.setState({ animated: true });
    }
  };

  componentDidMount() {
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.animate(d);
    }
  }

  async kOne() {
    await this.props.kOneFunc(this.props.name);
  }

  async kTwo() {
    await this.props.kTwoFunc(this.props.name);

    if (this.props.k2.includes(this.props.name)) {
      this.setState({ b2: true });
    } else {
      this.setState({ b2: false });
    }
  }

  leftSide() {
    if (this.state.animated) {
      return (
        <Button
          id="kb"
          onClick={() => this.kOne()}
          style={{
            backgroundColor:
              this.props.k1[0] === this.props.name ? "#2541B2" : "",
            color: this.props.k1[0] === this.props.name ? "white" : "",
          }}
          disabled={
            this.props.k2.includes(this.props.name) ||
            this.props.alreadyDone ||
            this.props.spinning
          }
        >
          {this.props.name} ({this.props.pick})
        </Button>
      );
    }
  }

  rightSide() {
    if (this.state.animated) {
      return (
        <Button
          id="kb"
          onClick={() => this.kTwo()}
          style={{
            backgroundColor:
              this.state.b2 ||
              (this.props.k2.includes(this.props.name) &&
                this.props.alreadyDone)
                ? "#2541B2"
                : "",
            color:
              this.state.b2 ||
              (this.props.k2.includes(this.props.name) &&
                this.props.alreadyDone)
                ? "white"
                : "",
          }}
          disabled={
            this.props.k1.includes(this.props.name) ||
            (this.props.k2.length == 3 &&
              !this.props.k2.includes(this.props.name)) ||
            !this.props.twoV ||
            (this.props.selection != "" && !this.props.testRun) ||
            this.props.spinning
          }
        >
          {this.props.name} ({this.props.pick})
        </Button>
      );
    }
  }

  enter = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.2, fontWeight: "bolder" });
  };

  leave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col ref={this.r1} onMouseEnter={this.enter}  onMouseLeave={this.leave}>{this.leftSide()}</Col>
            <Col ref={this.r2} onMouseEnter={this.enter}  onMouseLeave={this.leave}>{this.rightSide()}</Col>
          </Row>
        </Container>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default KeeperPlayer;
