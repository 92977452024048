import React from "react";
import { getId, leagueId } from "./App";
import Team from "./Team";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { getDocs, query, where } from "firebase/firestore";
import { usersReference, updateUser } from "./App";
import { gsap } from "gsap";
import { sleep } from "./App";

class TeamContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ids1: [],
      names1: [],
      users: [],
      users1: [],
      selected: "",
      sId: "",
      lockAll: false,
      myId: "",
      error: false,
      loadCount: 0,
      invalid: true,
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.r8 = React.createRef();
    this.r9 = React.createRef();
    this.r10 = React.createRef();
  }

  async idSet() {
    let id = await getId(usersReference);

    if (id != "") {
      if (id == -1) {
        this.setState({ error: true });
      }
      this.setState({ myId: id, lockAll: true });
    } else {
        this.setState({ myId: id });
      
    }
  }

  animate = async (a) => {
    await sleep(50);
    if (this.state.loadCount == a) {
      gsap.from(this.r1.current, {
        duration: 2,
        ease: "power4",
        scale: 0.5,
      });
      gsap.from(this.r2.current, {
        y: "100%",
        duration: 2,
        ease: "power4",
        scale: 1,
      });
      gsap.from(this.r3.current, {
        y: "400%",
        duration: 2,
        ease: "bounce",
        scale: 1,
      });
    }
  };

  componentDidMount() {
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.animate(d);
    }

    if (this.state.names1.length == 0) {
      this.idSet();

      let url = "https://api.sleeper.app/v1/league/" + leagueId + "/users";
      let nameArr = []; //array for names
      let idArr = []; //array for ids

      // retrieve all the owner ids
      fetch(url, {
        method: "GET",
        headers: {
          // 'x-access-token': t
        },
      })
        .then((res) => res.json())
        .then((res) => {
          //iterate through teams and get names and ids
          for (let i = 0; i < res.length; i++) {
            if (typeof res[i].metadata.team_name === "undefined") {
              nameArr.push(res[i].display_name);
            } else {
              nameArr.push(res[i].metadata.team_name);
            }
            idArr.push(res[i].user_id);
          }

          this.setState({ ids1: nameArr, users: idArr });
        })
        .then(() => {
          this.setState({ names1: nameArr, users1: idArr });
        });
    }
  }

  alreadySelected = () => {
    this.setState({ lockAll: true });
  };

  async checkLengths() {
    let r = await this.state.users1;
    return r;
  }

  showTeams() {
    let r = [];
    let test = 0;

    if (this.state.users1.length == 0) {
      test = this.checkLengths();
    }

    // while(this.state.users1.length == 0) {

    // }

    if (this.state.users1.length > 0) {
      for (let i = 0; i < this.state.ids1.length; i++) {
        r.push(
          <Team
            name={this.state.names1[i]}
            id={this.state.users1[i]}
            teamSelected={this.teamSelected}
            selection={this.state.selection}
            lockAll={this.state.lockAll}
            myId={this.state.myId}
          />
        );
      }
    }

    return r;
  }

  teamSelected = (data) => {
    this.setState({ selection: data[0], sId: data[1], invalid: false });
  };

  async submit() {

    if (localStorage.getItem("name") === "guest") {
      this.setState({ lockAll: true });
      return;
    }

    let q = query(
      usersReference,
      where("Email", "==", localStorage.getItem("email"))
    );
    const data = await getDocs(q);

    if (!data.empty) {
      updateUser(data.docs[0].id, this.state.sId);
      this.setState({ lockAll: true });
      localStorage.setItem("teamName", this.state.selection);
    }
  }

  showError() {
    if (this.state.error) {
      return (
        <div style={{ color: "red", fontSize: "12px" }}>
          ERROR: Please log out and log back in and it should work.
        </div>
      );
    }
  }

  showPage1() {
    if (this.state.users1.length > 0) {
      return (
        <Container>
          <Row>
            <Col
              style={{
                marginTop: "10px",
                fontSize: "30px",
                fontWeight: "bolder",
              }}
            >
              Team Selection
            </Col>
          </Row>
          <Row>
            <Col>{this.showError()}</Col>
          </Row>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
        </Container>
      );
    }
  }

  showPage2() {
    if (this.state.users1.length > 0) {
      return (
        <Container>
          <Row>
            <Col> {this.showTeams()}</Col>
          </Row>
          <Row>
            <Col
              id="horDiv"
              style={{ marginTop: "10px", marginBottom: "5px" }}
            ></Col>
          </Row>
        </Container>
      );
    }
  }

  showPage3() {
    if (this.state.users1.length > 0) {
      return (
        <Container>
          <Row>
            <Col>
              <Button
                id="TCSubmit"
                onClick={() => this.submit()}
                disabled={this.state.lockAll || this.state.invalid}
                onMouseEnter={this.enter}
                onMouseLeave={this.leave}
                ref={this.r5}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  enter = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.2, fontWeight: "bolder" });
  };

  leave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  render() {
    return (
      <div>
        <div ref={this.r1}>{this.showPage1()}</div>
        <div ref={this.r2}>{this.showPage2()}</div>
        <div ref={this.r3}>{this.showPage3()}</div>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default TeamContainer;
