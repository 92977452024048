import React from "react";
import Team from "./Team";
import { analyzePRWeek } from "./StatsWeek";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { Pie } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import {
  usersReference,
  updateUser,
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  keepersReference,
  sleep,
} from "./App";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { keepArr } from "./KeeperContainer";
import { getWinnersArr } from "./StatsContainer";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

class StatsYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadCount: 0,
      sortedRecords: [],
      sortedFA: [],
      onlyCash: [],
      key1: "best",
      key2: "best",
      EVR: [],
      rankGraph: [],
      rankGraphOptions: [],
    };
  }

  componentDidMount() {
    if (this.state.loadCount == 0) {
      this.calcData();
      this.setState({ loadCount: this.state.loadCount++ });
    }
    if (this.state.rankGraph.length == 0) {
      this.createRankPlot();
    }
  }

  createRankPlot() {
    let week = getWeek();

    let allData = this.props.nameIdArr;

    let currentValid = 0;

    if (this.props.rankingData.filter((r) => r.Week == week).length > 1) { // >1 because two are needed for all 10 ranked
      currentValid = 1;
    }

    for (let i = 1; i < week + currentValid; i++) {
      let weekRanks = analyzePRWeek(
        this.props.rankingData.filter((r) => r.Week == i),
        this.props.nameIdArr
      );

      for (let j = 0; j < allData.length; j++) {
        for (let h = 0; h < weekRanks.length; h++) {
          if (allData[j][0] === weekRanks[h][0]) {
            allData[j].push(h + 1);
            break;
          }
        }
      }
    }

    //the rank of each team each week

    let options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          reverse: "true",
          title: {
            display: "true",
            text: "Rank",
          },
        },
        x: {
          title: {
            display: "true",
            text: "Week",
          },
        },
      },
    };
    let labels = [];

    for (let i = 1; i < week + currentValid; i++) {
      labels.push(i);
    }

    let colors = [
      "rgba(255,0,0,0.3)",
      "rgba(255,255,0,0.3)",
      "rgba(0,234,255,0.3)",
      "rgba(170,0,255,0.3)",
      "rgba(255,127,0,0.3)",
      "rgba(191,255,0,0.3)",
      "rgba(0,149,255,0.3)",
      "rgba(255,0,170,0.3)",
      "rgba(255,212,0,0.3)",
      "rgba(106,255,0,0.3)",
      "rgba(0,64,255,0.3)",
      "rgba(237,185,185,0.3)",
      "rgba(185,215,237,0.3)",
      "rgba(231,233,185,0.3)",
      "rgba(220,185,237,0.3)",
      "rgba(185,237,224,0.3)",
      "rgba(143,35,35,0.3)",
      "rgba(35,98,143,0.3)",
      "rgba(143,106,35,0.3)",
      "rgba(107,35,143,0.3)",
    ];

    let borderColors = [
      "rgba(255,0,0,0.7)",
      "rgba(255,255,0,0.7)",
      "rgba(0,234,255,0.7)",
      "rgba(170,0,255,0.7)",
      "rgba(255,127,0,0.7)",
      "rgba(191,255,0,0.7)",
      "rgba(0,149,255,0.7)",
      "rgba(255,0,170,0.7)",
      "rgba(255,212,0,0.7)",
      "rgba(106,255,0,0.7)",
      "rgba(0,64,255,0.7)",
      "rgba(237,185,185,0.7)",
      "rgba(185,215,237,0.7)",
      "rgba(231,233,185,0.7)",
      "rgba(220,185,237,0.7)",
      "rgba(185,237,224,0.7)",
      "rgba(143,35,35,0.7)",
      "rgba(35,98,143,0.7)",
      "rgba(143,106,35,0.7)",
      "rgba(107,35,143,0.7)",
    ];

    let data = {
      labels,
      scale: "reverse",
      datasets: allData.map((team, ind, arr) => {

        let d = [...team];
        d = d.slice(2);

        return {
          label: team[1],
          data: d,
          borderColor: borderColors[ind],
          backgroundColor: colors[ind],
          tension: 0.1,
          borderWidth: 5
        };
      }),
    };

    this.setState({ rankGraph: data, rankGraphOptions: options });
  }

  getCashList = (cash) => {
    var table = document.getElementById("cashTable2");
    for (let i = -1; i < cash.length; i++) {
      var row = table.insertRow();
      if (i == -1) {
        var cell1 = row.insertCell();
        var cell2 = row.insertCell();
        var cell3 = row.insertCell();
        var cell4 = row.insertCell();
        cell1.innerHTML = "Rank";
        cell2.innerHTML = "Team";
        cell3.innerHTML = "Wins";
        cell4.innerHTML = "$";
        cell4.style.color = "green";
        cell4.style.fontWeight = "bold";
      } else {
        let rowContent = [
          i + 1,
          this.props.nameIdArr[cash[i][3]][1],
          cash[i][4],
          cash[i][0] + cash[i][1] + cash[i][2],
        ];

        if (cash[i][3] == this.props.myIndex) {
          row.style.fontWeight = "bolder";
        }
        for (let j = 0; j < rowContent.length; j++) {
          var cell1 = row.insertCell();
          cell1.innerHTML = rowContent[j];
        }
      }
    }
  };

  async calcData() {
    let week = await getWeek();
    let winnersArr = await getWinnersArr();
    // await sleep(500)
    let recordPercentage = this.props.recordsArr.map((record, index) => {
      if (
        isNaN(
          (record[0] * 1.0) /
            this.props.whoEachPersonPicks[index][
              this.props.whoEachPersonPicks.length
            ]
        )
      ) {
        let tmp = [];
        tmp.push(-1);
        tmp.push(index);
        return tmp;
      } else {
        let tmp = [];
        tmp.push(
          (record[0] * 1.0) / (record[1] + record[0])
          // ((this.props.whoEachPersonPicks[index][
          //   this.props.whoEachPersonPicks.length
          // ] *
          //   this.props.whoEachPersonPicks.length) /
          //   2)
        );
        tmp.push(index);
        return tmp;
      }
    });

    let sortedPercentages = recordPercentage
      .filter((record) => record[0] > 0)
      .sort((a, b) => {
        if (a[0] == b[0]) {
          return (
            this.props.recordsArr[b[1]][0] - this.props.recordsArr[a[1]][0]
          );
        }
        return b[0] - a[0];
      });

    let sortedRecords = sortedPercentages.map((p) => {
      let tmp = [];
      tmp.push(this.props.recordsArr[p[1]]);
      tmp.push(p[1]);
      return tmp;
    });

    if (
      sortedPercentages.length >= 2 &&
      sortedPercentages[sortedPercentages.length - 1][0] ==
        sortedPercentages[sortedPercentages.length - 2][0]
    ) {
      if (
        sortedRecords[sortedRecords.length - 1][0][0] <
        sortedRecords[sortedRecords.length - 2][0][0]
      ) {
        let tmp1 = [...sortedRecords[sortedRecords.length - 1]];
        let tmp2 = [...sortedRecords[sortedRecords.length - 2]];
        sortedRecords[sortedRecords.length - 2] = [...tmp1];
        sortedRecords[sortedRecords.length - 1] = [...tmp2];
      }
    }

    //get who picks
    let pickCount = this.props.pickEmResults.length;

    let sortedFA = this.props.whoEveryonePicks
      .map((count, index) => {
        let tmp1 = [];
        tmp1.push(count);
        tmp1.push(pickCount - count);

        let tmp = [];
        tmp.push(tmp1);
        tmp.push(this.props.nameIdArr[index][1]);
        return tmp;
      })
      .sort((a, b) => b[0][0] - a[0][0]);

    // cash table generation

    let justCash = this.props.cash
      .map((team, i) => [...team, i])
      .filter((a) => a[0] + a[1] + a[2] > 0)
      .sort((a, b) => b[0] + b[1] + b[2] - (a[0] + a[1] + a[2]))
      .map((winner) => {
        let wins = 0;
        for (let i = 1; i < week; i++) {
          if (
            winnersArr[i].data.winner_info[0] ===
            this.props.nameIdArr[winner[3]][0]
          ) {
            wins++;
          }
        }
        return [...winner, wins];
      });

    //only show top 3
    justCash.splice(3, justCash.length - 3);

    //calc closest tb
    let tbs = [];

    for (let i = 1; i < week; i++) {
      let currTB = Number(winnersArr[i].data.tiebreaker);
      tbs.push(
        ...this.props.pickEmResults
          .filter((a) => a.Week == i)
          .map((p) => {
            return [Math.abs(currTB - Number(p.TieBreaker)), i, p.Tid];
          })
      );
    }

    let tbs1 = tbs
      .sort((a, b) => a[0] - b[0])
      .map((t) => [Number(t[0].toFixed(1)), t[1], t[2]]);

    //calc expectation v reality
    let EVR = this.props.nameIdArr
      .map((team) => {
        let wins = 0;
        for (let i = 1; i < week; i++) {
          if (winnersArr[i].data.picks.includes(team[0])) {
            wins++;
          }
        }
        let winPercentage = Math.round(((wins * 1.0) / (week - 1)) * 100);
        return [winPercentage, team[0], team[1]];
      })
      .map((team) => {
        for (let i = 0; i < sortedFA.length; i++) {
          if (sortedFA[i][1] == team[2]) {
            let pickPercentage = Math.round(
              ((sortedFA[i][0][0] * 1.0) /
                (sortedFA[i][0][1] + sortedFA[i][0][0])) *
                100
            );
            return [
              team[0],
              pickPercentage,
              team[0] - pickPercentage,
              team[1],
              team[2],
            ];
          }
        }
      })
      .sort((a, b) => b[2] - a[2]);

    this.setState({
      sortedRecords: sortedRecords,
      sortedFA: sortedFA,
      onlyCash: justCash,
      sortedTBs: tbs1,
      sortedEVR: EVR,
    });

    this.getCashList(justCash);
    this.getRecordList(sortedRecords);
    this.getPicksList(sortedFA);
  }

  async retrieveSR() {
    return await this.state.sortedRecords;
  }

  getRecordList = async (sortedRecords) => {
    // await sleep(500)
    var table = document.getElementById("recordTable");
    let sr = await this.retrieveSR();

    for (let i = -1; i < sortedRecords.length; i++) {
      if (i == -1) {
        var row = table.insertRow();
        var cell1 = row.insertCell();
        var cell2 = row.insertCell();
        var cell3 = row.insertCell();
        var cell4 = row.insertCell();
        cell1.innerHTML = "Rank";
        cell2.innerHTML = "Team";
        cell3.innerHTML = "✓";
        cell3.style.color = "green";
        cell3.style.fontWeight = "bold";
        cell4.innerHTML = "X";
        cell4.style.color = "red";
        cell4.style.fontWeight = "bolder";
      } else {
        var row = table.insertRow();
        let tmp = [];
        tmp.push(
          i + 1,
          this.props.nameIdArr[sortedRecords[i][1]][1],
          sortedRecords[i][0][0],
          sortedRecords[i][0][1]
        );
        for (let j = 0; j < 4; j++) {
          var cell1 = row.insertCell();
          cell1.innerHTML = tmp[j];
          if (sortedRecords[i][1] == this.props.myIndex) {
            cell1.style.fontWeight = "bolder";
          }
        }
      }
    }
  };

  getPicksList = async (sortedFA) => {
    // await sleep(500)
    var table = document.getElementById("picksFATable");
    let sr = await this.retrieveSR();

    for (let i = -1; i < sortedFA.length; i++) {
      if (i == -1) {
        var row = table.insertRow();
        var cell1 = row.insertCell();
        var cell2 = row.insertCell();
        var cell3 = row.insertCell();
        var cell4 = row.insertCell();
        cell1.innerHTML = "Rank";
        cell2.innerHTML = "Team";
        cell3.innerHTML = "For";
        cell4.innerHTML = "Against";
      } else {
        var row = table.insertRow();
        let tmp = [];
        tmp.push(i + 1, sortedFA[i][1], sortedFA[i][0][0], sortedFA[i][0][1]);

        for (let j = 0; j < 4; j++) {
          var cell1 = row.insertCell();
          cell1.innerHTML = tmp[j];

          if (sortedFA[i][1] == this.props.nameIdArr[this.props.myIndex][1]) {
            cell1.style.fontWeight = "bolder";
          }
        }
      }
    }
  };

  getTBCard() {
    if (this.state.key1 === "best" && this.state.sortedTBs) {
      if (this.state.sortedTBs.length >= 3) {
        let mine = [];

        for (let i = 0; i < 3; i++) {
          if (
            this.state.sortedTBs[i][2] ===
            this.props.nameIdArr[this.props.myIndex][0]
          ) {
            mine.push(i + 1);
          }
        }

        return (
          <Row>
            <Col>
              <Card.Body>
                <Card.Title>Best Tiebreaker Guesses</Card.Title>
                <Card.Text>
                  <Table id="mPicksTable2">
                    <thead>
                      <tr>
                        <td>Rank</td>
                        <td>Team</td>
                        <td>Difference</td>
                        <td>Week</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          fontWeight: mine.includes(1) ? "bolder" : "normal",
                        }}
                      >
                        <td>1</td>
                        <td>
                          {
                            this.props.nameIdArr.filter(
                              (a) => a[0] == this.state.sortedTBs[0][2]
                            )[0][1]
                          }
                        </td>
                        <td>{this.state.sortedTBs[0][0]}</td>
                        <td>{this.state.sortedTBs[0][1]}</td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: mine.includes(2) ? "bolder" : "normal",
                        }}
                      >
                        <td>2</td>
                        <td>
                          {
                            this.props.nameIdArr.filter(
                              (a) => a[0] == this.state.sortedTBs[1][2]
                            )[0][1]
                          }
                        </td>
                        <td>{this.state.sortedTBs[1][0]}</td>
                        <td>{this.state.sortedTBs[1][1]}</td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: mine.includes(3) ? "bolder" : "normal",
                        }}
                      >
                        <td>3</td>
                        <td>
                          {
                            this.props.nameIdArr.filter(
                              (a) => a[0] == this.state.sortedTBs[2][2]
                            )[0][1]
                          }
                        </td>
                        <td>{this.state.sortedTBs[2][0]}</td>
                        <td>{this.state.sortedTBs[2][1]}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Text>
              </Card.Body>
            </Col>
          </Row>
        );
      }
    } else if (this.state.key1 === "worst" && this.state.sortedTBs) {
      if (this.state.sortedTBs.length >= 3) {
        let mine = [];

        for (let i = 0; i < 3; i++) {
          if (
            this.state.sortedTBs[this.state.sortedTBs.length - (i + 1)][2] ===
            this.props.nameIdArr[this.props.myIndex][0]
          ) {
            mine.push(i + 1);
          }
        }

        return (
          <Row>
            <Col>
              <Card.Body>
                <Card.Title>Worst Tiebreaker Guesses</Card.Title>
                <Card.Text>
                  <Table id="mPicksTable2">
                    <thead>
                      <tr>
                        <td>Rank</td>
                        <td>Team</td>
                        <td>Difference</td>
                        <td>Week</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          fontWeight: mine.includes(1) ? "bolder" : "normal",
                        }}
                      >
                        <td>1</td>
                        <td>
                          {
                            this.props.nameIdArr.filter(
                              (a) =>
                                a[0] ==
                                this.state.sortedTBs[
                                  this.state.sortedTBs.length - 1
                                ][2]
                            )[0][1]
                          }
                        </td>
                        <td>
                          {
                            this.state.sortedTBs[
                              this.state.sortedTBs.length - 1
                            ][0]
                          }
                        </td>
                        <td>
                          {
                            this.state.sortedTBs[
                              this.state.sortedTBs.length - 1
                            ][1]
                          }
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: mine.includes(2) ? "bolder" : "normal",
                        }}
                      >
                        <td>2</td>
                        <td>
                          {
                            this.props.nameIdArr.filter(
                              (a) =>
                                a[0] ==
                                this.state.sortedTBs[
                                  this.state.sortedTBs.length - 2
                                ][2]
                            )[0][1]
                          }
                        </td>
                        <td>
                          {
                            this.state.sortedTBs[
                              this.state.sortedTBs.length - 2
                            ][0]
                          }
                        </td>
                        <td>
                          {
                            this.state.sortedTBs[
                              this.state.sortedTBs.length - 2
                            ][1]
                          }
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: mine.includes(3) ? "bolder" : "normal",
                        }}
                      >
                        <td>3</td>
                        <td>
                          {
                            this.props.nameIdArr.filter(
                              (a) =>
                                a[0] ==
                                this.state.sortedTBs[
                                  this.state.sortedTBs.length - 3
                                ][2]
                            )[0][1]
                          }
                        </td>
                        <td>
                          {
                            this.state.sortedTBs[
                              this.state.sortedTBs.length - 3
                            ][0]
                          }
                        </td>
                        <td>
                          {
                            this.state.sortedTBs[
                              this.state.sortedTBs.length - 3
                            ][1]
                          }
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Text>
              </Card.Body>
            </Col>
          </Row>
        );
      }
    }
  }

  getEVRCard() {
    if (this.state.key2 === "best" && this.state.sortedEVR) {
      if (this.state.sortedEVR.length >= 3) {
        let size = this.state.sortedEVR.length;

        let mine = [];

        for (let i = 0; i < 3; i++) {
          if (
            this.state.sortedEVR[this.state.sortedEVR.length - (i + 1)][3] ===
            this.props.nameIdArr[this.props.myIndex][0]
          ) {
            mine.push(i + 1);
          }
        }

        return (
          <Row>
            <Col>
              <Card.Body>
                <Card.Title>Most Overrated</Card.Title>
                <Card.Text>
                  <Table id="EVR1">
                    <thead>
                      <tr>
                        <td>Rank</td>
                        <td>Team</td>
                        <td>Record %</td>
                        <td>Picked %</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          fontWeight: mine.includes(1) ? "bolder" : "normal",
                        }}
                      >
                        <td>1</td>
                        <td>{this.state.sortedEVR[size - 1][4]}</td>
                        <td>{this.state.sortedEVR[size - 1][0]}%</td>
                        <td>{this.state.sortedEVR[size - 1][1]}%</td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: mine.includes(2) ? "bolder" : "normal",
                        }}
                      >
                        <td>2</td>
                        <td>{this.state.sortedEVR[size - 2][4]}</td>
                        <td>{this.state.sortedEVR[size - 2][0]}%</td>
                        <td>{this.state.sortedEVR[size - 2][1]}%</td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: mine.includes(3) ? "bolder" : "normal",
                        }}
                      >
                        <td>3</td>
                        <td>{this.state.sortedEVR[size - 3][4]}</td>
                        <td>{this.state.sortedEVR[size - 3][0]}%</td>
                        <td>{this.state.sortedEVR[size - 3][1]}%</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Text>
              </Card.Body>
            </Col>
          </Row>
        );
      }
    } else if (this.state.key2 === "worst" && this.state.sortedEVR) {
      if (this.state.sortedEVR.length >= 3) {
        let size = this.state.sortedEVR.length;
        let mine = [];

        for (let i = 0; i < 3; i++) {
          if (
            this.state.sortedEVR[i][3] ===
            this.props.nameIdArr[this.props.myIndex][0]
          ) {
            mine.push(i + 1);
          }
        }

        return (
          <Row>
            <Col>
              <Card.Body>
                <Card.Title>Underrated</Card.Title>
                <Card.Text>
                  <Table id="EVR2">
                    <thead>
                      <tr>
                        <td>Rank</td>
                        <td>Team</td>
                        <td>Record %</td>
                        <td>Picked %</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          fontWeight: mine.includes(1) ? "bolder" : "normal",
                        }}
                      >
                        <td>1</td>
                        <td>{this.state.sortedEVR[0][4]}</td>
                        <td>{this.state.sortedEVR[0][0]}%</td>
                        <td>{this.state.sortedEVR[0][1]}%</td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: mine.includes(2) ? "bolder" : "normal",
                        }}
                      >
                        <td>2</td>
                        <td>{this.state.sortedEVR[1][4]}</td>
                        <td>{this.state.sortedEVR[1][0]}%</td>
                        <td>{this.state.sortedEVR[1][1]}%</td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: mine.includes(3) ? "bolder" : "normal",
                        }}
                      >
                        <td>3</td>
                        <td>{this.state.sortedEVR[2][4]}</td>
                        <td>{this.state.sortedEVR[2][0]}%</td>
                        <td>{this.state.sortedEVR[2][1]}%</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Text>
              </Card.Body>
            </Col>
          </Row>
        );
      }
    }
  }

  rankingsPlot() {
    if (this.state.rankGraph.length != 0 && window.innerWidth > 800) {
      return (
        <Card id="card">
          <Card.Header>
            {" "}
            <Card.Title>Power Rankings Overview</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <Line
                options={this.state.rankGraphOptions}
                data={this.state.rankGraph}
                responsive
              />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    } else {
    }
  }

  render() {
    return (
      <div>
        <Container id="yContainer">
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <Col id="tabTitle">Yearly Stats</Col>
          </Row>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <Col>
              <Card id="card">
                <Card.Header>
                  <Card.Title>Top Money Makers</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Table id="cashTable2"></Table>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col style={{ paddingRight: "2px" }}>
              <Card id="card">
                <Card.Header>
                  <Card.Title>Records</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Table id="recordTable"></Table>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card id="card">
                <Card.Header>
                  <Card.Title>Picks For & Against</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Table id="picksFATable"></Table>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card id="card">
                <Card.Header>
                  <Tabs
                    id="controlled-tab-example"
                    defaultActiveKey="home"
                    activeKey={this.state.key2}
                    onSelect={(k) => {
                      this.setState({ key2: k });
                    }}
                  >
                    <Tab eventKey="best" title="Overrated"></Tab>
                    <Tab eventKey="worst" title="Underrated"></Tab>
                  </Tabs>
                </Card.Header>
                {this.getEVRCard()}
              </Card>
            </Col>
            <Col>
              <Card id="card">
                <Card.Header>
                  <Tabs
                    id="controlled-tab-example"
                    defaultActiveKey="home"
                    activeKey={this.state.key1}
                    onSelect={(k) => {
                      this.setState({ key1: k });
                    }}
                  >
                    <Tab eventKey="best" title="Best"></Tab>
                    <Tab eventKey="worst" title="Worst"></Tab>
                  </Tabs>
                </Card.Header>
                {this.getTBCard()}
              </Card>
            </Col>
          </Row>
          {/* line graph that shows how rankings have changed over time */}
          <Row>{this.rankingsPlot()}
          </Row>
        </Container>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default StatsYear;
