import React, { Fragment } from "react";
import Matchup from "./Matchup";
import { getDocs, addDoc, query, where } from "firebase/firestore";
import {
  usersReference,
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  sleep,
  leagueSize,
  passedDue,
  rankingsReference,
  currYear,
} from "./App";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { gsap } from "gsap";

const Body = React.forwardRef((props, ref) => (
  <button ref={ref} className="FancyButton">
    {props.children}
  </button>
));

class PickemContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ids1: [],
      names1: [],
      users: [],
      users1: [],
      allMatchups: [],
      matchupNames: [],
      userPicks: [],
      userPicksIds: [],
      submittable: false,
      week: 0,
      valid: true,
      rs: [],
      matchupRosters: [],
      allP: [],
      matchupPlayers: [],
      rVal: -1,
      picksMade: false,
      loadedPicks: [],
      loadedTB: "____",
      progressCount: 0,
      progSet: false,
      validTime: true,
      loadCount: 0,
      animate: false,
      subMessage: "NOT SUBMITTED",
      currLock: "",
      showModal: false,
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.r8 = React.createRef();
    this.r9 = React.createRef();
    this.r10 = React.createRef();
  }

  async generateIds() {
    let url = "https://api.sleeper.app/v1/league/" + leagueId + "/rosters";

    let teamInfo = [];
    let ids = [];

    fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((res) => res.json())
      .then((res) => {
        teamInfo = res; //player info
      })
      .then(() => {
        ids = teamInfo.map((team) => {
          return team.owner_id;
        });
      })
      .then(() => {
        this.setState({ ids1: ids });
        this.generateNames(ids);
      });
  }

  generateNames = async (ids) => {
    let url = "https://api.sleeper.app/v1/league/" + leagueId + "/users";
    let teamInfo = [];
    let nms = [];

    await fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((res) => res.json())
      .then((res) => {
        teamInfo = res;
      })
      .then(() => {
        //iterate through users

        nms = ids.map((id) => {
          for (let i = 0; i < teamInfo.length; i++) {
            if (teamInfo[i].user_id == id) {
              if (typeof teamInfo[i].metadata.team_name === "undefined") {
                return [teamInfo[i].display_name, id];
              } else {
                return [teamInfo[i].metadata.team_name, id];
              }
            }
          }
        });
      })
      .then(() => {
        this.setState({ names1: nms });
        this.assignTeams(nms);
      });
  };

  async assignTeams(arr) {
    let mNames = [];
    let mRosters = [];
    let mAll = [];

    for (let i = 0; i < this.state.allMatchups.length; i++) {
      let currNames = [];
      let currRosters = [];
      let currAll = [];

      currNames.push(arr[this.state.allMatchups[i][0] - 1]);
      currRosters.push(this.state.rs[this.state.allMatchups[i][0] - 1]);
      currAll.push(this.state.allP[this.state.allMatchups[i][0] - 1]);

      currNames.push(arr[this.state.allMatchups[i][1] - 1]);
      currRosters.push(this.state.rs[this.state.allMatchups[i][1] - 1]);
      currAll.push(this.state.allP[this.state.allMatchups[i][1] - 1]);

      mNames.push(currNames);
      mRosters.push(currRosters);
      mAll.push(currAll);
    }
    this.setState({
      matchupNames: mNames,
      matchupRosters: mRosters,
      matchupPlayers: mAll,
    });
  }

  callback = (data) => {
    // this.setProg()
    let tmp = this.state.userPicks;

    let portion = 100 / (this.state.allMatchups.length + 2);
    let lockPortion = 0;
    let selectPortion = 0;
    // data[3]
    // console.log('data[3] :', data[3]);

    switch (data[3]) {
      case "Lock": {
        // console.log("ONE")
        if (this.state.currLock === "") {
          lockPortion = portion;
        }
        break;
      }
      case "RemoveLock": {
        // console.log("TWO")
        lockPortion = -portion;
        break;
      }
      case "NoLockData": {
        // console.log("THREE")
        break;
      }
    }

    // console.log('lockPortion :', lockPortion);

    if (data[1] == -1) {
      selectPortion = -portion;
      // this.setState({ progressCount: this.state.progressCount - portion + lockPortion});
    } else {
      if (tmp[data[0]] == -1) {
        selectPortion = portion;
        // this.setState({ progressCount: this.state.progressCount + portion + lockPortion });
      }
    }

    this.setState({
      progressCount: this.state.progressCount + selectPortion + lockPortion,
    });

    if (data[1] == -1) {
      tmp[data[0]] = -1;
    } else {
      tmp[data[0]] = [data[1], data[2]];
    }
    this.setState({ userPicks: tmp });

    if (!tmp.includes(-1)) {
      this.setState({ picksMade: true });
      if (this.state.rVal != -1) {
        this.setState({ submittable: true });
      }
    } else {
      this.setState({ picksMade: false, submittable: false });
    }
    // this.setProg()
  };

  async isValid() {
    // ----- check if it's past thurs at 715

    let validTime = true;
    var date = new Date();
    let offset = (date.getTimezoneOffset() / 60) * 100; //500
    let GMTTime = date.getHours() * 100 + offset + date.getMinutes(); //2000 + 500  = 2230

    let invalidDays = [0, 1, 5, 6];

    if (
      invalidDays.includes(date.getDay()) ||
      (date.getDay() == 4 && GMTTime > 2415)
    ) {
      // validTime = false;
    }

    // -------------

    let q = query(
      picksReference,
      where("Week", "==", this.state.week),
      where("Tid", "==", await getId(usersReference)),
      where("Year", "==", currYear)
    );

    const data = await getDocs(q);

    if (data.empty) {
      await this.setState({
        valid: data.empty && validTime,
        validTime: validTime,
      });
    } else {
      let tmp = [];
      for (
        let i = 0;
        i <
        data.docs[0]._document.data.value.mapValue.fields.Picks.arrayValue
          .values.length;
        i++
      ) {
        tmp.push(
          data.docs[0]._document.data.value.mapValue.fields.Ids.arrayValue
            .values[i].stringValue
        );
      }

      await this.setState({
        valid: data.empty,
        loadedPicks: tmp,
        rVal: data.docs[0]._document.data.value.mapValue.fields.TieBreaker
          .stringValue,
        progressCount: 100,
        validTime: validTime,
        subMessage: "SUBMITTED",
      });
    }

    return data.empty;
  }

  setPicks() {
    let userPicks = [];
    for (let i = 0; i < leagueSize / 2; i++) {
      userPicks.push(-1);
    }
    this.setState({ userPicks: userPicks });
  }

  componentDidMount() {
    let week = getWeek();
    this.setState({ week: week });
    let url =
      "https://api.sleeper.app/v1/league/" + leagueId + "/matchups/" + week; //other league for test

    let matchupInfo = [];
    let all = [];
    let rosters = [];
    let allPlayers = [];

    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.testAnimate(d);
      this.setPicks();
    }

    //if matchup names have not been set yet
    if (this.state.matchupNames.length == 0) {
      fetch(url, {
        method: "GET",
        headers: {},
      })
        .then((res) => res.json())
        .then((res) => {
          matchupInfo = res; //array of each team in the league with info about their matchup, like matchup id and players
          console.log('matchupInfo :', matchupInfo);
        })
        .then(() => {
          //iterate through tmp and find the matchups
          for (let i = 0; i < matchupInfo.length / 2; i++) {
            let matchup = [];
            for (let j = 0; j < matchupInfo.length; j++) {
              if (matchupInfo[j].matchup_id == i + 1) {
                matchup.push(matchupInfo[j].roster_id);
              }

              //for first loop fill up roster arrays
              if (i == 0) {
                rosters.push(matchupInfo[j].starters);
                allPlayers.push(matchupInfo[j].players);
              }
            }
            all.push(matchup);
          }
        })
        .then(() => {
          this.setState({ allMatchups: all, rs: rosters, allP: allPlayers });
        })
        .then(() => this.generateIds())
        .then(() => this.isValid());
    }
  }

  showMatchups() {
    let r = [];

    for (let i = 0; i < this.state.matchupNames.length; i++) {
      r.push(
        <Matchup
          num={i}
          matchups={this.state.matchupNames[i]}
          rosters={this.state.matchupRosters[i]}
          allPlayers={this.state.matchupPlayers[i]}
          callback={this.callback}
          lockCallback={this.lockCallback}
          currLock={this.state.currLock}
          d={this.state.valid}
          lp={this.state.loadedPicks}
          portion={100 / (this.state.allMatchups.length + 2)}
        />
      );
    }

    return r;
  }

  async submit() {
    if (localStorage.getItem("email") == null) {
      return;
    } else if (localStorage.getItem("name") === "guest") {
      this.setState({
        valid: false,
        loadedPicks: [-1],
        subMessage: "SUBMITTED",
      });
      return;
    }

    let picks = this.state.userPicks.map((p) => p[0]);
    let ids = this.state.userPicks.map((p) => p[1]);
    this.setState({ valid: false, loadedPicks: [-1], subMessage: "SUBMITTED" });
    let tid = await getId(usersReference);

    await addDoc(picksReference, {
      Picks: picks,
      Ids: ids,
      TieBreaker: this.state.rVal,
      Tid: (tid = await getId(usersReference)),
      Week: this.state.week,
      Lock: this.state.currLock,
      Year: currYear
    });

    let q = query(
      rankingsReference,
      where("Week", "==", this.state.week),
      where("Tid", "==", tid), 
      where("Year", "==", currYear),
    );

    const data = await getDocs(q);

    if (data.empty) {
      window.location.href = "/PowerRanking";
    } else {
      window.location.href = "/stats";
    }
  }

  setRVal() {
    //if progress bar hasnt yet been adjust from changing slider
    if (!this.state.progSet) {
      let portion = 100 / (this.state.allMatchups.length + 2);
      this.setState({
        progSet: true,
        progressCount: this.state.progressCount + portion,
      });
      // this.setProg()
      //if all the picks have been made
    }
    if (this.state.picksMade) {
      this.setState({ rVal: document.getElementById("range").value });
      if (this.state.rVal != -1) {
        //if the range slider has been adjusted
        this.setState({ submittable: true });
      }
    } else {
      this.setState({
        picksMade: false,
        rVal: document.getElementById("range").value,
      });
    }
  }

  checkRVal() {
    //if slider has been changed
    if (this.state.rVal != -1) {
      if (this.state.loadedPicks.length != 0) {
        //if the picks have already been submitted
        return <div style={{ color: "grey" }}>{this.state.rVal}</div>;
      } else {
        return this.state.rVal;
      }
    } else {
      return "____";
    }
  }

  submissionsClosed() {
    if (!this.state.validTime) {
      return <div>Submissions Closed</div>;
    }
  }

  async setProg() {
    // console.log('setProg() :');
    await sleep(250);
    let prog = 0;
    let portion = 100 / (this.state.allMatchups.length + 2);
    let userPicks = [...this.state.userPicks];

    let lockPortion = 0;
    let tbPortion = 0;
    let picksPortion = userPicks.filter((p) => p != -1).length * portion;
    // console.log(
    //   "userPicks.filter(p => p != -1).length :",
    //   userPicks.filter((p) => p != -1).length
    // );

    this.state.currLock !== "" ? (lockPortion = portion) : (lockPortion = 0);
    this.state.progSet ? (tbPortion = portion) : (tbPortion = 0);

    this.setState({ progressCount: lockPortion + tbPortion + picksPortion });

    // console.log(
    //   "lockPortion + tbPortion + picksPortion :",
    //   lockPortion + tbPortion + picksPortion
    // );

    // console.log("picksPortion :", picksPortion);
    // console.log("tbPortion :", tbPortion);
    // console.log("lockPortion :", lockPortion);
  }

  lockCallback = async (Tid) => {
    // if (Tid === "") {

    // }
    // if (Tid !== "" && Tid !== this.state.currLock) {
    //   this.setState({
    //     currLock: Tid,
    //   });
    // }
    this.setState({
      currLock: Tid,
    });
    // this.setProg()
  };

  showPage1() {
    if (this.state.matchupNames.length > 0 && this.state.animate) {
      return (
        <div>
          <Container>
            <Row>
              <Col
                style={{
                  marginTop: "10px",
                  fontSize: "30px",
                  fontWeight: "bolder",
                  color: this.state.validTime ? "black" : "#FF7367",
                }}
              >
                PICK 'EM!
              </Col>
            </Row>
            <Row>
              <Col style={{ fontSize: "16px" }}>
                WEEK {this.state.week}{" "}
                <span style={{ color: !this.state.valid ? "green" : "red" }}>
                  ({this.state.subMessage})
                </span>
              </Col>
            </Row>
            <Row>
              <Col style={{ color: "#FF7367", fontSize: "12px" }}>
                {this.submissionsClosed()}
              </Col>
            </Row>
            <Row>
              <Col id="horDiv"></Col>
            </Row>
            <Modal
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false })}
            >
              <Modal.Header closeButton className="px-4">
                <Modal.Title className="ms-auto">Locks</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "left" }}>
                I've added a something back that I forgot we had last year,{" "}
                <b>locks</b>. You can now lock a team by double-tapping their
                name. A lock counts for two points instead of one (and -2
                instead of -1 if you are incorrect).
              </Modal.Body>
            </Modal>
          </Container>
        </div>
      );
    }
  }

  showPage2() {
    if (this.state.matchupNames.length > 0 && this.state.animate) {
      return (
        <div>
          <Container>
            <Row>
              <Col>{this.showMatchups()}</Col>
            </Row>
            <Row>
              <Col id="horDiv" style={{ marginTop: "15px" }}></Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return (
        <Container>
          <Row style={{ marginTop: "25%" }}>
            <Col>Loading Pick 'Em</Col>
          </Row>
          <Row>
            <Col>
              <Spinner animation="border"></Spinner>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  showPage3() {
    if (this.state.matchupNames.length > 0 && this.state.animate) {
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <div
                  id="tbHeader"
                  style={{ color: this.state.valid ? "black" : "grey" }}
                >
                  Tie Breaker
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  id="tbBody"
                  style={{ color: this.state.valid ? "black" : "grey" }}
                >
                  {tbMessage[this.state.week]}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Range
                  id="range"
                  min={0}
                  max={200}
                  step={0.1}
                  disabled={!this.state.valid}
                  onChange={() => this.setRVal()}
                  ref={this.r5}
                  onMouseEnter={this.enter}
                  onMouseLeave={this.leave}
                />
              </Col>
            </Row>

            <Row>
              <Col style={{ color: this.state.valid ? "black" : "grey" }}>
                {this.checkRVal()}
              </Col>
            </Row>
            <Row>
              <Col id="horDiv"></Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  showLockReminder() {
    if (this.state.animate && this.state.currLock === "" && !this.state.userPicks.includes(-1)) {
      return "Don't forget to lock a team (double tap)";
    }
  }

  enter = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.2, fontWeight: "bolder" });
  };

  leave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  showPage4() {
    if (this.state.matchupNames.length > 0 && this.state.animate) {
      return (
        <div>
          <Container>
            <Button
              id="no-but1"
              disabled={
                !this.state.valid ||
                !this.state.submittable ||
                this.state.currLock === "" ||
                passedDue
              } //if not all are selected and it is first submission
              onClick={() => this.submit()}
              style={{
                
                border: "2px solid #03256C",
                width: "50%",
              }}
              ref={this.r6}
              onMouseEnter={this.enter}
              onMouseLeave={this.leave}
            >
              SUBMIT
              <ProgressBar
                now={this.state.progressCount}
                style={{
                  borderRadius: "0px",
                  backgroundColor: "grey",
                  color: "#06BEE1",
                }}
              />
            </Button>
            <Row>
              <Col style={{fontSize: "16px", marginBottom: "50px", marginTop: "10px", fontWeight: "bolder"}}>{this.showLockReminder()}</Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  testAnimate = async (a) => {
    await sleep(100);

    if (this.state.loadCount == a) {
      gsap.from(this.r1.current, { duration: 3, scale: 0.5, ease: "bounce" });
      gsap.from(this.r2.current, { duration: 3, ease: "power4", scale: 0.5 });
      gsap.from(this.r3.current, { duration: 5, ease: "power4", scale: 0.5 });
      gsap.from(this.r4.current, {
        y: "100%",
        duration: 3,
        ease: "bounce",
        scale: 0.5,
      });
      await sleep(1000);
      this.setState({ animate: true });
      setTimeout(() => {
        this.setState({
          showModal: false,
        });
      }, 500);
    }
  };

  render() {
    return (
      <Container>
        <div ref={this.r1}>{this.showPage1()}</div>
        <div ref={this.r2}>{this.showPage2()}</div>
        <div ref={this.r3}>{this.showPage3()}</div>
        <div ref={this.r4}>{this.showPage4()}</div>
      </Container>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default PickemContainer;
