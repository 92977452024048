import { useState, useEffect, useRef } from "react";
import "./App.css";
import { db } from "./Firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import { signInWithGoogle } from "./Firebase";
import TeamSelection from "./pages/TeamSelection";
import Pickem from "./pages/Pickem";
import PowerRanking from "./pages/PowerRanking";
import KeeperSelection from "./pages/KeeperSelection";
import Stats from "./pages/Stats";
import Home, { Banner } from "./pages/Home";
import { gsap } from "gsap";
export const passedDue = false;
export const usersReference = collection(db, "Users");
// export const usersReference = collection(db, "UsersTest");
export const picksReference = collection(db, "Picks");
// export const picksReference = collection(db, "PicksTest");
export const rankingsReference = collection(db, "Rankings");
// export const rankingsReference = collection(db, "RankingsTest");
export const keepersReference = collection(db, "Keepers");
export const messagesReference = collection(db, "Messages");
export const winnersReference = collection(db, "Winners");
export const generalReference = collection(db, "General");
// export const leagueId = 730152815690936320n; //need the n there for it to work 862616162117238784
// export const leagueId = 862616162117238784n; //my league 2022 
export const leagueId = 1124850814658048000n; //my league 2023
// export const leagueId = 865391764033384448n; //bot league
export const tbMessage = [
  "NONE",
  "How many points will the highest scoring QB put up?",
  "How many points will the highest scoring RB put up?",
  "How many points will the highest scoring WR put up?",
  "How many points will the highest scoring TE put up?",
  "How many points will the highest scoring D/ST put up?",
  "How many points will the highest scoring K put up?",
  "How many points will the highest scoring bench player put up?",
  "How many points will the highest scoring fantasy team put up?",
  "How many points will the lowest scoring fantasy team put up?",
  "How many yards will be the longest touchdown of the week",
  "What will be the difference in points scored in the biggest fantasy blowout this week?",
  "What will be the difference in points scored in the closest fantasy matchup this week?",
  "How many points will the highest scoring NFL team put up?",
  "What will be the difference in points scored in the biggest NFL blowout this week?",
  "What will be the difference in points scored in the closest NFL matchup this week?",
  "What is the most amount of receptions an RB will have this week?",
  "What is the most amount of receptions a WR will have this week?"
]; //my league
export const leagueSize = 10; //my league
// export const leagueSize = 20
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const currYear = 2024; //current year

export const updateUser = async (id, tid) => {
  const userDoc = doc(db, "Users", id);
  // const userDoc = doc(db, "UsersTest", id);
  const newFields = { id: tid };
  await updateDoc(userDoc, newFields);
};

export const getId = async (ref) => {
  let q = query(ref, where("Email", "==", localStorage.getItem("email")));
  const data = await getDocs(q);

  if (!data.empty) {
    return data.docs[0]._document.data.value.mapValue.fields.id.stringValue;
  } else {
    return -1;
  }
};

export const getWeek = () => {
  //week finder
  // let w1 = 1662518523776;
  let w1 = 1725357600000;
  let weekTime = 604800000;

  //week starts
  //1 - 1662518523776
  //2 - 1663123323776
  //3 - 1663728123776

  //distance between now and start of w1 = length of week * x
  let weeksPassed = (Date.now() - w1) / weekTime;
  // console.log('Date.now(): ' + Date.now())

  // Define the target date and current date
var targetDate = new Date("2023-09-05T06:00:00");
var currentDate = new Date();

// Calculate the difference in milliseconds
var timeDifference = targetDate - currentDate;

// console.log(timeDifference); // This will output the time difference in milliseconds
// return 10;
  if (weeksPassed < 1) {
    return 1;
  } else {
    return Math.floor(weeksPassed + 1);
  }
  // return 3
};

function App() {
  const [userList, setUserList] = useState([]);
  const [currUser, setCurrUser] = useState("-1");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const getUsers = async () => {
      const userData = await getDocs(usersReference);
      setUserList(userData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getUsers();

    setCurrUser(localStorage.getItem("userId"));
    setUserName(localStorage.getItem("name"));
  }, []);

  function signOut() {
    localStorage.clear();
    window.location.href = "/";
  }

  function logOutButton() {
    if (localStorage.getItem("email")) {
      return (
        <button onClick={signOut} id="log-out">
          Log Out
        </button>
      );
    }
  }

  async function signIn() {
    await signInWithGoogle();

    if (localStorage.getItem("email")) {
      //look for email in the userlist
      var result = userList.find((obj) => {
        return obj.Email === localStorage.getItem("email");
      });

      let q = query(
        usersReference,
        where("Email", "==", localStorage.getItem("email"))
      );

      const data = await getDocs(q);
      



      //found user already in db that has that email
      if (!data.empty) {
        let idFound = false;
        for (let i = 0; i < data.docs.length; i++) {
          console.log("DATRAL:")
          console.log(data.docs[i]._document.data.value.mapValue.fields.id.stringValue)
          if (data.docs[i]._document.data.value.mapValue.fields.id.stringValue != ""){
            setCurrUser(data.docs[i]._document.data.value.mapValue.fields.id.stringValue);
            localStorage.setItem("userId", data.docs[i]._document.data.value.mapValue.fields.id.stringValue);
            idFound = true;
          }
        }

        if (!idFound) {
          setCurrUser("");
          localStorage.setItem("userId", "");
        }
        
        
        window.location.href = window.location.href;
      } else {
        //email not found in db, new user
        //add user to Users db and set current user
        let newId = "";

        await addDoc(usersReference, {
          id: newId,
          Name: localStorage.getItem("name"),
          Email: localStorage.getItem("email"),
        }).then((docRef) => {
          newId = docRef.id;
        });

        setCurrUser(newId);
        setUserName(localStorage.getItem("name"));
        localStorage.setItem("userId", newId);
        
        window.location.href = window.location.href;
      }
    }
  }

  function left() {
    if (localStorage.getItem("email")) {
      return (
        <Link to="/" id="name-head">
          <p style={{ marginBottom: "0px" }}>&lt;</p>
        </Link>
      );
    }
  }

  function middle() {
    if (localStorage.getItem("email")) {
      return (
        <Link to="/" id="name-head">
          Home
        </Link>
      );
    } else {
      return (
        <button id="log-in" onClick={signIn}>
          Sign in
        </button>
      );
    }
  }
  const enter = ({ currentTarget }) => {
    gsap.to(currentTarget, { color: "red", scale: 1.2, fontWeight: "bolder" });
  };

  const enterBack = ({ currentTarget }) => {
    gsap.to(currentTarget, { color: "red", fontWeight: "bolder" });
  };

  const leave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  const l = useRef();
  const mid = useRef();
  const right = useRef();

  return (
    <Router>
      <div className="App">
        <Container fluid>
          {/* ◄ */}
          <Row id="header">
            <Col ref={l} onMouseEnter={enter} onMouseLeave={leave}>
              {left()}
            </Col>
            <Col ref={mid} onMouseEnter={enter} onMouseLeave={leave}>
              {middle()}
            </Col>
            <Col ref={right} onMouseEnter={enter} onMouseLeave={leave}>
              {logOutButton()}
            </Col>
          </Row>
        </Container>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pickem" element={<Pickem />} />
          <Route path="/powerranking" element={<PowerRanking />} />
          <Route path="/keeperselection" element={<KeeperSelection />} />
          <Route path="/teamselection" element={<TeamSelection />} />
          <Route path="/stats" element={<Stats />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
