import Team from "../Team";
import TeamContainer from "../TeamContainer";

const TeamSelection = () => {
  return (
    <div>
      <TeamContainer/>
      
    </div>
  );
};

export default TeamSelection;
