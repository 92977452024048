import React from "react";
import Team from "./Team";
import { analyzePRWeek } from "./StatsWeek";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { Line } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import {
  usersReference,
  updateUser,
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  keepersReference,
  sleep,
} from "./App";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { keepArr } from "./KeeperContainer";

import { winnersArr } from "./pages/Stats";
import { gsap } from "gsap";

Chart.register(Filler);


class StatsPersonal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadCount: 0,
      userW: 0,
      userL: 0,
      myRank: -1, // x / numLeagueSize
      myPicksOrganized: [],
      cash: this.props.cash,
      picksForMe: -1,
      picksAgainstMe: -1,
      whoLovesMe: [],
      whoHatesMe: [],
      key1: "most",
      key2: "most",
      myMostPicked: [],
      myLeastPicked: [],
      animated: false,
      rankGraph: [],
      rankGraphOptions: [],
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
  }

  componentDidMount() {
    if (this.state.loadCount == 0) {
      this.calcData();
      // this.getTable1();
      // this.getTable2();
      this.setState({ loadCount: this.state.loadCount++ });
    }
    if (this.state.rankGraph.length == 0) {
      this.createRankPlot();
    }
  }

  async calcData() {
    let id = await getId(usersReference);

    // gsap.to(this.r1.current, { rotation: "+=360" });
    gsap.from(this.r1.current, {
      x: "-100%",
      duration: 2,
      ease: "power4",
      scale: 0.5,
    });
    gsap.from(this.r2.current, {
      x: "100%",
      duration: 2,
      ease: "power4",
      scale: 0.5,
    });
    gsap.from(this.r3.current, {
      y: "1000%",
      duration: 2,
      ease: "power4",
      scale: 0.1,
    });
    gsap.from(this.r4.current, {
      y: "1000%",
      duration: 2,
      ease: "power4",
      scale: 0.1,
    });
    gsap.from(this.r5.current, {
      y: "100%",
      duration: 2,
      ease: "power4",
      scale: 0.1,
    });
    gsap.from(this.r7.current, {
      y: "100%",
      duration: 2,
      ease: "power4",
      scale: 0.1,
    });
    gsap.from(this.r6.current, {
      // y: "100%",
      duration: 2,
      ease: "bounce",
      scale: 0.1,
    });
    this.setState({ animated: true });
    //----- get record rank

    let avgs = this.props.recordsArr
      .map((record, i) => {
        if (record[1] == 0) {
          let tmp = [];
          tmp.push(-1, i);
        }
        let tmp = [];
        tmp.push((record[0] * 1.0) / record[1], i);
        return tmp;
      })
      .filter((record) => record[0] > 0)
      .sort((a, b) => b[0] - a[0]);

    let myScore = avgs
      .filter((a) => a[1] == this.props.myIndex)

      .map((a) => {
        return a[0];
      });
    let myRank = -1;
    let tied = "";

    for (let i = 0; i < avgs.length; i++) {
      if (avgs[i][0] == myScore) {
        myRank = i + 1;
        if (
          avgs[i][1] != this.props.myIndex ||
          (i != avgs.length - 1 && avgs[i + 1][0] == myScore)
        ) {
          tied = "T";
        }
      }
    }

    // -------- get most and least voted for

    let most = [];
    let least = [];

    let high = -1;
    let low = 100;

    let myPicksOrganized = [...this.props.whoIPick];
    myPicksOrganized.splice(myPicksOrganized.length - 1);
    myPicksOrganized = myPicksOrganized
      .map((team, index) => {
        let tmp = [];
        tmp.push(team);
        tmp.push(index);
        return tmp;
      })
      .sort((a, b) => b[0] - a[0]);

    most.push(myPicksOrganized[0], myPicksOrganized[1], myPicksOrganized[2]);
    least.push(
      myPicksOrganized[myPicksOrganized.length - 1],
      myPicksOrganized[myPicksOrganized.length - 2],
      myPicksOrganized[myPicksOrganized.length - 3]
    );

    //-------- picks for and against me

    let forMe = this.props.whoEveryonePicks[this.props.myIndex];
    let againstMe = this.props.pickEmResults.length - forMe;

    //-------- who picks me the msot and least

    let loveMe = [];
    let hateMe = [];

    let h = -1;
    let l = 100;

    let pickPercentage = this.props.whoEachPersonPicks.map((a, index) => {
      if (a[this.props.whoEachPersonPicks.length] == 0) {
        return -1;
      }

      let tmp = [];
      let tmp1 = [];
      tmp.push(
        (a[this.props.myIndex] * 1.0) / a[this.props.whoEachPersonPicks.length]
      );
      tmp.push(index);
      tmp1.push(a[this.props.myIndex] * 1.0);
      tmp1.push(a[this.props.whoEachPersonPicks.length]);
      tmp.push(tmp1);

      return tmp;
    });

    let sortedPickPercentage = pickPercentage
      .sort((a, b) => {
        if (b == -1) {
          return -1;
        }
        if (b[0] == a[0]) {
        }
        return b[0] - a[0];
      })
      .filter((n) => n[0] >= 0);

    let findBest = sortedPickPercentage
      .filter((n) => n[0] == sortedPickPercentage[0][0])
      .sort((a, b) => {
        return (
          this.props.whoEachPersonPicks[b[1]][
            this.props.whoEachPersonPicks.length
          ] -
          this.props.whoEachPersonPicks[a[1]][
            this.props.whoEachPersonPicks.length
          ]
        );
      });

    loveMe = [...sortedPickPercentage];

    for (let i = 0; i < findBest.length; i++) {
      loveMe[i] = [...findBest[i]];
    }

    let findWorst = sortedPickPercentage
      .filter(
        (n) => n[0] == sortedPickPercentage[sortedPickPercentage.length - 1][0]
      )
      .sort((a, b) => {
        return (
          this.props.whoEachPersonPicks[b[1]][
            this.props.whoEachPersonPicks.length
          ] -
          this.props.whoEachPersonPicks[a[1]][
            this.props.whoEachPersonPicks.length
          ]
        );
      });

    hateMe = sortedPickPercentage.sort((a, b) => {
      return a[0] - b[0];
    });

    for (let i = 0; i < findWorst.length; i++) {
      hateMe[i] = [...findWorst[i]];
    }

    if (this.props.whoIPick.filter((a) => a > 0).length == 0) {
      least = [];
      most = [];
    }

    this.setState({
      myRank: tied + myRank,
      myPicksOrganized: myPicksOrganized,
      myMostPicked: most,
      myLeastPicked: least,
      picksForMe: forMe,
      picksAgainstMe: againstMe,
      whoLovesMe: loveMe,
      whoHatesMe: hateMe,
    });
  }

  getC1 = (t1) => {
    if (this.state.key1 === "most" && this.state.myMostPicked.length >= 3) {
      return (
        <Row>
          <Col sm="0" lg="1"></Col>
          <Col>
            <Card.Body>
              <Card.Title>Who You Pick the Most</Card.Title>
              <Card.Text>
                <Row>
                  <Col>
                    <Table id="mPicksTable">
                      <thead>
                        <tr>
                          <td>Rank</td>
                          <td>Team</td>
                          <td>Fraction</td>
                          <td>Percent</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            {
                              this.props.nameIdArr[
                                this.state.myMostPicked[0][1]
                              ][1]
                            }
                          </td>
                          <td>
                            {this.state.myMostPicked[0][0]}/
                            {
                              this.props.whoIPick[
                                this.props.whoIPick.length - 1
                              ]
                            }
                          </td>
                          <td>
                            {Math.round(
                              ((this.state.myMostPicked[0][0] * 1.0) /
                                this.props.whoIPick[
                                  this.props.whoIPick.length - 1
                                ]) *
                                100
                            )}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            {
                              this.props.nameIdArr[
                                this.state.myMostPicked[1][1]
                              ][1]
                            }
                          </td>
                          <td>
                            {this.state.myMostPicked[1][0]}/
                            {
                              this.props.whoIPick[
                                this.props.whoIPick.length - 1
                              ]
                            }
                          </td>
                          <td>
                            {Math.round(
                              ((this.state.myMostPicked[1][0] * 1.0) /
                                this.props.whoIPick[
                                  this.props.whoIPick.length - 1
                                ]) *
                                100
                            )}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            {
                              this.props.nameIdArr[
                                this.state.myMostPicked[2][1]
                              ][1]
                            }
                          </td>
                          <td>
                            {this.state.myMostPicked[2][0]}/
                            {
                              this.props.whoIPick[
                                this.props.whoIPick.length - 1
                              ]
                            }
                          </td>
                          <td>
                            {Math.round(
                              ((this.state.myMostPicked[2][0] * 1.0) /
                                this.props.whoIPick[
                                  this.props.whoIPick.length - 1
                                ]) *
                                100
                            )}
                            %
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Col>
          <Col sm="0" lg="1"></Col>
        </Row>
      );
    } else if (
      this.state.key1 === "least" &&
      this.state.myLeastPicked.length >= 3
    ) {
      return (
        <Row>
          <Col sm="0" lg="1"></Col>
          <Col>
            <Card.Body>
              <Card.Title>Who You Pick the Least</Card.Title>
              <Card.Text>
                <Table id="lPicksTable">
                  <thead>
                    <tr>
                      <td>Rank</td>
                      <td>Team</td>
                      <td>Fraction</td>
                      <td>Percent</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        {
                          this.props.nameIdArr[
                            this.state.myLeastPicked[0][1]
                          ][1]
                        }
                      </td>
                      <td>
                        {this.state.myLeastPicked[0][0]}/
                        {this.props.whoIPick[this.props.whoIPick.length - 1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.myLeastPicked[0][0] * 1.0) /
                            this.props.whoIPick[
                              this.props.whoIPick.length - 1
                            ]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        {
                          this.props.nameIdArr[
                            this.state.myLeastPicked[1][1]
                          ][1]
                        }
                      </td>
                      <td>
                        {this.state.myLeastPicked[1][0]}/
                        {this.props.whoIPick[this.props.whoIPick.length - 1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.myLeastPicked[1][0] * 1.0) /
                            this.props.whoIPick[
                              this.props.whoIPick.length - 1
                            ]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        {
                          this.props.nameIdArr[
                            this.state.myLeastPicked[2][1]
                          ][1]
                        }
                      </td>
                      <td>
                        {this.state.myLeastPicked[2][0]}/
                        {this.props.whoIPick[this.props.whoIPick.length - 1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.myLeastPicked[2][0] * 1.0) /
                            this.props.whoIPick[
                              this.props.whoIPick.length - 1
                            ]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
          </Col>
          <Col sm="0" lg="1"></Col>
        </Row>
      );
    }
  };

  getC2() {
    if (this.state.key2 === "most" && this.state.whoLovesMe.length >= 3) {
      return (
        <Row>
          <Col sm="0" lg="1"></Col>
          <Col>
            <Card.Body>
              <Card.Title>Who Picks You the Most</Card.Title>
              <Card.Text>
                <Table id="mPicksTable2">
                  <thead>
                    <tr>
                      <td>Rank</td>
                      <td>Team</td>
                      <td>Fraction</td>
                      <td>Percent</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        {this.props.nameIdArr[this.state.whoLovesMe[0][1]][1]}
                      </td>
                      <td>
                        {this.state.whoLovesMe[0][2][0]}/
                        {this.state.whoLovesMe[0][2][1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.whoLovesMe[0][2][0] * 1.0) /
                            this.state.whoLovesMe[0][2][1]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        {this.props.nameIdArr[this.state.whoLovesMe[1][1]][1]}
                      </td>
                      <td>
                        {this.state.whoLovesMe[1][2][0]}/
                        {this.state.whoLovesMe[1][2][1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.whoLovesMe[1][2][0] * 1.0) /
                            this.state.whoLovesMe[1][2][1]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        {this.props.nameIdArr[this.state.whoLovesMe[2][1]][1]}
                      </td>
                      <td>
                        {this.state.whoLovesMe[2][2][0]}/
                        {this.state.whoLovesMe[2][2][1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.whoLovesMe[2][2][0] * 1.0) /
                            this.state.whoLovesMe[2][2][1]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
          </Col>
          <Col sm="0" lg="1"></Col>
        </Row>
      );
    } else if (
      this.state.key2 === "least" &&
      this.state.whoHatesMe.length >= 3
    ) {
      return (
        <Row>
          <Col sm="0" lg="1"></Col>
          <Col>
            <Card.Body>
              <Card.Title>Who Picks You the Least</Card.Title>
              <Card.Text>
                <Table id="lPicksTable2">
                  <thead>
                    <tr>
                      <td>Rank</td>
                      <td>Team</td>
                      <td>Fraction</td>
                      <td>Percent</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        {this.props.nameIdArr[this.state.whoHatesMe[0][1]][1]}
                      </td>
                      <td>
                        {this.state.whoHatesMe[0][2][0]}/
                        {this.state.whoHatesMe[0][2][1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.whoHatesMe[0][2][0] * 1.0) /
                            this.state.whoHatesMe[0][2][1]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        {this.props.nameIdArr[this.state.whoHatesMe[1][1]][1]}
                      </td>
                      <td>
                        {this.state.whoHatesMe[1][2][0]}/
                        {this.state.whoHatesMe[1][2][1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.whoHatesMe[1][2][0] * 1.0) /
                            this.state.whoHatesMe[1][2][1]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        {this.props.nameIdArr[this.state.whoHatesMe[2][1]][1]}
                      </td>
                      <td>
                        {this.state.whoHatesMe[2][2][0]}/
                        {this.state.whoHatesMe[2][2][1]}
                      </td>
                      <td>
                        {Math.round(
                          ((this.state.whoHatesMe[2][2][0] * 1.0) /
                            this.state.whoHatesMe[2][2][1]) *
                            100
                        )}
                        %
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
          </Col>
          <Col sm="0" lg="1"></Col>
        </Row>
      );
    }
  }

  getRecordCard() {
    if (this.props.recordsArr[0] && this.state.animated) {
      return (
        <Card className="card1">
          <Card.Header>
            <Card.Title>Pick 'Em Record</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <Row>
                <Col>
                  <Table id="rPicksTable">
                    <thead>
                      <tr>
                        <td style={{ textAlign: "center" }}>Rank</td>
                        <td
                          style={{
                            textAlign: "center",
                            color: "green",
                            fontWeight: "bolder",
                          }}
                        >
                          ✓
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: "red",
                            fontWeight: "bolder",
                          }}
                        >
                          X
                        </td>
                        <td style={{ textAlign: "center" }}>Percent</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          {this.state.myRank}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {this.props.recordsArr[this.props.myIndex][0]}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {this.props.recordsArr[this.props.myIndex][1]}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {(
                            ((this.props.recordsArr[this.props.myIndex][0] *
                              1.0) /
                              (this.props.recordsArr[this.props.myIndex][1] +
                                this.props.recordsArr[this.props.myIndex][0])) *
                            100
                          ).toFixed(0)}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }
  }

  getOthersPicksCard() {
    if (this.props.recordsArr[0] && this.state.animated) {
      return (
        <Card id="card">
          <Card.Header>
            <Card.Title>Picks For & Against You</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <Table id="fPicksTable">
                <thead>
                  <tr>
                    <td style={{ textAlign: "center" }}>For</td>
                    <td style={{ textAlign: "center" }}>Against</td>
                    <td style={{ textAlign: "center" }}>Percent</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      {this.state.picksForMe}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {this.state.picksAgainstMe}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Math.round(
                        ((this.state.picksForMe * 1.0) /
                          (this.state.picksAgainstMe + this.state.picksForMe)) *
                          100
                      )}
                      %
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }
  }

  getMoneyCard() {
    if (this.state.cash && this.state.animated) {
      return (
        <Card id="card">
          <Card.Header>
            <Card.Title>Money Breakdown</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <Table id="cashTable" style={{ tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <td style={{ textAlign: "center" }}>Pick 'Em</td>
                    <td style={{ textAlign: "center" }}>Power Ranking</td>
                    <td style={{ textAlign: "center" }}>Wins</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      ${this.state.cash[0]}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      ${this.state.cash[2]}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      ${this.state.cash[1]}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }
  }

  showC1() {
    if (this.state.animated && this.state.myMostPicked[0]) {
      return (
        <Card id="card">
          <Card.Header>
            <Tabs
              id="controlled-tab-example"
              defaultActiveKey="home"
              activeKey={this.state.key1}
              onSelect={(k) => {
                this.setState({ key1: k });
              }}
            >
              <Tab eventKey="most" title="Most"></Tab>
              <Tab eventKey="least" title="Least"></Tab>
            </Tabs>
          </Card.Header>
          {this.getC1()}
        </Card>
      );
    }
  }

  showC2() {
    if (this.state.animated) {
      return (
        <Card id="card">
          <Card.Header>
            <Tabs
              id="controlled-tab-example"
              defaultActiveKey="home"
              activeKey={this.state.key2}
              onSelect={(k) => {
                this.setState({ key2: k });
              }}
            >
              <Tab eventKey="most" title="Most"></Tab>
              <Tab eventKey="least" title="Least"></Tab>
            </Tabs>
          </Card.Header>
          {this.getC2()}
        </Card>
      );
    }
  }

  showTitle() {
    if (this.state.animated) {
      return (
        <div>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <Col id="tabTitle">Your Stats</Col>
          </Row>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
        </div>
      );
    }
  }

  async createRankPlot() {
    let week = getWeek();
    let myId = this.props.nameIdArr[this.props.myIndex][0];

    let allData = this.props.nameIdArr.map(t => [t[0], t[1]]);
    let currentValid = 0;

    if (
      this.props.rankingData.filter((r) => r.Week == week).length > 1 ||
      (this.props.rankingData.filter((r) => r.Week == week).length == 1 &&
        this.props.rankingData.filter((r) => r.Week == week)[0].Tid !== myId)
    ) {
      // >1 because two are needed for all 10 ranked
      currentValid = 1;
    }

    for (let i = 1; i < week + currentValid; i++) {
      let weekRanks = analyzePRWeek(
        this.props.rankingData.filter((r) => r.Week == i),
        ...this.props.nameIdArr
      );

      weekRanks = weekRanks.map((r,i) => [i+1, ...r])

      allData = allData.map((team) => {
        if (weekRanks.filter(r => r[1] === team[0]).length > 0) {
          return [...team, weekRanks.filter(r => r[1] === team[0])[0][0]];
        }
        else {
          return [...team, -1];
        }
      });
    }

    allData = allData.filter(
      (t) => t[0] === this.props.nameIdArr[this.props.myIndex][0]
    );

    //the rank of each team each week

    let options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          reverse: "true",
          title: {
            display: "true",
            text: "Rank",
          },
          min: 0,
          max: 10,
        },
        x: {
          title: {
            display: "true",
            text: "Week",
          },
        },
      },
    };
    let labels = [];

    for (let i = 1; i < week + currentValid; i++) {
      labels.push(i);
    }

    let colors = [
      "rgba(255,0,0,0.3)",
      "rgba(255,255,0,0.3)",
      "rgba(0,234,255,0.3)",
      "rgba(170,0,255,0.3)",
      "rgba(255,127,0,0.3)",
      "rgba(191,255,0,0.3)",
      "rgba(0,149,255,0.3)",
      "rgba(255,0,170,0.3)",
      "rgba(255,212,0,0.3)",
      "rgba(106,255,0,0.3)",
      "rgba(0,64,255,0.3)",
      "rgba(237,185,185,0.3)",
      "rgba(185,215,237,0.3)",
      "rgba(231,233,185,0.3)",
      "rgba(220,185,237,0.3)",
      "rgba(185,237,224,0.3)",
      "rgba(143,35,35,0.3)",
      "rgba(35,98,143,0.3)",
      "rgba(143,106,35,0.3)",
      "rgba(107,35,143,0.3)",
    ];

    let borderColors = [
      "rgba(255,0,0,0.7)",
      "rgba(255,255,0,0.7)",
      "rgba(0,234,255,0.7)",
      "rgba(170,0,255,0.7)",
      "rgba(255,127,0,0.7)",
      "rgba(191,255,0,0.7)",
      "rgba(0,149,255,0.7)",
      "rgba(255,0,170,0.7)",
      "rgba(255,212,0,0.7)",
      "rgba(106,255,0,0.7)",
      "rgba(0,64,255,0.7)",
      "rgba(237,185,185,0.7)",
      "rgba(185,215,237,0.7)",
      "rgba(231,233,185,0.7)",
      "rgba(220,185,237,0.7)",
      "rgba(185,237,224,0.7)",
      "rgba(143,35,35,0.7)",
      "rgba(35,98,143,0.7)",
      "rgba(143,106,35,0.7)",
      "rgba(107,35,143,0.7)",
    ];

    let data = {
      labels,
      scale: "reverse",
      datasets: allData.map((team, ind, arr) => {
        let d = [...team];
        d = d.slice(2);

        return {
          label: team[1],
          data: d,
          borderColor: borderColors[this.props.myIndex],
          backgroundColor: colors[this.props.myIndex],
          tension: 0.1,
          fill: { value: 10 },
          borderWidth: 5,
        };
      }),
    };

    this.setState({ rankGraph: data, rankGraphOptions: options });
  }

  rankingsPlot() {
    if (this.state.rankGraph.length != 0 && this.state.animated) {
      return (
        <Card id="card">
          <Card.Header>
            {" "}
            <Card.Title>Power Rankings Overview</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <Line
                options={this.state.rankGraphOptions}
                data={this.state.rankGraph}
                responsive
              />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    } else {
    }
  }

  render() {
    return (
      <div>
        <Container id="pContainer">
          <div ref={this.r6}>{this.showTitle()}</div>
          <Row style={{ marginTop: "10px" }}>
            <Col lg="1" sm="0"></Col>
            <Col style={{ paddingRight: "2px" }} sm="12" lg="5" ref={this.r1}>
              {this.getRecordCard()}
            </Col>
            <Col style={{ paddingLeft: "2px" }} sm="12" lg="5" ref={this.r2}>
              {this.getOthersPicksCard()}
            </Col>
            <Col lg="1" sm="0"></Col>
          </Row>
          <Row>
            <Col lg="1" sm="0"></Col>
            <Col ref={this.r3}>{this.showC1()}</Col>
            <Col lg="1" sm="0"></Col>
          </Row>
          <Row>
            <Col lg="1" sm="0"></Col>
            <Col ref={this.r4}>{this.showC2()}</Col>
            <Col lg="1" sm="0"></Col>
          </Row>
          <Row>
            <Col lg="1" sm="0"></Col>
            <Col ref={this.r5}>{this.getMoneyCard()}</Col>
            <Col lg="1" sm="0"></Col>
          </Row>
          <Row>
            <Col lg="1" sm="0"></Col>
            <Col ref={this.r7}>{this.rankingsPlot()}</Col>
            <Col lg="1" sm="0"></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default StatsPersonal;
