import React from "react";
import Team from "./Team";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Pie } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import {
  usersReference,
  updateUser,
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  keepersReference,
  sleep,
} from "./App";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { keepArr } from "./KeeperContainer";
import { gsap } from "gsap";

import { Draggable } from "react-beautiful-dnd";

class PRCTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadCount: 0,
      nameIdArr: [],
      animate: false,
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.r8 = React.createRef();
    this.r9 = React.createRef();
    this.r10 = React.createRef();
  }

  testAnimate = async (a) => {
    await sleep(100);

    if (this.state.loadCount == a) {
      gsap.from(this.r1.current, { y: "400%", duration: 3, ease: "power4" });
      // gsap.from(this.r4.current, { y: "100%", duration: 3, ease: "bounce", scale: 0.5});
      // await sleep(1000)
      this.setState({ animate: true });
    }
  };

  componentDidMount() {
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.testAnimate(d);
    }
  }

  enter = ({ currentTarget }) => {
    if (this.props.disable) {
      return;
    }
    gsap.to(currentTarget, { scale: 1.2, fontWeight: "bolder" });
  };

  leave = ({ currentTarget }) => {
    if (this.props.disable) {
      return;
    }
    gsap.to(currentTarget, {
      color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  render() {
    return (
      <Draggable
        draggableId={this.props.team[0]}
        index={this.props.index}
        isDragDisabled={this.props.disable}
      >
        {(provided) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Row>
              <Col sm="1" lg="3"></Col>
              <Col>
                <Row
                  id="rankedTeam"
                  ref={this.r1}
                  onMouseEnter={this.enter}
                  onMouseLeave={this.leave}
                  style={{
                    backgroundColor: this.props.disable ? "lightgray" : "white",
                  }}
                >
                  <Col xs={1} style={{margin:"0", padding:"0"}}>Ξ</Col>
                  <Col id="rankNum" xs={1}>
                    {this.props.index + 1}
                  </Col>

                  <Col style={{ paddingLeft: "0px" }}>{this.props.team[1]}</Col>
                  <Col id="rankRecord" xs={2}>
                    ({this.props.team[3][0]}-{this.props.team[3][1]})
                  </Col>
                </Row>
              </Col>
              <Col sm="1" lg="3"></Col>
            </Row>
          </Container>
        )}
      </Draggable>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default PRCTeam;
