import React from "react";
import { getDocs, query, where } from "firebase/firestore";
import { usersReference, updateUser, getId } from "./App";
import Button from "react-bootstrap/Button";
import { gsap } from "gsap";

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      taken: false,
      checked: false,
      tMessage: "",
    };
    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.r8 = React.createRef();
    this.r9 = React.createRef();
    this.r10 = React.createRef();
  }

  componentDidMount() {
    if (!this.state.checked) {
      this.checkValid();
    }
  }

  async checkValid() {
    let q = query(usersReference, where("id", "==", this.props.id));
    const data = await getDocs(q);

    if (data.size == 1 && localStorage.getItem("name") !== "guest") {
      this.setState({ taken: true });
    }
    this.setState({ checked: true });
  }

  // assign team to user in db
  async selectTeam() {
    let tmp = [];
    tmp.push(this.props.name);
    tmp.push(this.props.id);

    this.props.teamSelected(tmp);
  }

  enter = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.2, fontWeight: "bolder" });
  };

  leave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      // color: "#03256c",
      scale: 1,
      fontWeight: "normal",
    });
  };

  render() {
    return (
      <div>
        <Button
          id="select-button"
          disabled={this.state.taken || this.props.lockAll}
          onClick={() => this.selectTeam()}
          style={{
            backgroundColor:
              this.props.selection == this.props.name ||
              this.props.id == this.props.myId
                ? "#2541B2"
                : "",
            color:
              this.props.selection == this.props.name ||
              this.props.id == this.props.myId
                ? "white"
                : "",
          }}
          onMouseEnter={this.enter}
          onMouseLeave={this.leave}
          ref={this.r1}
        >
          {this.props.name}
        </Button>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default Team;
