import Team from "../Team";
import StatsContainer from "../StatsContainer";
import { useEffect, useState } from "react";
import { getDocs } from "firebase/firestore";
import { winnersReference, sleep } from "../App";

// export const winnersArr = {
//   1: {
//     data: {
//       picks: [
//         "Milwaukee Milkcrates",
//         "Half Mast & Bass Boosted",
//         "Whitefish Bay Wagons",
//         "rossmunce",
//         "Cninko",
//         "Devontae is dead to me",
//         "spildzz",
//         "Fond du Lac Fighters",
//         "Hash Browns",
//         "Sugma",
//       ],
//       tiebreaker: 32.5,
//       winner_info: ["411947911086096384", 10],
//     },
//   },
//   2: {
//     data: {
//       picks: [
//         "TheVodkaPalace",
//         "Complicated Fellas ",
//         "Half Mast & Bass Boosted ",
//         "Whitefish Bay Wagons",
//         "pottynode",
//         "Pour one out for Mahomes",
//         "Rramuta",
//         "sruetz",
//         "Nico Mannion Fan Club",
//         "Sugma",
//       ],
//       tiebreaker: "26",
//       winner_info: ["466461424856592384", 5],
//     },
//   },
//   3: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   4: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   5: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   6: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   7: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   8: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   9: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   10: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   11: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   12: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   13: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   14: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   15: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   16: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   17: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
//   18: {
//     data: {
//       picks: [],
//       tiebreaker: "",
//       winner_info: ["", 5],
//     },
//   },
// };


const Stats = () => {
  const [winnerArr, setWinnerArr] = useState([]);
  useEffect(() => {
    const getWinnerArr = async () => {
      let winnersArr = await getDocs(winnersReference);

      await sleep(500);
      
      setWinnerArr(winnersArr.docs[0].data().Winners);
      
    };

    if (winnerArr.length == 0) {
      getWinnerArr();
    }
  });

  return (
    <div>
      <StatsContainer winnersArr={winnerArr}/>
      {/* {this.showTeams()} */}
    </div>
  );
};

export default Stats;
