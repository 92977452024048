import React from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { usersReference, updateUser } from "./App";
import App from "./App";
import { db } from "./Firebase";
import Keeper from "./Keeper";
import keepers from "./keepers.json";  // Import the JSON data



class KeeperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      k: keepers,
    };
  }

  render() {
    return (
    <div>
        <Keeper 
        info = {this.state.k}
        />
    </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default KeeperContainer;
