import React from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { usersReference, updateUser, getId, keepersReference } from "./App";
import App from "./App";
import { db } from "./Firebase";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import KeeperPlayer from "./KeeperPlayer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { TimeScale } from "chart.js";
import { sleep, currYear } from "./App";
import { gsap } from "gsap";
// import { usersReference, updateUser, picksReference, getId } from "./App";

class Keeper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keepers: [],
      userIndex: -1,
      userId: -1,
      k1: [],
      k2: [],
      twoV: false,
      selection: "",
      top: "",
      bottom: "",
      i: 0,
      delay: 100,
      winnerFound: false,
      keepMessage: "Keep two players?",
      showModal: false,
      showModal1: false,
      alreadyDone: false,
      spinning: false,
      singleSub: false,
      testRun: false,
      loadCount: 0,
      lc2: 0,
      animated: false,
    };

    this.r1 = React.createRef();
    this.r2 = React.createRef();
    this.r3 = React.createRef();
    this.r4 = React.createRef();
    this.r5 = React.createRef();
    this.r6 = React.createRef();
    this.r7 = React.createRef();
    this.r8 = React.createRef();
    this.r9 = React.createRef();
    this.r10 = React.createRef();
    this.s1 = React.createRef();
    this.s2 = React.createRef();
    this.s3 = React.createRef();
    this.s4 = React.createRef();
  }

  async randomSelect() {
    gsap.from(this.r8.current, {
      y: "-200%",
      duration: 1.5,
      ease: "power2",
      scale: 0.5,
    });
    gsap.from(this.r9.current, { duration: 1.5, ease: "power2", scale: 0.5 });
    gsap.from(this.r10.current, {
      y: "200%",
      duration: 1.5,
      ease: "power2",
      scale: 0.5,
    });

    let r = Math.floor(Math.random() * 10);
    this.setState({ i: 0, winnerFound: false, spinning: true });

    let iId = setInterval(() => {
      if (this.state.i == 40 + r) {
        clearInterval(iId);

        setTimeout(() => {
          this.setState({
            selection: this.state.k2[this.state.i % 3],
            i: this.state.i + 1,
            top: this.state.k2[(this.state.i + 1) % 3],
            bottom: this.state.k2[(this.state.i - 1) % 3],
          });
        }, 200);

        setTimeout(() => {
          this.setState({
            selection: this.state.k2[this.state.i % 3],
            i: this.state.i + 1,
            top: this.state.k2[(this.state.i + 1) % 3],
            bottom: this.state.k2[(this.state.i - 1) % 3],
          });
        }, 500);

        setTimeout(() => {
          this.setState({
            selection: this.state.k2[this.state.i % 3],
            i: this.state.i + 1,
            top: this.state.k2[(this.state.i + 1) % 3],
            bottom: this.state.k2[(this.state.i - 1) % 3],
          });
        }, 900);

        setTimeout(() => {
          this.setState({
            selection: this.state.k2[this.state.i % 3],
            i: this.state.i + 1,
            top: this.state.k2[(this.state.i + 1) % 3],
            bottom: this.state.k2[(this.state.i - 1) % 3],
          });
        }, 1400);

        setTimeout(() => {
          this.setState({
            selection: this.state.k2[this.state.i % 3],
            i: this.state.i + 1,
            top: this.state.k2[(this.state.i + 1) % 3],
            bottom: this.state.k2[(this.state.i - 1) % 3],
          });
        }, 2000);

        setTimeout(() => {
          this.setState({
            selection: this.state.k2[this.state.i % 3],
            i: this.state.i + 1,
            top: this.state.k2[(this.state.i + 1) % 3],
            bottom: this.state.k2[(this.state.i - 1) % 3],
          });
        }, 2700);

        setTimeout(() => {
          this.setState({
            selection: this.state.k2[this.state.i % 3],
            i: this.state.i + 1,
            top: this.state.k2[(this.state.i + 1) % 3],
            bottom: this.state.k2[(this.state.i - 1) % 3],
          });
        }, 3700);

        setTimeout(() => {
          this.setState({
            selection: this.state.k2[this.state.i % 3],
            i: this.state.i + 1,
            top: this.state.k2[(this.state.i + 1) % 3],
            bottom: this.state.k2[(this.state.i - 1) % 3],
          });

          if (!this.state.testRun) {
            this.setState({ winnerFound: true });
            this.submitDouble(this.state.k2[this.state.i % 3]);
          } else {
            this.setState({ spinning: false });
          }
        }, 5000);
      }

      this.setState({
        selection: this.state.k2[this.state.i % 3],
        top: this.state.k2[(this.state.i + 1) % 3],
        bottom: this.state.k2[(this.state.i - 1) % 3],
      });

      this.setState({ i: this.state.i + 1 });
    }, 100);
  }

  async findIndex() {
    if (this.state.userIndex == -1) {
      let Uid = await getId(usersReference);
      console.log('Uid: ', Uid)
      if (localStorage.getItem("name") === "guest") {
        Uid = "466461424856592384";
      }
      let index = -1;
      let d = this.props.info;

      for (let i = 0; i < d.length; i++) {
        if (d[i]["id"] === Uid) {
          index = i;
        }
      }
      this.setState({ userIndex: index, userId: Uid });

      let q = query(keepersReference, where("Uid", "==", Uid), where("Year", "==", currYear));

      const data = await getDocs(q);

      if (data.size > 0 && localStorage.getItem("name") !== "guest") {
        let k1 = [];
        k1.push(
          data.docs[0]._document.data.value.mapValue.fields.K1.stringValue
        );

        this.setState({
          alreadyDone: true,
          k1: k1,
        });

        if (this.state.lc2 == 0) {
          let d = Math.random();
          this.setState({ lc2: d });
          this.animateDone(d);
        }

        if (
          typeof data.docs[0]._document.data.value.mapValue.fields.K2 !==
          "undefined"
        ) {
          let k2 = [];

          k2.push(
            data.docs[0]._document.data.value.mapValue.fields.K2.stringValue
          );
          k2.push(-1);
          k2.push(-1);


          this.setState({
            selection:
              data.docs[0]._document.data.value.mapValue.fields.K2.stringValue,
            k2: k2,
          });
        }
      }

    }
  }

  animateDone = async (a) => {
    await sleep(50);
    if (this.state.lc2 == a) {
      gsap.from(this.r3.current, {
        x: "-100%",
        duration: 1.5,
        ease: "power2",
        scale: 1,
      });

      gsap.from(this.r9.current, {x: "100%", duration: 1.5, ease: "power2", scale: 0.5 });
    }
  };

  animate = async (a) => {
    await sleep(100);
    if (this.state.loadCount == a) {
      gsap.from(this.r1.current, {
        x: "-100%",
        duration: 2,
        ease: "power4",
        scale: 0.5,
      });
      gsap.from(this.r2.current, {
        x: "100%",
        duration: 2,
        ease: "power4",
        scale: 0.5,
      });
      gsap.from(this.s1.current, {
        // x: "100%",
        duration: 2,
        ease: "bounce",
        scale: 0.5,
      });
      this.setState({animated: true})
      // await sleep(1000);
    }
  };

  componentDidMount() {
    this.findIndex();
    if (this.state.loadCount == 0) {
      let d = Math.random();
      this.setState({ loadCount: d });
      this.animate(d);
    }
  }

  showKP() {
    if (this.state.userIndex != -1) {
      let r = [];

      for (
        let i = 0;
        i < this.props.info[this.state.userIndex]["players"].length;
        i++
      ) {
        r.push(
          <KeeperPlayer
            name={this.props.info[this.state.userIndex]["players"][i]["player"]}
            pick={this.props.info[this.state.userIndex]["players"][i]["round"]}
            kOneFunc={this.keeperOne}
            kTwoFunc={this.keeperTwo}
            k1={this.state.k1}
            k2={this.state.k2}
            twoV={this.state.twoV}
            selection={this.state.selection}
            alreadyDone={this.state.alreadyDone}
            spinning={this.state.spinning}
            testRun={this.state.testRun}
            // id={this.props.recipes[i].id}
          />
        );
      }

      return r;
    }
  }

  keeperOne = (data) => {
    if (this.state.k1.length == 0) {
      gsap.from(this.r3.current, {
        y: "1000%",
        duration: 1.5,
        ease: "power2",
        scale: 1,
      });
      gsap.from(this.r4.current, {
        y: "1000%",
        duration: 1.5,
        ease: "power2",
        scale: 1,
      });
      gsap.from(this.r5.current, {
        y: "1000%",
        duration: 1.5,
        ease: "power2",
        scale: 1,
      });
    }
    if (!this.state.k2.includes(data)) {
      let arr = [];
      arr.push(data);
      this.setState({ k1: arr });
    }
  };

  keeperTwo = (data) => {
    let newAdd = "";

    if (this.state.k2.length >= 3 || this.state.k1.includes(data)) {
      //do not add because the max selections have been made
    } else {
      let arr = this.state.k2;

      if (!arr.includes(data)) {
        arr.push(data);

        this.setState({ k2: arr });
        newAdd = data;
      }
    }

    if (newAdd !== data) {
      if (this.state.k2.length > 0) {
        let tmp = this.state.k2;

        for (let i = 0; i < tmp.length; i++) {
          if (tmp[i] === data) {
            tmp.splice(i, 1);
            break;
          }
        }

        this.setState({ k2: tmp });
      }
    }
  };

  validTwo() {
    if (this.state.twoV == false) {
      gsap.from(this.r6.current, {
        x: "100%",
        duration: 1.5,
        ease: "power2",
        scale: 1,
      });
      gsap.from(this.r7.current, {
        x: "100%",
        duration: 1.5,
        ease: "power2",
        scale: 1,
      });
    } else {
      // gsap.from(this.r5.current, {y: "1000%", duration: 1.5, ease: "power2", scale: 1 });
    }
    if (this.state.keepMessage === "Keep two players?") {
      this.setState({ keepMessage: "Keeping two players..." });
    } else {
      this.setState({ keepMessage: "Keep two players?" });
    }

    this.setState({ twoV: !this.state.twoV });
  }

  singleLock() {
    if (
      !this.state.twoV &&
      this.state.k1.length == 1 &&
      !this.state.alreadyDone
    ) {
      return (
        <Button
          id="lock-in"
          onClick={() => this.setState({ showModal1: true })}
        >
          Lock in {this.state.k1[0]} as only keeper?
        </Button>
      );
    }
  }

  showRButton() {
    if (this.state.twoV && !this.state.alreadyDone) {
      return (
        <Button
          id="random-button"
          // onClick={() => this.randomSelect()}
          onClick={() => {
            this.setState({ testRun: false, showModal: true });
          }}
          disabled={
            this.state.k2.length != 3 ||
            !this.state.twoV ||
            (this.state.selection != "" && !this.state.testRun) ||
            this.state.spinning
          }
        >
          Randomize 2nd Keeper (and lock in keepers)
        </Button>
      );
    }
  }

  async submitSingle() {

    if (localStorage.getItem("name") === "guest") {
      this.setState({ alreadyDone: true, singleSub: true });
      return;
    }

    await addDoc(keepersReference, {
      K1: this.state.k1[0],
      Uid: this.state.userId,
      Year: currYear
    });

    this.setState({ alreadyDone: true, singleSub: true });
  }

  async submitDouble(data) {

    if (localStorage.getItem("name") === "guest") {
      this.setState({ alreadyDone: true, spinning: false });
      return;
    }

    await addDoc(keepersReference, {
      K1: this.state.k1[0],
      K2: data,
      Uid: this.state.userId,
      Year: currYear
    });

    this.setState({ alreadyDone: true, spinning: false });
  }

  twoOption() {
    if (this.state.k1.length == 1 && !this.state.alreadyDone) {
      return (
        <Button
          id="random-button"
          onClick={() => this.validTwo()}
          style={{
            backgroundColor: this.state.twoV ? "#03256C" : "",
            color: this.state.twoV ? "white" : "",
          }}
          disabled={
            this.state.k1.length == 0 ||
            this.state.alreadyDone ||
            (this.state.selection != "" && !this.state.testRun) ||
            this.state.spinning
          }
        >
          {this.state.keepMessage}
        </Button>
      );
    }
  }

  showTestButton() {
    if (this.state.twoV && !this.state.alreadyDone) {
      return (
        <Button
          id="random-button"
          // onClick={() => this.randomSelect()}
          onClick={() => {
            this.setState({ testRun: true });
            this.randomSelect();
          }}
          disabled={
            this.state.k2.length != 3 ||
            !this.state.twoV ||
            (this.state.selection != "" && !this.state.testRun) ||
            this.props.spinning
          }
        >
          Test Spin
        </Button>
      );
    }
  }

  showT1() {
    if (this.state.animated) {
      return("Keeper Selection");
    }
  }

  showT2() {
    if (this.state.animated) {
      return("KEEPER #1");
    }
  }

  showT3() {
    if (this.state.animated) {
      return("KEEPER #2");
    }
  }

  enter = ({ currentTarget }) => {
      gsap.to(currentTarget, {scale: 1.1, fontWeight: "bolder"},);
  
    
  };

  leave = ({ currentTarget }) => {
    gsap.to(currentTarget, { color: "#03256c", scale: 1 , fontWeight: "normal"});
  };

  render() {
    return (
      <div>
        <Container id="pickCont">
          <Row>
            <Col
              style={{
                marginTop: "10px",
                fontSize: "30px",
                fontWeight: "bolder",
              }}
              ref={this.s1}
            >
              {this.showT1()}
            </Col>
          </Row>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <Col
              id="kTitle"
              style={{
                color: this.state.alreadyDone ? "grey" : "",
              }}
              ref={this.r1}
            >
              {this.showT2()}
            </Col>
            <Col
              style={{
                color: !this.state.twoV ? "grey" : "",
              }}
              id="kTitle"
              ref={this.r2}
            >
              {this.showT3()}
            </Col>
          </Row>
        </Container>
        {this.showKP()}
        <Container></Container>
        <Container id="slot">
          <Row>
            <Col></Col>
            <Col id="miss" ref={this.r10}>
              {this.state.top}
            </Col>
          </Row>
          <Row>
            <Col
              id="hit"
              style={{
                borderColor:
                  this.state.k1.length == 1
                    ? "#06BEE1"
                    : "",
              }}
              ref={this.r3}
            >
              {this.state.k1}
            </Col>
            {/* <Col xs={1}></Col> */}
            <Col
              id="hit"
              style={{
                borderColor:
                  this.state.winnerFound ||
                  (this.state.alreadyDone &&
                    this.state.k2.length > 0 &&
                    !this.state.singleSub &&
                    !this.state.testRun)
                    ? "#06BEE1"
                    : "",
              }}
              ref={this.r9}
            >
              {this.state.selection}
            </Col>
            {/* <Col xs={4}></Col> */}
          </Row>
          <Row>
            <Col></Col>
            <Col ref={this.r8} id="miss">
              {this.state.bottom}
            </Col>
          </Row>
        </Container>
        <Container id="lockRow">
          <Row
            style={{
              marginTop: "50px",
            }}
          >
            <Col ref={this.r4}  onMouseEnter={this.enter}  onMouseLeave={this.leave}>{this.twoOption()}</Col>
            <Col ref={this.r6}  onMouseEnter={this.enter}  onMouseLeave={this.leave}>{this.showRButton()}</Col>
          </Row>
          <Row
            style={{
              marginTop: "5px",
            }}
          >
            <Col ref={this.r5} onMouseEnter={this.enter}  onMouseLeave={this.leave}>{this.singleLock()}</Col>
            <Col ref={this.r7} onMouseEnter={this.enter}  onMouseLeave={this.leave}>{this.showTestButton()}</Col>
          </Row>
        </Container>

        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Randomization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Once randomization begins, you cannot change your keepers. You will
            be given your first keeper as well as the keeper that is randomly
            chosen from your selection of three:{" "}
            <strong>{this.state.k2[0]}</strong>,{" "}
            <strong>{this.state.k2[1]}</strong>,{" "}
            <strong>{this.state.k2[2]}</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showModal: false })}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showModal: false });
                this.randomSelect();
              }}
            >
              Randomize
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal1}
          onHide={() => this.setState({ showModal1: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Selection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            If confirmed, your only keeper will be{" "}
            <strong>{this.state.k1[0]}</strong>, and you will not be able to
            change this afterward.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showModal1: false })}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showModal1: false });
                this.submitSingle();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default Keeper;
