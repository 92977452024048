import React from "react";
import Team from "./Team";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Pie } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import {
  usersReference,
  updateUser,
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  sleep,
} from "./App";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { getWinnersArr } from "./StatsContainer";
import { ThemeConsumer } from "styled-components";

export const analyzePRWeek = (weekData, nameIdArr) => {
  let avgs = [];
  let who = [];

  //iterate through the rankings of the week
  for (let i = 0; i < weekData.length; i++) {
    let currNames = weekData[i].Rankings.map((id) => {
      for (let j = 0; j < nameIdArr.length; j++) {
        if (id === nameIdArr[j][0]) {
          return nameIdArr[j][1];
        }
      }
    });

    who.push(weekData[i].Tid);

    //iterate through the current set of rankings
    for (let j = 0; j < weekData[i].Rankings.length; j++) {
      //if it's the first set of rankings
      if (i == 0) {
        avgs.push([weekData[i].Rankings[j], j + 1, currNames[j]]);
      } else {
        let found = false;
        for (let h = 0; h < avgs.length; h++) {
          if (avgs[h][0] === weekData[i].Rankings[j]) {
            found = true;
            avgs[h] = [
              weekData[i].Rankings[j],
              ((avgs[h][1] + j + 1) * 1.0) / 2,
              currNames[j],
            ];
          }
        }
        if (!found) {
          avgs.push([weekData[i].Rankings[j], j + 1, currNames[j]]);
        }
      }
    }
  }

  who = who.map((t) => {
    for (let i = 0; i < nameIdArr.length; i++) {
      if (nameIdArr[i][0] === t) {
        return nameIdArr[i][1];
      }
    }
  });
  return avgs.sort((a, b) => a[1] - b[1]);
};

Chart.register(RadialLinearScale, ArcElement, Tooltip, Legend);

class StatsWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      tbGraph: [],
      loadCount: 0,
      selectedWeek: this.props.currWeek,
      prevRows: 0,
      prevRows1: 0,
      ppm: [],
      whoRankedThisWeek: [],
      deletingTables: false,
    };
  }

  componentDidMount() {
    if (this.state.datasets.length == 0) {
      this.createDataSets(this.state.selectedWeek);
    }
    if (this.state.tbGraph.length == 0) {
      this.createTBPlot(this.state.selectedWeek);
    }
    if (this.state.loadCount == 0) {
      this.setTable(this.state.selectedWeek);
      this.getRankingData(this.state.selectedWeek);
      this.setState({ loadCount: this.state.loadCount++ });
    }
  }

  analyzePRWeek = (weekData, nameIdArr) => {
    // let nameIdArr = [...nIA];
    let avgs = [];
    let who = [];

    //iterate through the rankings of the week
    for (let i = 0; i < weekData.length; i++) {
      let currNames = weekData[i].Rankings.map((id) => {
        for (let j = 0; j < nameIdArr.length; j++) {
          if (id === nameIdArr[j][0]) {
            return nameIdArr[j][1];
          }
        }
      });

      who.push(weekData[i].Tid);

      //iterate through the current set of rankings
      for (let j = 0; j < weekData[i].Rankings.length; j++) {
        //if it's the first set of rankings
        if (i == 0) {
          avgs.push([weekData[i].Rankings[j], j + 1, currNames[j]]);
        } else {
          let found = false;
          for (let h = 0; h < avgs.length; h++) {
            if (avgs[h][0] === weekData[i].Rankings[j]) {
              found = true;
              avgs[h] = [
                weekData[i].Rankings[j],
                ((avgs[h][1] + j + 1) * 1.0) / 2,
                currNames[j],
              ];
            }
          }
          if (!found) {
            avgs.push([weekData[i].Rankings[j], j + 1, currNames[j]]);
          }
        }
      }
    }

    who = who.map((t) => {
      for (let i = 0; i < nameIdArr.length; i++) {
        if (nameIdArr[i][0] === t) {
          return nameIdArr[i][1];
        }
      }
    });

    this.setState({ whoRankedThisWeek: who });
    return avgs.sort((a, b) => a[1] - b[1]);
  };

  getRankingData = async (week) => {
    let winnersArr = await getWinnersArr();
    let weekData = this.props.rankingData.filter((r) => r.Week == week);
    let lastWeekData = this.props.rankingData.filter((r) => r.Week == week - 1);
    let lwSortedRanks = [];
    if (week - 1 > 0) {
      lwSortedRanks = await this.analyzePRWeek(
        lastWeekData,
        this.props.nameIdArr
      );
    }

    let sortedRanks = await this.analyzePRWeek(weekData, this.props.nameIdArr);

    //get real records
    let records = this.props.nameIdArr.map((team) => {
      let wins = 0;
      for (let i = 1; i < this.state.selectedWeek; i++) {
        if (winnersArr[i].data.picks.includes(team[0])) {
          wins++;
        }
      }
      // let winPercentage = Math.round(((wins * 1.0) / (week - 1)) * 100);
      return [wins, this.state.selectedWeek - 1 - wins, team[0], team[1]];
    });

    let sr = sortedRanks.map((rank) => {
      for (let i = 0; i < records.length; i++) {
        if (records[i][2] === rank[0]) {
          return [...rank, records[i][0], records[i][1]];
          break;
        }
      }
    });

    sr = sr.map((rank, index) => {
      if (week - 1 > 0 && lwSortedRanks.length > 0) {
        for (let i = 0; i < lwSortedRanks.length; i++) {
          if (lwSortedRanks[i][0] === rank[0]) {
            let difference = i - index;
            let msg = "";
            if (difference < 0) {
              msg = "▼" + Math.abs(difference);
            } else if (difference > 0) {
              msg = "▲" + difference;
            } else {
              msg = "≡";
            }
            return [...rank, i + 1, msg];
          }
        }
      } else {
        return [...rank, 0, "≡"];
      }
    });
    this.createRankingTable(sr);
  };

  createRankingTable = async (ranks) => {
    var table = document.getElementById("prTable");
    for (let i = -1; i < ranks.length; i++) {
      var row = table.insertRow();
      if (i == -1) {
        var cell1 = row.insertCell();
        var cell2 = row.insertCell();
        var cell3 = row.insertCell();
        var cell4 = row.insertCell();
        cell1.innerHTML = "Rank";
        cell2.innerHTML = "Name";
        cell3.innerHTML = "Record";
        cell4.innerHTML = "Last Week";
        row.style.fontWeight = "bolder";
      } else {
        let r = "" + ranks[i][3] + "-" + ranks[i][4];
        let rowContent = [
          i + 1,
          ranks[i][2],
          r,
          ranks[i][5] + " (" + ranks[i][6] + ")",
        ];
        for (let j = 0; j < rowContent.length; j++) {
          var cell1 = row.insertCell();
          if (j == rowContent.length - 1) {
            if (rowContent[j].includes("▼")) {
              // let insert = rowContent[j][0] + " (" + <span>▼</span> + rowContent[j][4] + ")"
              // cell1.innerHTML = rowContent[j].Replace("▼", cell1.innerHTML.format("<span>fsdf</span>", "▼"));;
              cell1.innerHTML = rowContent[j];
              cell1.style.color = "red";
            } else if (rowContent[j].includes("▲")) {
              cell1.innerHTML = rowContent[j];
              cell1.style.color = "green";
            } else {
              cell1.innerHTML = rowContent[j];
            }
          } else {
            cell1.innerHTML = rowContent[j];
          }
        }
      }
    }

    if (this.state.prevRows1 > 0) {
      this.tableCleanup(this.state.prevRows1, table);
    }

    this.setState({ prevRows1: ranks.length + 1 });
  };

  createDataSets = async (week) => {
    let winnersArr = await getWinnersArr();

    let picksPerMatchup = [];
    let sets = [];

    if (this.state.datasets.length == 0) {
      await sleep(500);
    }

    //iterate through the matchups of this week
    for (
      let i = 0;
      i < this.props.matchups[this.state.selectedWeek - 1].length;
      i++
    ) {
      let t1 = 0;
      let t2 = 0;

      //this graph will represent the picks for the following matchup
      let currMatch = [
        this.props.matchups[this.state.selectedWeek - 1][i][0][0],
        this.props.matchups[this.state.selectedWeek - 1][i][1][0],
      ];

      //iterate through the picks of the current week
      for (let j = 0; j < this.props.picksByWeek[week].length; j++) {
        //if the current user picked the first team
        if (this.props.picksByWeek[week][j].Ids.includes(currMatch[0])) {
          t1++;
        } else {
          t2++;
        }
      }

      let tmp = [];
      tmp.push(t1);
      tmp.push(t2);
      let currPie;

      picksPerMatchup.push(tmp);

      if (week < (await getWeek())) {
        if (winnersArr[week].data.picks.includes(currMatch[0])) {
          currPie = {
            // labels: [currMatch[0], currMatch[1]],
            // labels: [...currMatch],
            datasets: [
              {
                label: "# of Picks Recieved",
                data: [t1, t2],
                backgroundColor: [
                  "rgba(255, 0, 0, .8)",
                  "rgba(37, 65, 178, 0.3)",
                ],
                borderColor: ["rgba(255, 0, 0, 1)", "rgba(37, 65, 178, 1)"],
                borderWidth: 3,
              },
            ],
          };
        } else if (winnersArr[week].data.picks.includes(currMatch[1])) {
          currPie = {
            // labels: [currMatch[0], currMatch[1]],
            // labels: [...currMatch],
            datasets: [
              {
                label: "# of Picks Recieved",
                data: [t1, t2],
                backgroundColor: [
                  "rgba(255, 0, 0, 0.3)",
                  "rgba(37, 65, 178, .8)",
                ],
                borderColor: ["rgba(255, 0, 0, 1)", "rgba(37, 65, 178, 1)"],
                borderWidth: 3,
              },
            ],
          };
        }
      } else {
        currPie = {
          // labels: [currMatch[0], currMatch[1]],
          // labels: [...currMatch],
          datasets: [
            {
              label: "# of Picks Recieved",
              data: [t1, t2],
              backgroundColor: [
                "rgba(255, 0, 0, 0.3)",
                "rgba(37, 65, 178, 0.3)",
              ],
              borderColor: ["rgba(255, 0, 0, 1)", "rgba(37, 65, 178, 1)"],
              borderWidth: 3,
            },
          ],
        };
      }

      sets.push(currPie);
    }

    this.setState({ datasets: sets, ppm: picksPerMatchup });
  };

  checkData = (data) => {
    if (data) {
      return <Pie data={data} responsive="true" />;
    } else {
    }
  };

  createTBPlot = (week) => {
    let tbs = [];
    let tbIds = [];
    let tbNames = [];

    console.log('week: ', week);
    console.log('this.props.picksByWeek: ', this.props.picksByWeek);

    //iterate through this week's picks
    for (let i = 0; i < this.props.picksByWeek[week].length; i++) {
      tbs.push(this.props.picksByWeek[week][i].TieBreaker);
      tbIds.push(this.props.picksByWeek[week][i].Tid);
    }

    for (let i = 0; i < tbIds.length; i++) {
      for (let j = 0; j < this.props.nameIdArr.length; j++) {
        if (tbIds[i] === this.props.nameIdArr[j][0]) {
          tbNames.push(this.props.nameIdArr[j][1]);
        }
      }
    }

    let currPolar = {
      // labels: [currMatch[0], currMatch[1]],
      labels: [...tbNames],
      datasets: [
        {
          label: tbMessage[week],
          data: [...tbs],
          backgroundColor: [
            "rgba(255,0,0,0.3)",
            "rgba(255,255,0,0.3)",
            "rgba(0,234,255,0.3)",
            "rgba(170,0,255,0.3)",
            "rgba(255,127,0,0.3)",
            "rgba(191,255,0,0.3)",
            "rgba(0,149,255,0.3)",
            "rgba(255,0,170,0.3)",
            "rgba(255,212,0,0.3)",
            "rgba(106,255,0,0.3)",
            "rgba(0,64,255,0.3)",
            "rgba(237,185,185,0.3)",
            "rgba(185,215,237,0.3)",
            "rgba(231,233,185,0.3)",
            "rgba(220,185,237,0.3)",
            "rgba(185,237,224,0.3)",
            "rgba(143,35,35,0.3)",
            "rgba(35,98,143,0.3)",
            "rgba(143,106,35,0.3)",
            "rgba(107,35,143,0.3)",
          ],
          borderColor: [
            "rgba(255,0,0,0.7)",
            "rgba(255,255,0,0.7)",
            "rgba(0,234,255,0.7)",
            "rgba(170,0,255,0.7)",
            "rgba(255,127,0,0.7)",
            "rgba(191,255,0,0.7)",
            "rgba(0,149,255,0.7)",
            "rgba(255,0,170,0.7)",
            "rgba(255,212,0,0.7)",
            "rgba(106,255,0,0.7)",
            "rgba(0,64,255,0.7)",
            "rgba(237,185,185,0.7)",
            "rgba(185,215,237,0.7)",
            "rgba(231,233,185,0.7)",
            "rgba(220,185,237,0.7)",
            "rgba(185,237,224,0.7)",
            "rgba(143,35,35,0.7)",
            "rgba(35,98,143,0.7)",
            "rgba(143,106,35,0.7)",
            "rgba(107,35,143,0.7)",
          ],
          borderWidth: 3,
          // circular: false,
          hoverBorderWidth: 5,
        },
      ],
    };

    this.setState({ tbGraph: currPolar });
  };

  tbPlot() {
    if (this.state.tbGraph.length != 0) {
      return <PolarArea data={this.state.tbGraph} responsive="true" />;
    } else {
    }
  }

  getNameFromId = (data) => {
    for (let i = 0; i < this.props.nameIdArr.length; i++) {
      if (this.props.nameIdArr[i][0] === data) {
        return this.props.nameIdArr[i][1];
      }
    }
  };

  setTable = async (week) => {
    var table = document.getElementById("picksTable");

    if (this.state.prevRows == 0) {
      await sleep(500);
    }

    console.log("PBW")
    console.log(this.props.picksByWeek)

    //iterate through the picks of the given week
    for (let i = -1; i < this.props.picksByWeek[week].length; i++) {
      let winnersArr = await getWinnersArr();

      var row = table.insertRow();
      let rowContent = [];
      let teamName = "";
      let lockName = "";

      //if it's not the first row, push the team that made the picks and then their picks to rowContent
      if (i > -1) {
        teamName = await this.getNameFromId(
          this.props.picksByWeek[week][i].Tid
        );
        if (week < (await getWeek())) {
          let wins = 0;
          for (
            let j = 0;
            j < this.props.picksByWeek[week][i].Picks.length;
            j++
          ) {
            if (
              winnersArr[week].data.picks.includes(
                this.props.picksByWeek[week][i].Ids[j]
              )
            ) {
              wins++;
            }
          }

          if (
            winnersArr[week].data.picks.includes(
              this.props.picksByWeek[week][i].Lock
            )
          ) {
            wins++;
          } else {
            wins--;
          }

          rowContent.push(teamName + " (" + wins + ")");
        } else {
          rowContent.push(teamName);
        }

        //make sure all names are updated

        let currNames = this.props.picksByWeek[week][i].Ids.map((id) => {
          for (let j = 0; j < this.props.nameIdArr.length; j++) {
            if (id === this.props.nameIdArr[j][0]) {
              if (id == this.props.picksByWeek[week][i].Lock) {
                lockName = this.props.nameIdArr[j][1];
                // return this.props.nameIdArr[j][1] + " 🔒";
              }
              return this.props.nameIdArr[j][1];
            }
          }
        });

        rowContent.push(...currNames);
        rowContent.push(this.props.picksByWeek[week][i].TieBreaker);
        rowContent.push(this.props.picksByWeek[week][i].Ids);
      }

      //iterate through matchups
      for (let j = 0; j < this.props.nameIdArr.length / 2; j++) {
        let n = this.props.nameIdArr.length / 2 - j - 1;

        //for the first row, make it the matchups
        if (i == -1) {
          if (j == 0) {
            var cell1 = row.insertCell();
            cell1.innerHTML = "";
          }
          var cell1 = row.insertCell();
          cell1.innerHTML =
            this.props.matchups[this.state.selectedWeek - 1][j][0][1] +
            " vs. " +
            this.props.matchups[this.state.selectedWeek - 1][j][1][1];
          cell1.style.fontWeight = "bolder";

          if (j == this.props.nameIdArr.length / 2 - 1) {
            var cell2 = row.insertCell();
            cell2.style.fontWeight = "bolder";
            if (week < (await getWeek())) {
              cell2.innerHTML =
                "Tiebreaker (" + winnersArr[week].data.tiebreaker + ")";
            } else {
              cell2.innerHTML = "Tiebreaker";
            }
          }
        } else {
          //if it's not the first row, make the first column the team that made the picks, and the rest the picks
          var cell1 = row.insertCell();
          cell1.innerHTML = rowContent[j];
          if (j == 0) {
            if (week < getWeek()) {
              let winnerID = winnersArr[week].data.winner_info[0];
              if (this.props.nameIdArr.filter((a) => a[0] == winnerID)[0][1] ===
              teamName) {
                cell1.style.backgroundColor = "gold";
              }
            }
            cell1.style.fontWeight = "bolder";
          } else {
            let found = false;

            //iterate through matchups
            for (
              let h = 0;
              h < this.props.matchups[this.state.selectedWeek - 1].length;
              h++
            ) {
              //if red
              if (
                this.props.matchups[this.state.selectedWeek - 1][h][0][1] ==
                rowContent[j]
              ) {
                found = true;
                if (
                  week < (await getWeek()) &&
                  winnersArr[week].data.picks.includes(
                    this.props.picksByWeek[week][i].Ids[j - 1]
                  )
                ) {
                  cell1.style.backgroundColor = "rgba(255, 0, 0, 1)";
                  cell1.style.textAlign = "center";
                  cell1.style.color = "white";
                  if (lockName === rowContent[j]) {
                    cell1.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                  } else {
                    cell1.style.border = "solid 2px white";
                  }
                } else {
                  cell1.style.backgroundColor = "rgba(255, 0, 0, 0.3)";
                  cell1.style.border = "solid 2px white";
                  cell1.style.textAlign = "center";
                  if (lockName === rowContent[j]) {
                    cell1.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                  }
                }
              }
            }
            // if blue
            if (!found) {
              // does the pick of the current user = the correct pick

              if (
                week < (await getWeek()) &&
                winnersArr[week].data.picks.includes(
                  this.props.picksByWeek[week][i].Ids[j - 1]
                )
              ) {
                cell1.style.backgroundColor = "rgba(37, 65, 178, 1)";
                cell1.style.border = "solid 2px white";
                cell1.style.textAlign = "center";
                cell1.style.color = "white";
                if (lockName === rowContent[j]) {
                  cell1.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                }
              } else {
                cell1.style.backgroundColor = "rgba(37, 65, 178, 0.3)";
                cell1.style.border = "solid 2px white";
                cell1.style.textAlign = "center";
                if (lockName === rowContent[j]) {
                  cell1.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                }
              }
            }
          }

          if (n == 0) {
            var cell2 = row.insertCell();
            cell2.innerHTML = rowContent[j + 1];
            let found = false;
            for (
              let h = 0;
              h < this.props.matchups[this.state.selectedWeek - 1].length;
              h++
            ) {
              if (
                this.props.matchups[this.state.selectedWeek - 1][h][0][1] ==
                rowContent[j + 1]
              ) {
                found = true;
                if (week < (await getWeek())) {
                  if (
                    winnersArr[week].data.picks.includes(
                      this.props.picksByWeek[week][i].Ids[j]
                    )
                  ) {
                    cell2.style.backgroundColor = "rgba(255, 0, 0, 1)";
                    cell2.style.border = "solid 2px white";
                    cell2.style.textAlign = "center";
                    cell2.style.color = "white";
                    if (lockName === rowContent[j + 1]) {
                      cell2.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                    }
                  } else {
                    cell2.style.backgroundColor = "rgba(255, 0, 0, 0.3)";
                    cell2.style.border = "solid 2px white";
                    cell2.style.textAlign = "center";
                    if (lockName === rowContent[j + 1]) {
                      cell2.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                    }
                  }
                } else {
                  cell2.style.backgroundColor = "rgba(255, 0, 0, 0.3)";
                  cell2.style.border = "solid 2px white";
                  cell2.style.textAlign = "center";
                  if (lockName === rowContent[j + 1]) {
                    cell2.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                  }
                }
              }
            }

            if (!found) {
              if (week < getWeek()) {
                console.log(
                  "this.props.picksByWeek[week][i].Ids[j] :",
                  this.props.picksByWeek[week][i].Ids[j]
                );

                console.log(
                  "winnersArr[week].data.picks[j] :",
                  winnersArr[week].data.picks[j]
                );
              }

              if (
                week < (await getWeek()) &&
                  winnersArr[week].data.picks[j] === this.props.picksByWeek[week][i].Ids[j]
              ) {
                cell2.style.backgroundColor = "rgba(37, 65, 178, 1)";
                cell2.style.border = "solid 2px white";
                cell2.style.textAlign = "center";
                cell2.style.color = "white";
                if (lockName === rowContent[j + 1]) {
                  cell2.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                }
              } else {
                cell2.style.backgroundColor = "rgba(37, 65, 178, 0.3)";
                cell2.style.border = "solid 2px white";
                cell2.style.textAlign = "center";
                if (lockName === rowContent[j + 1]) {
                  cell2.style.outline = "solid 4px rgba(224, 221, 30, 1)";
                }
              }
            }

            if (j == this.props.nameIdArr.length / 2 - 1) {
              var cell3 = row.insertCell();
              cell3.innerHTML = rowContent[j + 2];
              cell3.style.fontWeight = "bolder";
              cell3.style.textAlign = "center";
              cell3.style.fontSize = "16px";
              cell3.style.paddingTop = "16px";
            }
          }
        }
      }
    }
    // table.deleteRow(0);
    if (this.state.prevRows > 0) {
      this.tableCleanup(this.state.prevRows, table);
    }

    this.setState({ prevRows: this.props.picksByWeek[week].length + 1 });
  };

  tableCleanup = async (numRows, table) => {
    for (let i = 0; i < numRows; i++) {
      table.deleteRow(0);
    }
    await sleep(500);
    this.setState({ deletingTables: false });
  };

  changeWeek = async (week) => {
    // if (week != this.state.selectedWeek) {
    await this.setState({ selectedWeek: week, deletingTables: true });

    this.createDataSets(week);

    this.createTBPlot(week);
    this.getRankingData(week);

    this.setTable(week);
    // }
  };

  getPieTitle = (rowIndex) => {
    if (
      typeof this.props.matchups[this.state.selectedWeek - 1] !== "undefined" &&
      this.props.matchups[this.state.selectedWeek - 1][0].length > 0 &&
      this.state.ppm[rowIndex]
    ) {
      return (
        <Container>
          <Row>
            <Col>
              <span id="pieT2">
                {
                  this.props.matchups[this.state.selectedWeek - 1][
                    rowIndex
                  ][1][1]
                }
              </span>{" "}
              vs.{" "}
              <span id="pieT1">
                {
                  this.props.matchups[this.state.selectedWeek - 1][
                    rowIndex
                  ][0][1]
                }
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span style={{ color: "blue" }}>
                {this.state.ppm[rowIndex][1]}
              </span>{" "}
              -{" "}
              <span style={{ color: "red" }}>
                {this.state.ppm[rowIndex][0]}
              </span>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  loadButtons() {
    let b = [];
    let w = getWeek();
    let l = 13;

    for (let i = 1; i <= w; i++) {
      if (i == w) {
        b.push(
          <Button
            onClick={() => this.changeWeek(i)}
            id="weekBtnW"
            style={{
              backgroundColor: this.state.selectedWeek == i ? "#03256C" : "",
              color: this.state.selectedWeek == i ? "white" : "",
            }}
            disabled={this.state.deletingTables}
          >
            {i}
          </Button>
        );
      } else {
        b.push(
          <Button
            onClick={() => this.changeWeek(i)}
            id="weekBtn"
            style={{
              backgroundColor: this.state.selectedWeek == i ? "#2541B2" : "",
              color: this.state.selectedWeek == i ? "white" : "",
            }}
            disabled={this.state.deletingTables}
          >
            {i}
          </Button>
        );
      }
    }

    return b;
  }

  getWhoRanked() {
    let r = [];
    for (let i = 0; i < this.state.whoRankedThisWeek.length; i++) {
      r.push(
        <Row>
          <Col style={{ fontSize: "12px" }}>
            {this.state.whoRankedThisWeek[i]}
          </Col>
        </Row>
      );
    }
    return r;
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <Col id="tabTitle">Pick 'Em Results </Col>
          </Row>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <ButtonGroup aria-label="Basic example">
              {this.loadButtons()}
            </ButtonGroup>
          </Row>
          <Tabs
            defaultActiveKey="pickem"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="pickem" title="Pick 'Em">
              <Row id="pieTitle">
                <Col>{this.getPieTitle(0)}</Col>

                <Col>{this.getPieTitle(1)}</Col>
              </Row>
              <Row>
                <Col id="pieColL">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[0])}</Col>
                  </Row>
                </Col>
                <Col id="pieColR">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[1])}</Col>
                  </Row>
                </Col>
              </Row>
              <Row id="pieTitle">
                <Col>{this.getPieTitle(2)}</Col>

                <Col>{this.getPieTitle(3)}</Col>
              </Row>
              <Row>
                <Col id="pieColL">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[2])}</Col>
                  </Row>
                </Col>
                <Col id="pieColR">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[3])}</Col>
                  </Row>
                </Col>
              </Row>
              <Row id="pieTitle">
                <Col>{this.getPieTitle(4)}</Col>

                <Col>{this.getPieTitle(5)}</Col>
              </Row>
              <Row>
                {/* <Col lg="3"></Col> */}
                <Col id="pieColL">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[4])}</Col>
                  </Row>
                </Col>
                {/* <Col lg="3"></Col> */}
                <Col id="pieColR">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[5])}</Col>
                  </Row>
                </Col>
              </Row>
              <Row id="pieTitle">
                <Col>{this.getPieTitle(6)}</Col>

                <Col>{this.getPieTitle(7)}</Col>
              </Row>
              <Row>
                <Col id="pieColL">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[6])}</Col>
                  </Row>
                </Col>
                <Col id="pieColR">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[7])}</Col>
                  </Row>
                </Col>
              </Row>
              <Row id="pieTitle">
                <Col>{this.getPieTitle(8)}</Col>

                <Col>{this.getPieTitle(9)}</Col>
              </Row>
              <Row>
                <Col id="pieColL">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[8])}</Col>
                  </Row>
                </Col>
                <Col id="pieColR">
                  <Row>
                    <Col>{this.checkData(this.state.datasets[9])}</Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col id="horDiv"></Col>
              </Row>
              <Row>
                <Col id="SWTBMessage">{tbMessage[this.state.selectedWeek]}</Col>
              </Row>
              <Row>
                <Col
                  style={{
                    padding: "15%",
                    paddingTop: "2%",
                    paddingBottom: "5%",
                  }}
                >
                  {" "}
                  {this.tbPlot()}
                </Col>
              </Row>
              <Row>
                <Col id="horDiv"></Col>
              </Row>
              <Row>
                <Col>
                  <Table responsive id="picksTable"></Table>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="powerrankings" title="Power Rankings">
              <Row>
                <Col sm="0" lg="0"></Col>
                <Col>
                  <Table id="prTable"></Table>
                </Col>
                <Col
                  sm="12"
                  lg="2"
                  style={{
                    borderLeft:
                      window.innerWidth > 1000 ? "1px solid lightgrey" : "",
                  }}
                >
                  <Row
                    style={{
                      marginTop: window.innerWidth > 1000 ? "5px" : "10px",
                      fontSize: "20px",
                    }}
                  >
                    <Col
                      style={{
                        fontWeight:
                          window.innerWidth > 1000 ? "bolder" : "bolder",
                        fontSize: window.innerWidth > 1000 ? "16px" : "20px",
                      }}
                    >
                      Ranked By:
                    </Col>
                  </Row>
                  {this.getWhoRanked()}
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default StatsWeek;
