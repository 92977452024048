import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "fantasy-pick--em.firebaseapp.com",
  projectId: "fantasy-pick--em",
  storageBucket: "fantasy-pick--em.appspot.com",
  messagingSenderId: "1075943049193",
  appId: "1:1075943049193:web:41220f60ed052842052d78",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  await signInWithPopup(auth, provider)
    .then((result) => {
      const email = result.user.email;
      const name = result.user.displayName;

      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
    })
    .catch((error) => {
    });
};

export const db = getFirestore();
