import React from "react";
import Team from "./Team";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Pie } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import {
  usersReference,
  updateUser,
  picksReference,
  getId,
  getWeek,
  leagueId,
  tbMessage,
  keepersReference,
  sleep,
  currYear
} from "./App";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import keepers from "./keepers.json";  // Import the JSON data

class StatsKeepers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadCount: 0,
    };
  }

  componentDidMount() {
    if (this.state.loadCount == 0) {
      this.loadKeeperTable();
      this.setState({ loadCount: this.state.loadCount++ });
    }
  }

  async loadKeeperTable() {
    const keeperData = await getDocs(keepersReference);

    var table = document.getElementById("keepersTable");
    // let picks = keeperData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    let picks = keeperData.docs
  .filter((doc) => doc.data().Year === currYear)
  .map((doc) => ({ ...doc.data(), id: doc.id }));

    await sleep(500)

    for (let j = -1; j < this.props.nameIdArr.length; j++) {
        var row = table.insertRow();
      for (let i = 0; i < 3; i++) {
        

        if (i == 0) {
          var cell1 = row.insertCell();
          cell1.style.borderRight = "solid 2px lightgrey"
          if (window.innerWidth > 600) {
            cell1.style.width = "35%"
          }
          if (j == -1) {
            cell1.innerHTML = "";
            cell1.style.borderBottom = "solid 2px lightgrey"
          } else {
            cell1.innerHTML = this.props.nameIdArr[j][1];
            
          }
        } else {
          if (i == 1) {
            var cell1 = row.insertCell();
            cell1.style.textAlign = "center"
            if (j == -1) {
              cell1.innerHTML = "Keeper #1";
              cell1.style.borderBottom = "solid 2px lightgrey"
            } else {
              for (let h = 0; h < picks.length; h++) {
                if (picks[h].Uid === this.props.nameIdArr[j][0]) {
                  cell1.innerHTML = picks[h].K1;
                  cell1.style.color = "white";
                  cell1.style.border = "solid 2px white"
                  cell1.style.borderRight = "solid 1px white"
                  
                  //iterate the array of keepers and find the Tid, then find the player name, then get the round
                  for (let k = 0; k < keepers.length; k++) {
                    if (keepers[k]["id"] === this.props.nameIdArr[j][0]) {
                      for (let z = 0; z < keepers[k]["players"].length; z++) {
                        if (keepers[k]["players"][z]["player"] === picks[h].K1) {
                          cell1.style.backgroundColor = this.getColor(
                            keepers[k]["players"][z]["round"]
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          } else if (i == 2) {
            var cell1 = row.insertCell();
            cell1.style.textAlign = "center"
            if (j == -1) {
              cell1.innerHTML = "Keeper #2";
              cell1.style.borderBottom = "solid 2px lightgrey"
            } else {
              for (let h = 0; h < picks.length; h++) {
                if (picks[h].Uid === this.props.nameIdArr[j][0]) {
                  cell1.innerHTML = picks[h].K2;
                  cell1.style.color = "white";
                  cell1.style.border = "solid 2px white"
                  cell1.style.borderLeft = "solid 1px white"
                  cell1.style.textAlign = "center"
                  //iterate the array of keepers and find the Tid, then find the player name, then get the round
                  for (let k = 0; k < keepers.length; k++) {
                    if (keepers[k]["id"] === this.props.nameIdArr[j][0]) {
                      for (let z = 0; z < keepers[k]["players"].length; z++) {
                        if (keepers[k]["players"][z]["player"] === picks[h].K2) {
                          cell1.style.backgroundColor = this.getColor(
                            keepers[k]["players"][z]["round"]
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  getColor = (val) => {
    if (val == 1) {
      return "rgba(3,37,108,1)";
    } else if (val == 2) {
      return "rgba(17,54,118,1)";
    } else if (val == 3) {
      return "rgba(29,68,127,1)";
    } else if (val == 4) {
      return "rgba(54,98,145,1)";
    } else if (val == 5) {
      return "rgba(68,114,155,1)";
    } else if (val == 6) {
      return "rgba(83,132,166,1)";
    } else if (val == 7) {
      return "rgba(97,148,176,1)";
    } else if (val == 8) {
      return "rgba(116,170,189,1)";
    } else if (val == 9) {
      return "rgba(133,190,201,1)";
    } else if (val == 10) {
      return "rgba(159,220,219,1)";
    } else {
      return "rgba(174,238,230,1)";
    }
  };

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <Col id="tabTitle">Keepers</Col>
          </Row>
          <Row>
            <Col id="horDiv"></Col>
          </Row>
          <Row>
            <Col sm="0" lg="3"></Col>
            <Col>
              <Table id="keepersTable"></Table>
            </Col>
            <Col sm="0" lg="3"></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;

export default StatsKeepers;
